<template>
  <!-- <getting_started></getting_started> -->
  <div id="app-wrapper" class>
    <div v-if="!authenticated && isResetPassword">
      <reset_password></reset_password>
    </div>
    <div v-if="isEmailconfirm">
      <confirmation> </confirmation>
    </div>
    <div
      v-if="!authenticated && !signup && !isEmailconfirm && !isResetPassword"
    >
      <login> </login>
    </div>
    <div v-if="!authenticated && signup && !isEmailconfirm">
      <signupUser></signupUser>
    </div>
    <div v-if="authenticated && !isEmailconfirm">
      <auth></auth>
    </div>

    <!-- Render the modal when showNotificationModal is true -->
    <notification_modal v-if="showNotificationModal" :message="notificationMessage"
      @close="hideNotificationModal"></notification_modal>

  </div>
</template>

<script>
require("./js/config.js");
const custom = require("./js/custom.js");
import signupUser from "./components/signupUser";
import login from "./components/login";
import confirmation from "./components/confirmation";
import auth from "./components/auth_comp/auth";
import reset_password from "./components/reset_password.vue";
import getting_started from "./components/getting_started.vue";
import notification_modal from "./components/notification_modal.vue";

export default {
  name: "App",
  components: {
    auth,
    signupUser,
    login,
    confirmation,
    reset_password,
    getting_started,
    notification_modal
  },
  data: function () {
    return {
      // authenticated: config.authenticated,
      version: "1.0.2 - Fixed drop when no tentative orders",
    };
  },
  computed: {
    authenticated() {
      return this.$store.state.user.authenticated;
    },
    signup() {
      return this.$store.state.user.signup;
    },
    isEmailconfirm() {
      return this.$store.state.user.isEmailconfirm;
    },
    isResetPassword() {
      return (
        this.$store.state.user.resetPasswordStep1 ||
        this.$store.state.user.resetPasswordStep2
      );
    },
    showNotificationModal() {
      return this.$store.state.showNotificationModal;
    },
    notificationMessage() {
      return this.$store.state.notificationMessage;
    },
  },
  methods: {
    hideNotificationModal() {
      this.$store.commit('hideNotificationModal');
    },
  },
  created: function () {
    if (custom.readCookie("sid")) {
      this.$store.dispatch("user/cookieLogin");
    }
    console.log("Version: " + this.version);
  },
  beforeMount() {
    var currentUrl = new URL(window.location.href);
    if (currentUrl.search.indexOf("token") > 0) {
      var token = currentUrl.searchParams.get("token");
      var storeId = currentUrl.searchParams.get("store");
      this.$store.dispatch("user/logout");
      this.$store.dispatch("user/tokenLogin", { token, storeId });
    } else if (
      window.location.pathname.includes("confirmation") &&
      currentUrl.searchParams.get("userId") !== null &&
      currentUrl.searchParams.get("code") !== null
    ) {
      this.$store.state.user.isEmailconfirm = true;
    } else {
      this.$store.state.user.isEmailconfirm = false;
    }
  },
};
</script>

<style scoped>
</style>