import { render, staticRenderFns } from "./bottom-menu.vue?vue&type=template&id=63b34b34&scoped=true"
import script from "./bottom-menu.vue?vue&type=script&lang=js"
export * from "./bottom-menu.vue?vue&type=script&lang=js"
import style0 from "./bottom-menu.vue?vue&type=style&index=0&id=63b34b34&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "63b34b34",
  null
  
)

export default component.exports