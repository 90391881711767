<template>
    <div class="card">
      <header class="card-header">
        <p class="card-header-title">{{ formattedPhone }}</p>
        <div class="card-header-title">
          <input
            ref="editOrderNumber"
            class="input is-small order-number-input"
            :class="{ 'is-danger': errorOrderNumber }"
            v-model="editOrder.order_number"
            type="text"
            placeholder="ORDER # (OPTIONAL)"
            :disabled="isReadOnly"
          />
          <p
            class="help is-danger ml-1"
            v-bind:style="{
                        visibility: errorOrderNumber ? 'visible' : 'hidden',
                    }"
            >
          Order # must be unique
        </p>
        </div>
        <a class="card-header-icon button is-primary"  v-if="!isReadOnly" @click="saveOrder" :class="{ 'is-loading': isOrderSaving }"
          >Save</a
        >
        <a class="card-header-icon button" v-on:click="toggleEditOrder()"
          >Cancel</a
        >
      </header>
  
      <div class="card-content">
        <strong>
          <p
            class="help is-danger has-text-centered is-size-6"
            v-if="hasValidationErrors"
          >
          CHECK MISSING REQUIRED FIELDS
          </p>
        </strong>
        <strong>
          <p
            class="help is-danger"
            v-bind:style="{ visibility: editOrderError ? 'visible' : 'hidden' }"
          >
            {{ editOrderError }}
          </p>
        </strong>
        <section class="section">
          <div class="columns is-mobile">
            <div class="column is-one-fifth">
              <div class="field">
                <div class="control">
                  <input
                    class="input"
                    :class="{ 'is-danger': errorName }"
                    v-model="editOrder.customer_name"
                    type="text"
                    placeholder="NAME"
                    :disabled="isReadOnly"
                  />
                </div>
                <p
                  class="help is-danger"
                  v-bind:style="{ visibility: errorName ? 'visible' : 'hidden' }"
                >
                  Required
                </p>
              </div>
            </div>
            <div class="column is-half">
              <div class="field">
                <div class="control">
                  <address-textbox v-model="editOrder.address" :error="errorAddress" :disabled="isReadOnly || (editOrder.OrderType != null && editOrder.OrderType === 'Pickup')"></address-textbox>
                </div>
                <p
                  class="help is-danger"
                  v-bind:style="{
                    visibility: errorAddress ? 'visible' : 'hidden',
                  }"
                >
                  Required
                </p>
              </div>
            </div>
            <div class="column">
              <div class="toggle-container">
                <label class="switch">
                  <input type="checkbox" v-model="editOrder.is_scheduled" :disabled="isReadOnly"/>
                  <span class="slider round"></span>
                </label>
                <div
                  class="scheduled-label"
                  :style="{ 
                                  backgroundColor: editOrder.is_scheduled ? '#6ec545' : '#dcdcdc',
                                  color: editOrder.is_scheduled ? '#ffffff' : '#4a4a4a' 
                              }"
                >
                  SCHEDULED
                </div>
              </div>
            </div>
          </div>
  
          <div class="columns is-align-items-center">
            <div class="column is-one-fifth">
              <div class="field">
                <div class="select" :class="{ 'is-danger': errorPayment }" style="width: 100%">
                  <select v-model="editOrder.payment" style="width: 100%" :disabled="isReadOnly">
                    <option class="placeholder" value="" disabled selected hidden>
                      PAYMENT TYPE
                    </option>
                    <option value="Prepaid">Prepaid</option>
                    <option value="Cash">Cash</option>
                    <option value="Credit">Credit</option>
                    <option value="Other">Other</option>
                  </select>
                </div>
                <p
                  class="help is-danger"
                  v-if="errorPayment"
                  v-bind:style="{
                              visibility: errorPayment ? 'visible' : 'hidden',
                              }"
                >
                  Required
                </p>
              </div>
              <div class="field">
                <div class="columns is-flex is-align-items-center">
                  <div class="column is-half">
                    <label class="label">TIP</label>
                  </div>
                  <div class="column is-half">
                    <input
                      type="number"
                      class="input no-arrows"
                      v-model="editOrder.tip"
                      min="0.00"
                      step="0.01"
                      max="2500"
                      :disabled="isReadOnly"
                    />
                  </div>
                </div>
              </div>
              <div class="field">
                <div class="columns is-flex is-align-items-center">
                  <div class="column is-half">
                    <label class="label">TOTAL</label>
                  </div>
                  <div class="column is-half">
                    <input
                      type="number"
                      class="input no-arrows"
                      v-model="editOrder.total"
                      :disabled="isReadOnly"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="column is-half">
              <div class="field">
                <div class="control">
                  <textarea
                    class="textarea"
                    rows="6"
                    placeholder="DELIVERY INSTRUCTIONS / APT NUMBER"
                    v-model="editOrder.special_instructions"
                    :disabled="isReadOnly"
                  ></textarea>
                </div>
              </div>
            </div>
            <div class="column">
              <div class="field">
                <label class="label">RECEIVED</label>
                <div class="control">
                  <input
                    class="input"
                    :class="{ 'is-danger': errorEntryDateTime }"
                    v-model="editOrder.entry_datetime"
                    type="datetime-local"
                    :disabled="editOrder.is_scheduled || isReadOnly"
                  />
                </div>
                <p
                  class="help is-danger"
                  v-if="errorEntryDateTime"
                  v-bind:style="{visibility: errorEntryDateTime ? 'visible' : 'hidden',}"
                >
                  Required
                </p>
              </div>
              <div class="field" v-if="!editOrder.is_scheduled">
                <label class="label">DELIVER BY</label>
                <div class="control">
                  <input
                    class="input"
                    v-model="editOrder.deliver_by_datetime"
                    type="datetime-local"
                    :disabled="true"
                  />
                </div>
              </div>
              <div class="field" v-else>
                <label class="label">DELIVER AT</label>
                <div class="control">
                  <input
                    class="input"
                    :class="{ 'is-danger': errorDeliverAtDateTime }"
                    v-model="editOrder.deliver_datetime"
                    type="datetime-local"
                    :disabled="isReadOnly"
                  />
                </div>
                <p
                  class="help is-danger"
                  v-if="errorDeliverAtDateTime"
                  v-bind:style="{visibility: errorDeliverAtDateTime ? 'visible' : 'hidden',}"
                >
                  Required
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </template>
  
  <script>
  var timeFunctions = require("../../js/timeFunctions.js");
  var addressTextbox = require("../address_textbox").default;
  
  module.exports = {
    components: {
        addressTextbox,
    },
    created: function () {
        if (this.currentPanel === "editOrder") {
            let self = this;
            setTimeout(() => {
                self.$refs.editOrderNumber.focus();
            });
        }
    },
    data: function () {
        return {
            validationErrorPhoneNumber: false,
            validationErrorOrderNumber: false,
            validationErrorName: false,
            validationErrorAddress: false,
            validationErrorPayment: false,
            validationErrorTotal: false,
            validationErrorDeliverAtDateTime: false,
            showDate: false,
            hasValidationErrors: false
        };
    },
    computed: {
        settings: function() {
            return this.$store.getters["settings"];
        },
        isReadOnly: function() {
            return this.$store.getters["app/getEditOrderScreenReadOnly"]
        },
        editOrder() {
            return  this.$store.state.orders.editOrder;
        },
        isOrderSaving(){
            return this.$store.state.orders.isOrderSaving;
        },
        editOrderError() {
            return this.$store.state.orders.editOrderError;
        },
        errorPhoneNumber() {
            return this.validationErrorPhoneNumber;
        },
        errorOrderNumber() {
            return this.validationErrorOrderNumber;
        },
        errorEntryDateTime() {
            return this.validationErrorEntryDateTime;
        },
        errorDeliverAtDateTime() {
            return this.validationErrorDeliverAtDateTime;
        },
        errorName() {
            return this.validationErrorName;
        },
        errorAddress() {
            return this.validationErrorAddress;
        },
        errorPayment() {
            return this.validationErrorPayment;
        },
        currentPanel() {
            return this.$store.state.app.currentPanel;
        },
        formattedPhone() {
            var cleaned = ("" + this.editOrder.phone).replace(/\D/g, "");
            var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
            if (match) {
                return "(" + match[1] + ") " + match[2] + "-" + match[3];
            }
            return this.editOrder.phone;
        },
    },
    methods: {
        setPanel: function (panel) {
            this.$store.dispatch("app/setPanel", panel);
        },
        toggleEditOrder: function () {
            this.validationErrorPhoneNumber = false;
            this.validationErrorOrderNumber = false;
            this.validationErrorEntryDateTime = false;
            this.validationErrorName = false;
            this.validationErrorAddress = false;
            this.validationErrorPayment = false;
            this.validationErrorDeliverAtDateTime = false;
            this.hasValidationErrors = false;
            this.setPanel("order");
        },
        saveOrder: function () {
            this.validationErrorPhoneNumber = false;
            this.validationErrorOrderNumber = false;
            this.validationErrorEntryDateTime = false;
            this.validationErrorName = false;
            this.validationErrorAddress = false;
            this.validationErrorPayment = false;
            this.validationErrorDeliverAtDateTime = false;
            this.hasValidationErrors = false;

            if (!this.editOrder.phone || this.editOrder.phone.length != 10) {
                this.validationErrorPhoneNumber = true;
            }
            if (!this.editOrder.order_number) {
                this.validationErrorOrderNumber = true;
            }
            if (!this.editOrder.entry_time) {
                this.validationErrorEntryDateTime = true;
            }
            if(!this.editOrder.deliver_datetime && this.editOrder.is_scheduled) {
                this.validationErrorDeliverAtDateTime = true;
            }
            if (!this.editOrder.customer_name) {
                this.validationErrorName = true;
            }
            if (!this.editOrder.address && (this.editOrder.OrderType != null && this.editOrder.OrderType != 'Pickup')) {
                this.validationErrorAddress = true;
            }
            if (!this.editOrder.payment) {
                this.validationErrorPayment = true;
            }
  
            if (
                this.validationErrorPhoneNumber ||
                this.validationErrorOrderNumber ||
                this.validationErrorEntryDateTime ||
                (!this.editOrder.deliver_datetime && this.editOrder.is_scheduled) ||
                this.validationErrorName ||
                this.validationErrorAddress ||
                this.validationErrorPayment 
            ) {
                this.hasValidationErrors = true;
                return false;
            }
  
            const orderToSave = this.getOrderToSave();
            
            this.$store.dispatch("orders/saveEditOrder", orderToSave);
        },
        getTgt: function () {
            const radiusDriveTimes = this.settings.radius_drive_times;
            if(!radiusDriveTimes) return
  
            const parts = radiusDriveTimes.split("|");
            const minutes = parts[0].split(";");
  
            const tgt = parseInt(minutes[0]);
  
            return tgt;
        },
        getDateAndTime(datetime) {
            const [date, time] = datetime.split('T');
            return { date, time };
        },
        getOrderToSave: function() {
            if(!this.editOrder.is_scheduled) {
                const { date, time } = this.getDateAndTime(this.editOrder.entry_datetime);
                return {
                    ...this.editOrder,
                    entry_date: date,
                    entry_time: time
                }
            }
            const tgt = this.getTgt();
            let { date, time } = this.getDateAndTime(this.editOrder.deliver_datetime);
            let dateTime = new Date(this.editOrder.deliver_datetime);
            dateTime.setMinutes(dateTime.getMinutes() - parseInt(tgt));
            let newEntryDate = timeFunctions.formatDate(dateTime);
            let newEntryTime = dateTime.toTimeString().slice(0, 5);
            return {
                ...this.editOrder,
                entry_date: newEntryDate,
                entry_time: newEntryTime,
                deliver_time: time,
                deliver_date: date,
            }
        },
    },
  };
  </script>
  
  <style scoped>
  .change-date-button {
    font-size: 0.6rem !important;
  }
  
  .double-field {
    margin-bottom: .5em !important;
  }
  
  .button.is-loading::after {
    border-color: #209cee transparent transparent transparent;
  }
  
  .order-number-input{
    width: 150px;
  }
  
  .placeholder {
    color: #dbdbdb;
  }
  
  .is-align-items-center {
    align-items: center;
  }
  
  .mr-3{
    margin-right: 3em;
  }
  
  .mr-2 {
    margin-right: 2em;
  }
  
  .column {
    padding: .75rem !important;
  }
  
  .toggle-container {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .scheduled-label {
    padding: 5px 15px;
    border-radius: 20px;
    font-size: .7rem;
    font-weight: bold;
    height: 34px;
    align-items: center;
    display: flex
  }
  
  .card {
  padding-top: .9em !important;
  }
  
  .ml-1 {
    margin-left: 1em;
  }
  </style>
  