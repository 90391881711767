<template>
  <nav class="footer-custom is-fullwidth level">
    <div class="is-grouped level-left">
      <a class="button" v-on:click="setPanel('customer')">
        <span class="icon is-small">
          <i class="fa fa-user"></i>
        </span>
        <span>Customer</span>
      </a>
      <!-- <a class="button" v-on:click="setPanel('order')">
        <span class="icon is-small">
          <i class="fa fa-list"></i>
        </span>
        <span>Orders</span>
      </a> -->
    </div>
    <div class="nav-center level-right"></div>
  </nav>
</template>

<script>
module.exports = {
  data: function () {
    return {};
  },
  methods: {
    setPanel: function (panel) {
      this.$store.dispatch("app/setPanel", panel);
    },
  },
};
</script>

<style scoped>
  .footer-custom{
    border-bottom: 1px solid #DBDBDB;
    padding: 5px;
    background-color: white;
    margin-bottom: 0px !important;
  }
  .username{
    margin-left: 12px;
  }
  .logout{
    color: #7A7A7A;
  }
</style>