<template>
  <div id="map" :style="{ height: isTabletMode ? '90vh' : '100vh' }"></div>
</template>

<script>
var timeFunctions = require("../../js/timeFunctions.js");
var orderFunctions = require("../../js/orderFunctions.js");
const custom = require("../../js/custom.js");

module.exports = {
  data: function () {
    return {
      base_url: this.$store.state.base_url,
      api_url: this.$store.state.api_url,
      store_id: this.$store.state.user.store_id,
      selectedDriverLocation: {
        lat: 0,
        lng: 0,
      },
      route: [],
      updatingMap: false,
      map: "",
      markers: [],
      orderMarkers: [],
      orderInfoWindows: [],
      directionsService: "",
      directionsDisplay: "",
      timeout: null,
      expandedOrderIds: [],
      zoomed: false,
      isTabletMode: false,
    };
  },
  mounted: function () {
    this.checkTabletMode();
    window.addEventListener("resize", this.checkTabletMode);
    this.$store.dispatch("app/expandLevelToDefault");
    this.$nextTick(function () {
      if (
        window.map_loaded.ready &&
        this.$store.state.settings.latitude &&
        this.$store.state.settings.longitude &&
        this.$store.state.settings.address
      ) {
        this.generateMap();
      } else {
        console.log("Map Not Generated; Not All Data Available");
      }
    });
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.checkTabletMode);
  },
  computed: {
    retailOptimization: function () {
        return this.$store.getters["orders/getStore"]['retailOptimization']
    },
    getDrivers: function () {
      return this.$store.getters["drivers/getDrivers"];
    },
    onDutyDrivers: function () {
      return this.$store.getters["drivers/onDutyDrivers"];
    },
    inStoreNotAssignedOrders: function () {
      return this.$store.getters["orders/inStoreNotAssignedOrders"].filter(
        (o) =>
          (o.external_delivery_source == null || o.external_delivery_source == "") && o.OrderType != "Pickup" && o.OrderType != "OutSourced"
      );
    },
    selectedDriver: function () {
      return this.$store.getters["drivers/selectedDriver"];
    },
    selectedDriverId: function () {
      return this.$store.getters["drivers/selectedDriver"]?.driver_id;
    },
    selectedOrders: function () {
      return this.$store.getters["orders/selectedOrders"];
    },
    tentativeRoute: function () {
      return this.$store.getters["drivers/selectedDriver"]
        ? this.$store.getters["orders/tentativeOrdersByDriverId"](
            this.$store.getters["drivers/selectedDriver"]?.driver_id
          )
        : [];
    },
    assignedRoute: function () {
      return this.$store.getters["drivers/selectedDriver"]
        ? this.$store.getters["orders/assignedOrdersByDriverId"](
            this.$store.getters["drivers/selectedDriver"]?.driver_id
          )
        : [];
    },
    storeAddress: function () {
      return this.$store.state.settings.address;
    },
    center: function () {
      return {
        lat: this.$store.state.settings.latitude,
        lng: this.$store.state.settings.longitude,
      };
    },
    averagePrepTime: function () {
      return this.$store.getters["orders/averagePrepTime"];
    },
    directionsDisplayCache: function () {
      return this.$store.getters["orders/directionsDisplayCache"];
    },
    outsourcedOrders: function () {
      return this.$store.getters["orders/outsourcedOrders"];
    },
    isOrderReadyRequired: function () {
        return this.$store.getters["orders/getIsOrderReadyRequired"]
    },
  },
  watch: {
    selectedDriverId: function () {
      this.zoomed = false;
    },
    inStoreNotAssignedOrders: function () {
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.generateMap();
      }, 300);
    },
    selectedOrders: function () {
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.generateMap();
      }, 300);
    },
    tentativeRoute: function () {
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.generateMap();
      }, 300);
    },
    isTabletMode(tabletMode) {
      // if (tabletMode !== undefined) {
      //   if (tabletMode) this.$store.dispatch("app/changeExpandLevel", 1);
      //   else this.$store.dispatch("app/changeExpandLevel", 3);
      // }
    },
  },
  methods: {
    checkTabletMode() {
      this.isTabletMode = custom.checkIfTablet();
    },
    getNameInitials: (n) => orderFunctions.getNameInitials(n),
    getShortName: (n, x) => orderFunctions.getShortName(n, x)?.toUpperCase(),
    getDriverShortName: (fname, lname, limitFirstName) =>
      orderFunctions.getDriverShortName(fname, lname, limitFirstName),
    setPanel: function (panel) {
      this.$store.dispatch("app/setPanel", panel);
    },
    elapsedTime: (a) => timeFunctions.elapsedTime(a),
    currentTime: () => timeFunctions.currentTime(),
    convertToTime: (a) => timeFunctions.convertToTime(a),
    generateMap: function () {
      // Instantiate a directions service.
      if (!this.directionsService) {
        this.directionsService = new window.google.maps.DirectionsService();
      }

      // Create a renderer for directions.
      if (!this.directionsDisplay) {
        this.directionsDisplay = new window.google.maps.DirectionsRenderer({
          suppressMarkers: true,
          preserveViewport: true,
        });
      }

      // Generate Map
      if (!this.map) {
        this.map = new window.google.maps.Map(document.getElementById("map"), {
          zoom: 14,
          center: this.center,
          mapTypeControl: false,
          scaleControl: false,
          streetViewControl: false,
          rotateControl: false,
          fullscreenControl: false,
          zoomControl: true,
          mapTypeId: google.maps.MapTypeId.ROADMAP,
          styles: [
            {
              featureType: "poi",
              elementType: "labels",
              stylers: [
                {
                  visibility: "off",
                },
              ],
            },
            {
              featureType: "administrative",
              elementType: "labels",
              stylers: [
                {
                  visibility: "off",
                },
              ],
            },
          ], 
        });

        this.directionsDisplay.setMap(this.map);
      }

      this.removeMarkers(this.markers);
      this.removeMarkers(this.orderMarkers);
      this.removeInfoWindows(this.orderInfoWindows);

      this.directionsDisplay.set("directions", null);

      // Create Store Marker
      this.createStoreMarker();

      this.route = [];
      if (this.selectedDriver) {
        this.addDriverToMap();
      } else {
        this.addUnroutedOrderMarkers();
        this.addOutsourcedOrderMarkers();
      }
    },

    addDriverToMap: function () {
      // Check if the driver is sill on duty
      if(!this.onDutyDrivers.some(driver => driver.driver_id === this.selectedDriver.driver_id)){
        this.$store.dispatch("drivers/deselectDriver");
        return;
      }

      // Get driver location
      var payload = {
        store_id: this.store_id,
        method: "GET",
        driver_id: this.selectedDriver.driver_id,
      };
      this.$http.post(this.base_url + "driver/location/", payload).then(
        (res) => {
          this.selectedDriverLocation.lng = res.body.longitude;
          this.selectedDriverLocation.lat = res.body.latitude;

          this.removeMarkers(this.markers);

          // Create Store Marker
          this.createStoreMarker();

          // Add Driver Marker
          if (
            this.selectedDriverLocation.lng &&
            this.selectedDriverLocation.lat
          ) {
            this.addDriverMarkerToMap();
          }
        },
        (res) => {
          console.log("Failed to get driver location...", res.body);
        }
      );

      if (this.assignedRoute.length) {
        this.route = this.assignedRoute;
      } else if (this.tentativeRoute.length) {
        this.route = this.tentativeRoute;
      } else {
        this.route = [];
      }

      this.addRouteToMap();
    },

    addDriverMarkerToMap: function () {
      const google = window.google;
      // Create Driver Marker
      var image = {
        url: "/img/Driver_Pin_Small.png",
        // This marker is 20 pixels wide by 32 pixels high.
        size: new google.maps.Size(26, 34),
        // The origin for this image is (0, 0).
        origin: new google.maps.Point(0, 0),
        // The anchor for this image is the base of the flagpole at (0, 32).
        anchor: new google.maps.Point(13, 34),
      };
      var marker = new google.maps.Marker({
        position: {
          lat: this.selectedDriverLocation.lat,
          lng: this.selectedDriverLocation.lng,
        },
        map: this.map,
        icon: image,
        label: "",
      });

      const infowindow = new google.maps.InfoWindow({
        content: `<div id='iw-custom'>${this.getDriverShortName(
          this.selectedDriver.driver_name,
          this.selectedDriver.DriverLastName
        ).toUpperCase()}</div>`,
        disableAutoPan: true,
      });
      infowindow.addListener("domready", () => {
        const element = document.getElementById('iw-custom');
        //element.closest(".gm-style-iw").classList.add("is-order");
        element.closest(".gm-style-iw").classList.add("is-driver");

        const style = document.createElement('style');
        style.innerHTML = `.is-driver .gm-ui-hover-effect { display: none !important; }`;
        document.head.appendChild(style);

      });

      infowindow.open(marker.get("map"), marker);

      this.markers.push(marker);
    },

    zoomMapToFitMarkers() {
        const google = window.google;
        // Zooms map to have all the order markers visible
        // Create a LatLngBounds object to store the bounds of the markers
        let bounds = new google.maps.LatLngBounds();
        // Add the order markers to the bounds
        let markersToFit = [
          ...(this.orderMarkers || []),
          ...(this.markers || []).filter((m) => m?.info?.name === "shop"),
        ];
        for (let i = 0; i < markersToFit.length; i++) {
          bounds.extend(markersToFit[i].getPosition());
        }
        // Fit the map to the bounds
        this.map.fitBounds(bounds);
        this.map.setZoom(this.map.getZoom() - 0.3);
    },

    addRouteToMap: function () {
      const that = this;
      const directionsDisplay = this.directionsDisplay;

      // clear map
      directionsDisplay.setMap(null);
      this.removeMarkers(this.orderMarkers);
      this.removeInfoWindows(this.orderInfoWindows);

      // Load waypoints
      var waypts = [];
      for (var i in this.route) {
        waypts.push({
          location: this.route[i].address,
          stopover: true,
        });
      }

      // Add route to direction service if waypoints exist
      if (waypts.length > 0) {
        directionsDisplay.setMap(this.map);

        // Pull directions from cache and set display to response found.
        let cachedWayptResponse = this.searchWayptCache(waypts);
        if (cachedWayptResponse) {
          console.log("Cached Directions Used");
          this.setDirectionsDisplay(cachedWayptResponse);
        } else {
          this.directionsService.route(
            {
              origin: this.storeAddress,
              destination: this.storeAddress,
              waypoints: waypts,
              travelMode: "DRIVING",
            },
            function (response, status) {
              if (status === "OK") {
                console.log("Directions Called", waypts);
                that.$store.dispatch("orders/cacheDirectionsDisplay", {
                  waypts: [...waypts],
                  response,
                });
                that.setDirectionsDisplay(response);
              } else {
                console.log("Failed to get directions...");
              }
            }
          );
        }
      } else {
        that.addUnroutedOrderMarkers();
      }
    },
    setDirectionsDisplay: function (response) {
      this.removeMarkers(this.orderMarkers);
      this.removeInfoWindows(this.orderInfoWindows);
      this.directionsDisplay.setDirections(response);
      var deliveries = response.routes[0].legs.slice(0, -1);
      for (var i = 0; i < deliveries.length; i++) {
        this.addDeliveryOrderMarker(deliveries[i], this.route[i], i);
      }
      this.addUnroutedOrderMarkers();
      this.zoomMapToFitMarkers();
    },
    searchWayptCache: function (waypts) {
      // Foreach Cached Direction Search; Compare Waypoints. If the same, return direction response from cache.
      if (this.directionsDisplayCache.length > 0) {
        for (var x = 0; x < this.directionsDisplayCache.length; x++) {
          cache = this.directionsDisplayCache[x];
          let cacheWaypts = cache.waypts;
          if (cacheWaypts.length == waypts.length) {
            let exactMatch = true;
            for (var i = 0; i < cacheWaypts.length; i++) {
              if (cacheWaypts[i].location !== waypts[i].location) {
                exactMatch = false;
              }
            }
            if (exactMatch) {
              return cache.response;
            }
          }
        }
      }
      return null;
    },
    addUnroutedOrderMarkers: function () {
      if(this.selectedDriver == null || this.selectedDriver.time_driver_left == null){
        var unRoutedOrders = this.inStoreNotAssignedOrders.filter(
          (o) => this.route.find((r) => r.order_id == o.order_id) == undefined
        );

        for (var i = 0; i < unRoutedOrders.length; i++) {
          this.addUnroutedOrderMarker(unRoutedOrders[i]);
        }
      }
    },
    addOutsourcedOrderMarkers: function () {
      const idSet = new Set(this.selectedOrders);
      const selectedOutsourcedOrders = this.outsourcedOrders.filter((o) =>
        idSet.has(o.order_id)
      );
      selectedOutsourcedOrders.forEach((order) => {
        this.addUnroutedOrderMarker(order, true);
      });
    },
    stringTimeDiff: (a, b) => timeFunctions.stringTimeDiff(a, b),
    getOrderMarkerColor: function(order) {
      const isDelivered = order?.delivery_time != null;

      if (isDelivered) {
        return 'grey';
      }

      const driver = this.getDrivers.find((d) => d.driver_id === order.driver_id);
      const isOnTheWay = driver?.time_driver_left != null;

      if (isOnTheWay) {
        return 'blue';
      }

      const isReady = order?.is_ready_to_deliver;
      const readyIn = Math.max(this.averagePrepTime - this.elapsedTime(order?.entry_time), 0);

      if (isReady) {
        return 'green';
      }

      if (readyIn < 5) {
        return 'orange';
      }

      return 'red';
    },
    addUnroutedOrderMarker: function (order, outsourced = false) {
      const google = window.google;

      let readyIn = Math.max(
        this.averagePrepTime - this.elapsedTime(order.entry_time),
        0
      );

      let isReady = order.is_ready_to_deliver ? true : false;

      readyIn = this.isOrderReadyRequired ? isReady ? "R" : readyIn : "";

      let isAlmostReady = isReady ? false : readyIn < 5; //to check when an order is almost ready

      let markerColor = this.getOrderMarkerColor(order);

      const isReturn = order.is_return;
      const isOnTheWay =
        this.getDrivers.find((d) => d.driver_id === order.driver_id)
          ?.time_driver_left != null;

      let notRoutedImage = {
        url: `https://maps.google.com/mapfiles/ms/icons/${markerColor}.png`,
        // url: "/img/green-marker-outlined.png",
        // This marker is 20 pixels wide by 32 pixels high.
        size: new google.maps.Size(26, 34),
        // The origin for this image is (0, 0).
        origin: new google.maps.Point(0, 0),
        // The anchor for this image is the base of the flagpole at (0, 32).
        anchor: new google.maps.Point(13, 34),
      };

      let selectedNotRoutedImage = {
        url: `https://maps.google.com/mapfiles/ms/icons/${markerColor}-dot.png`,
        // url: "/img/green-marker-filled.png",
        // This marker is 20 pixels wide by 32 pixels high.
        size: new google.maps.Size(26, 34),
        // The origin for this image is (0, 0).
        origin: new google.maps.Point(0, 0),
        // The anchor for this image is the base of the flagpole at (0, 32).
        anchor: new google.maps.Point(13, 34),
      };

      const isSelected = this.selectedOrders.indexOf(order.order_id) !== -1;

      const marker = new google.maps.Marker({
        position: {
          lat: order.latitude,
          lng: order.longitude,
        },
        map: this.map,
        icon: isSelected ? selectedNotRoutedImage : notRoutedImage,
        label: "",
      });

      var shortAddress = order.address.split(",")[0];
      if (shortAddress.length > 20) {
        shortAddress = shortAddress.substring(0, 20);
      }

      var customerName = order.customer_name;
      var customerNameInitials = this.getNameInitials(customerName);
      var shortCustomerName = this.getShortName(customerName);

      let tltd = order.delivery_time
        ? "D"
        : order.isReturn
        ? "R"
        : order.target_delivery_time_minutes -
          this.elapsedTime(order.entry_time);

      var tgt = order.target_delivery_time_minutes;

      var tdt = order.estimated_delivery_time ? -timeFunctions.stringTimeDiff(
        order.entry_time,
        order.estimated_delivery_time
      ) : "--";

      var isScheduled = false;
      if (order.ScheduleType == "SCHEDULED") {
        tltd = this.convertToTime(order.DeliverAt) - this.currentTime();
        isScheduled = true;
      }

      var sched = "";
      if (isScheduled) {
        sched = `<span><i style="color: ${
          isSelected
            ? "white"
            : isReturn
            ? "#FD7567"
            : isReady
            ? "#00E64D"
            : isAlmostReady
            ? "#FF9900"
            : order.delivery_time
            ? "gray"
            : "#FD7567"
        };/" class="fa fa-clock-o"></i></span>`;
      } else if (isOnTheWay) {
        const EDT = -this.stringTimeDiff(
                order.entry_time,
                order.estimated_delivery_time
              );
        const TUD = -this.elapsedTime(order.estimated_delivery_time);
        sched = `<span>${TUD}</span>`; //TUD
        tltd = `<span>${EDT}</span>`; //EDT
      } else if (order.delivery_time) {
        const ADT = -this.stringTimeDiff(order.entry_time, order.delivery_time);
        const TUD = this.elapsedTime(order.delivery_time);
        sched = `<span>${TUD}</span>`; //TUD
        tltd = `<span>${ADT}</span>`; //ADT
      } else {
        sched = `<span>${readyIn}</span>`;
      }

        let shorterCustomerName = this.getShortName(customerName, 6);

      var html = `<div id='order_${order.order_id}'>
         <div id='order_${order.order_id}_col'>

                              <div class="container">
                                <div class="top">${sched}</div>
                                <div class="middle">${shorterCustomerName}</div>
                                ${
                                  !this.retailOptimization
                                    ? `<div class="bottom">${tltd}</div>`
                                    : ""
                                }
                              </div>

                              </div> 
                              <div id='order_${
                                order.order_id
                              }_exp' class="display-none">
                                <table id="tbl_r_${order.order_id}">
                                  <thead>
                                    <tr>
                                      <th colspan="3" id="cstmr_${customerName}" style="text-align: left;">
                                        <span style="background: #2f53ba; color: white" class="text-upper-case">&nbsp;${shortCustomerName}&nbsp;</span>
                                        ${
                                          isScheduled
                                            ? `<span style="text-align: left;">
                                          <i style="color: #2f53ba; margin-left: .2rem" class="fa fa-clock-o"></i>
                                        </span>`
                                            : ""
                                        }
                                      </th
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr id="sa_${order.order_id}">
                                      <td colspan="3" class="text-upper-case text-italic"">${shortAddress}</td>
                                    </tr>
                                      ${
                                        !this.retailOptimization
                                          ? `<tbody>
                                              <tr>
                                                <td style="width: 33%; background-color: #2F53BA; color: white;">TLTD</td>
                                                <td style="width: 33%; background-color: #2F53BA; color: white;">TGT</td>
                                                <td style="width: 33%; background-color: #2F53BA; color: white;">TDT</td>
                                              </tr>
                                              <tr>
                                                <td style="width: 33%;">${tltd}</td>
                                                <td style="width: 33%;">${tgt}</td>
                                                <td style="width: 33%;">${tdt}</td>
                                              </tr>
                                            </tbody>`
                                          : ""
                                      }
                                </table>
                              </div>
                              </div>`;

      const infowindow = new google.maps.InfoWindow({
        content: html,
        disableAutoPan: true,
      });

      var isWindowExpanded = false;

      infowindow.addListener("domready", () => {
        const element = document.getElementById(`order_${order.order_id}`);
        element.closest(".gm-style-iw").classList.add("is-order");

        if (!isWindowExpanded) {
          element.closest(".gm-style-iw-d").classList.add("collapsed-window");
        }

        const tc = element.closest(".gm-style-iw").nextElementSibling;
        const collapsedWind = document.getElementById(
          `order_${order.order_id}_col`
        );
        const expandedWind = document.getElementById(
          `order_${order.order_id}_exp`
        );

        if ((this.expandedOrderIds || []).find((o) => o == order.order_id)) {
          isWindowExpanded = true;
          expandedWind.classList.remove("display-none");
          collapsedWind.classList.add("display-none");
          element
            .closest(".gm-style-iw-d")
            .classList.remove("collapsed-window");
          element.closest(".gm-style-iw-d").classList.add("expanded-window");
        }

        tc.addEventListener("click", () => {
          if (!isWindowExpanded) {
            isWindowExpanded = true;
            this.expandedOrderIds.push(order.order_id);
            expandedWind.classList.remove("display-none");
            collapsedWind.classList.add("display-none");
            element
              .closest(".gm-style-iw-d")
              .classList.remove("collapsed-window");
            element.closest(".gm-style-iw-d").classList.add("expanded-window");
          } else {
            isWindowExpanded = false;
            this.expandedOrderIds = (this.expandedOrderIds || []).filter(
              (o) => o != order.order_id
            );
            collapsedWind.classList.remove("display-none");
            expandedWind.classList.add("display-none");
            element
              .closest(".gm-style-iw-d")
              .classList.remove("expanded-window");
            element.closest(".gm-style-iw-d").classList.add("collapsed-window");
          }
        });

        if (isSelected) {
          element.closest(".gm-style-iw-d").classList.add("is-selected");
          element.closest(".gm-style-iw-c").classList.add("is-selected");
          element.closest(".gm-style-iw-t").classList.add("is-selected");
        }

        if (outsourced) {
          element.closest(".gm-style-iw-d").classList.add("is-outsourced");
          element.closest(".gm-style-iw-c").classList.add("is-outsourced");
          element.closest(".gm-style-iw-t").classList.add("is-outsourced");
        } else {
          if (isReturn) {
            //red
            element.closest(".gm-style-iw-d").classList.add("is-not-ready");
            element.closest(".gm-style-iw-c").classList.add("is-not-ready");
            element.closest(".gm-style-iw-t").classList.add("is-not-ready");
          } else {
            if (isAlmostReady) {
              element
                .closest(".gm-style-iw-d")
                .classList.add("is-almost-ready");
              element
                .closest(".gm-style-iw-c")
                .classList.add("is-almost-ready");
              element
                .closest(".gm-style-iw-t")
                .classList.add("is-almost-ready");
            } else if (!isReady) {
              element.closest(".gm-style-iw-d").classList.add("is-not-ready");
              element.closest(".gm-style-iw-c").classList.add("is-not-ready");
              element.closest(".gm-style-iw-t").classList.add("is-not-ready");
            }

            if (isOnTheWay) {
              element
                .closest(".gm-style-iw-d")
                .classList.add("on-the-way-is-ready");
            }

            if (isReady) {
              element.closest(".gm-style-iw-d").classList.add("is-ready");
              element.closest(".gm-style-iw-c").classList.add("is-ready");
              element.closest(".gm-style-iw-t").classList.add("is-ready");
            }
            if (order.delivery_time) {
              element.closest(".gm-style-iw-d").classList.add("is-delivered");
              element.closest(".gm-style-iw-c").classList.add("is-delivered");
              element.closest(".gm-style-iw-t").classList.add("is-delivered");
            }
          }
        }

        // element.onclick = () => {
        //   this.$store.dispatch("orders/toggleOrderSelection", order);
        // };
        document
          .getElementById(`order_${order.order_id}`)
          ?.addEventListener("click", () => {
            this.$store.dispatch("orders/toggleOrderSelection", order);
          });
      });
      infowindow.open(this.map, marker);

      marker.addListener("click", () => {
        this.$store.dispatch("orders/toggleOrderSelection", order);
      });

      this.orderMarkers.push(marker);
      this.orderInfoWindows.push(infowindow);

      this.zoomMapToFitMarkers();
    },

    addDeliveryOrderMarker: function (delivery, order, stopNumber) {
      const google = window.google;

      const isSelected = this.selectedOrders.indexOf(order.order_id) !== -1;

      const shortAddress = order.address.split(",")[0];
      const customerName = order.customer_name;

      const labels = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

      const label = labels[stopNumber];

      const isOnTheWay =
        this.getDrivers.find((d) => d.driver_id === order.driver_id)
          ?.time_driver_left != null;

      let tltd = order.delivery_time
        ? "D"
        : order.isReturn
        ? "R"
        : order.target_delivery_time_minutes -
          this.elapsedTime(order.entry_time);

      let isScheduled = false;
      if (order.ScheduleType == "SCHEDULED") {
        tltd = this.convertToTime(order.DeliverAt) - this.currentTime();
        isScheduled = true;
      }
      let isReady = order.is_ready_to_deliver ? true : false;

      let readyIn = Math.max(
        this.averagePrepTime - this.elapsedTime(order.entry_time),
        0
      );
      
      readyIn = this.isOrderReadyRequired ? isReady ? "R" : readyIn : "";

      let isAlmostReady = isReady ? false : readyIn < 5; //to check when an order is almost ready

      let markerColor = this.getOrderMarkerColor(order);

      const isDelivered = order.delivery_time != null;

      let notRoutedImage = {
        url: `https://maps.google.com/mapfiles/ms/icons/${markerColor}.png`,
        // url: "/img/green-marker-outlined.png",
        // This marker is 20 pixels wide by 32 pixels high.
        size: new google.maps.Size(26, 34),
        // The origin for this image is (0, 0).
        origin: new google.maps.Point(0, 0),
        // The anchor for this image is the base of the flagpole at (0, 32).
        anchor: new google.maps.Point(13, 34),
        labelOrigin: new google.maps.Point(15, 10),
      };

      let selectedNotRoutedImage = {
        url: `https://maps.google.com/mapfiles/ms/icons/${markerColor}.png`,
        // url: "/img/green-marker-filled.png",
        // This marker is 20 pixels wide by 32 pixels high.
        size: new google.maps.Size(26, 34),
        // The origin for this image is (0, 0).
        origin: new google.maps.Point(0, 0),
        // The anchor for this image is the base of the flagpole at (0, 32).
        anchor: new google.maps.Point(13, 34),
        labelOrigin: new google.maps.Point(15, 10),
      };

      const marker = new google.maps.Marker({
        position: {
          lat: delivery.end_location.lat(),
          lng: delivery.end_location.lng(),
        },
        map: this.map,
        icon: isSelected ? selectedNotRoutedImage : notRoutedImage,
        label: label,
      });

      var tgt = order.target_delivery_time_minutes;

      var tdt = order.estimated_delivery_time ? -timeFunctions.stringTimeDiff(
        order.entry_time,
        order.estimated_delivery_time
      ) : "--";

      const isReturn = order.is_return;

      var sched = "";
      if (isScheduled) {
        sched = `<span><i style="color: ${
          isSelected
            ? "white"
            : isReturn
            ? "#FD7567"
            : isReady
            ? "#00E64D"
            : isAlmostReady
            ? "#FF9900"
            : order.delivery_time
            ? "gray"
            : "#FD7567"
        };/" class="fa fa-clock-o"></i></span>`;
      } else if (isOnTheWay && !order.delivery_time) {
        const EDT = -this.stringTimeDiff(
                order.entry_time,
                order.estimated_delivery_time
              );
        const TUD = -this.elapsedTime(order.estimated_delivery_time);
        sched = `<span>${TUD}</span>`; //TUD
        tltd = `<span>${EDT}</span>`; //EDT
      } else if (order.delivery_time) {
        const ADT = -this.stringTimeDiff(order.entry_time, order.delivery_time);
        const TUD = this.elapsedTime(order.delivery_time);
        sched = `<span>${TUD}</span>`; //TUD
        const adtClass =  order.target_delivery_time_minutes + 10 < -this.stringTimeDiff(order.entry_time, order.delivery_time)
                    ? 
                    'will-be-late'
                    : 
                    order.target_delivery_time_minutes < -this.stringTimeDiff(order.entry_time, order.delivery_time) 
                    ? 
                    'orange-color' 
                    : 
                    'green-color'
  
        tltd = `<span class=${adtClass}>
          ${ADT}
          </span>`; //ADT
      } else {
        sched = `<span>${readyIn}</span>`;
      }

      let shortCustomerName = this.getShortName(customerName, 6);
      let shorterCustomerName = this.getShortName(customerName, 6);
      let splitShortCustomerName = shorterCustomerName.split(" ");
      let shortFirstName = splitShortCustomerName[0];
      let lastNameInitial =
        splitShortCustomerName.length > 1 ? splitShortCustomerName[1] : "";

      let html = `<div id='order_${order.order_id}'>
          <div id='order_${order.order_id}_col'>
                              <div class="container">
                                <div class="top">${sched}</div>
                                <div class="middle">${shortFirstName} ${lastNameInitial}</div>
                                ${
                                  !this.retailOptimization
                                    ? `<div class="bottom">${tltd}</div>`
                                    : ""
                                }
                              </div>

                              </div> 
                              <div id='order_${
                                order.order_id
                              }_exp' class="display-none">
                                <table id="tbl_r_${order.order_id}">
                                  <thead>
                                    <tr>
                                      <th colspan="3" id="cstmr_${shortCustomerName}" style="text-align: left;">
                                        <span style="background: #2f53ba; color: white" class="text-upper-case">&nbsp;${shortCustomerName}&nbsp;</span>
                                        ${
                                          isScheduled
                                            ? `<span style="text-align: left;">
                                          <i style="color: #2f53ba; margin-left: .2rem" class="fa fa-clock-o"></i>
                                        </span>`
                                            : ""
                                        }
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr id="sa_${order.order_id}">
                                      <td colspan="3" class="text-upper-case text-italic"">${shortAddress}</td>
                                    </tr>
                                      ${
                                        !this.retailOptimization
                                          ? `<tbody>
                                              <tr>
                                                <td style="width: 33%; background-color: #2F53BA; color: white;">TLTD</td>
                                                <td style="width: 33%; background-color: #2F53BA; color: white;">TGT</td>
                                                <td style="width: 33%; background-color: #2F53BA; color: white;">TDT</td>
                                              </tr>
                                              <tr>
                                                <td style="width: 33%;">${tltd}</td>
                                                <td style="width: 33%;">${tgt}</td>
                                                <td style="width: 33%;">${tdt}</td>
                                              </tr>
                                            </tbody>`
                                          : ""
                                      }
                                  </tbody>
                                </table>
                              </div>
                              </div>`;

      let htmlReadyDriver = `<div id='order_${order.order_id}'>
                                <table id="tbl_r_${order.order_id}">
                                  <thead>
                                    <tr>
                                      <th colspan="5" class="text-upper-case">${customerName}<th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td colspan="5" class="text-upper-case">${shortAddress}</td>
                                    </tr>
                                    <tr>
                                        <td colspan="5" style="padding-top: 0.2rem;text-align: center;"><span class="is-ready clrready f-w-6" > READY </span> </td>
                                      </tr>
                                      <tr>
                                        <td colspan="2" style="padding-top: 0.2rem;"><span class="f-w-6" style="text-align:left;"> DELIVER </span> </td>
                                        <td colspan="1" style="padding-top: 0.2rem;"><span style="text-align:center;"> IN </span> </td>
                                        ${
                                          !this.retailOptimization
                                            ? `<td colspan="1" style="text-align:center; padding-top: 0.2rem;">${tltd} </td>`
                                            : ""
                                        }
                                      
                                        ${sched}
                                      </tr>
                                  </tbody>
                                </table>
                              </div>`;
      htmlReadyDriver = html;

      let htmlNotReadyDriver = `<div id='order_${order.order_id}'>
                                  <table id="tbl_nr_${order.order_id}">
                                    <thead>
                                      <tr>
                                        <th colspan="5" class="text-upper-case">${customerName}<th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td colspan="5" class="text-upper-case">${shortAddress}</td>
                                      </tr>
                                      <tr>
                                        <td colspan="2" style="padding-top: 0.2rem;"><span class="red-c" style="text-align:left;"> READY </span> </td>
                                        <td colspan="1" style="padding-top: 0.2rem;"><span style="text-align:center;"> IN </span> </td>
                                        <td colspan="2" style="text-align:left; padding-top: 0.2rem;">${readyIn} </td>
                                      </tr>
                                      <tr>
                                        <td colspan="2" style="padding-top: 0.2rem;"><span class="f-w-6" style="text-align:left;"> DELIVER </span> </td>
                                        <td colspan="1" style="padding-top: 0.2rem;"><span style="text-align:center;"> IN </span> </td>
                                        ${
                                          !this.retailOptimization
                                            ? `<td colspan="1" style="text-align:center; padding-top: 0.2rem;">${tltd} </td>`
                                            : ""
                                        }
                                        ${sched}
                                      </tr>
                                    </tbody>
                                  </table>
                                  </div>`;
      htmlNotReadyDriver = html;

      const infowindow = new google.maps.InfoWindow({
        content: order.is_ready_to_deliver
          ? htmlReadyDriver
          : htmlNotReadyDriver,
        disableAutoPan: true,
      });

      var isWindowExpanded = false;

      infowindow.addListener("domready", () => {
        const element = document.getElementById(`order_${order.order_id}`);
        element.closest(".gm-style-iw").classList.add("is-order");

        if (!isWindowExpanded) {
          element.closest(".gm-style-iw-d").classList.add("collapsed-window");
        }

        const tc = element.closest(".gm-style-iw").nextElementSibling;
        const collapsedWind = document.getElementById(
          `order_${order.order_id}_col`
        );
        const expandedWind = document.getElementById(
          `order_${order.order_id}_exp`
        );

        if ((this.expandedOrderIds || []).find((o) => o == order.order_id)) {
          isWindowExpanded = true;
          expandedWind.classList.remove("display-none");
          collapsedWind.classList.add("display-none");
          element
            .closest(".gm-style-iw-d")
            .classList.remove("collapsed-window");
          element.closest(".gm-style-iw-d").classList.add("expanded-window");
        }

        tc.addEventListener("click", () => {
          if (!isWindowExpanded) {
            isWindowExpanded = true;
            this.expandedOrderIds.push(order.order_id);
            expandedWind.classList.remove("display-none");
            collapsedWind.classList.add("display-none");
            element
              .closest(".gm-style-iw-d")
              .classList.remove("collapsed-window");
            element.closest(".gm-style-iw-d").classList.add("expanded-window");
          } else {
            isWindowExpanded = false;
            this.expandedOrderIds = (this.expandedOrderIds || []).filter(
              (o) => o != order.order_id
            );
            collapsedWind.classList.remove("display-none");
            expandedWind.classList.add("display-none");
            element
              .closest(".gm-style-iw-d")
              .classList.remove("expanded-window");
            element.closest(".gm-style-iw-d").classList.add("collapsed-window");
          }
        });

        if (isSelected) {
          element.closest(".gm-style-iw-d").classList.add("is-selected");
          element.closest(".gm-style-iw-c").classList.add("is-selected");
          element.closest(".gm-style-iw-t").classList.add("is-selected");
        }

        if (isReturn) {
          element.closest(".gm-style-iw-d").classList.add("is-not-ready");
          element.closest(".gm-style-iw-c").classList.add("is-not-ready");
          element.closest(".gm-style-iw-t").classList.add("is-not-ready");
        } else {
          if (isAlmostReady && !isOnTheWay) {
            element.closest(".gm-style-iw-d").classList.add("is-almost-ready");
            element.closest(".gm-style-iw-c").classList.add("is-almost-ready");
            element.closest(".gm-style-iw-t").classList.add("is-almost-ready");
          } else if (!isReady && !isOnTheWay) {
            element.closest(".gm-style-iw-d").classList.add("is-not-ready");
            element.closest(".gm-style-iw-c").classList.add("is-not-ready");
            element.closest(".gm-style-iw-t").classList.add("is-not-ready");
          }

          if (isOnTheWay) {
            element.closest(".gm-style-iw-d").classList.add("on-the-way-is-ready");
            element.closest(".gm-style-iw-c").classList.add("on-the-way-is-ready");
            element.closest(".gm-style-iw-t").classList.add("on-the-way-is-ready");
          }
          else if (isReady) {
            element.closest(".gm-style-iw-d").classList.add("is-ready");
            element.closest(".gm-style-iw-c").classList.add("is-ready");
            element.closest(".gm-style-iw-t").classList.add("is-ready");
          }

          if (isDelivered) {
            element.closest(".gm-style-iw-d").classList.add("is-delivered");
            element.closest(".gm-style-iw-c").classList.add("is-delivered");
            element.closest(".gm-style-iw-t").classList.add("is-delivered");
          }
        }

        // element.onclick = () => {
        //   this.$store.dispatch("orders/toggleOrderSelection", order);
        // };
        document
          .getElementById(`order_${order.order_id}`)
          ?.addEventListener("click", () => {
            this.$store.dispatch("orders/toggleOrderSelection", order);
          });
      });
      infowindow.open(this.map, marker);

      this.orderMarkers.push(marker);
      this.orderInfoWindows.push(infowindow);

      this.zoomMapToFitMarkers();
    },

    createStoreMarker: function () {
      const google = window.google;
      // Create Store Marker
      var image = {
        url: "/img/Shop_Pin_Small.png",
        // This marker is 20 pixels wide by 32 pixels high.
        size: new google.maps.Size(26, 34),
        // The origin for this image is (0, 0).
        origin: new google.maps.Point(0, 0),
        // The anchor for this image is the base of the flagpole at (0, 32).
        anchor: new google.maps.Point(13, 34),
      };

      var marker = new google.maps.Marker({
        position: this.center,
        map: this.map,
        icon: image,
        label: "",
        info: {
          name: "shop",
        },
      });
      this.markers.push(marker);
    },

    removeMarkers: function (markers) {
      for (var i = 0; i < markers.length; i++) {
        markers[i].setMap(null);
      }
      markers.splice(0, markers.length);
    },

    removeInfoWindows: function (infoWindows) {
      for (var i = 0; i < infoWindows.length; i++) {
        infoWindows[i].close();
      }
      infoWindows.splice(0, infoWindows.length);
    },
  },
};
</script>

<style scoped>
#map {
  width: 100%;
}

.columns {
  margin: 0px;
}

.customer-name {
  color: black;
}
</style>
