<template>
  <div
    id="modal"
    class="modal-wrapper modal"
    v-bind:class="{ 'is-active': isActive }"
  >
    <!-- Bulma: create transparent overlay -->
    <div class="modal-background" v-on:click="toggleModal"></div>

    <!-- Bulma: content must go in modal-background. -->
    <div class="modal-card" v-bind:style="myWidth">
      <!-- Vue: to place child components in slots, add element attribute 'slot' -->
      <!-- Ex: <div slot="header"></div> to put elemnt/component in header slot -->
      <header class="modal-card-head">
        <slot name="header"></slot>
      </header>
      <section class="modal-card-body">
        <slot name="body"></slot>
      </section>
      <footer class="modal-card-foot">
        <slot name="footer"></slot>
      </footer>
    </div>
    <button class="modal-close" v-on:click="toggleModal"></button>
  </div>
</template>

<script>
module.exports = {
  data: function () {
    return {
      modalWidth: {
        width: "20vw",
      },
    };
  },
  props: {
    isActive: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
    myWidth: {
      default: function () {
        return { width: 50 + "vw" };
      },
    },
  },
  methods: {
    toggleModal: function () {
      this.$emit("close");
    },
  },
};
</script>

<style scoped>

  .modal-card-body{
    min-height: 10em;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
</style>