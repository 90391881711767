import Vue from 'vue';
import Vuex from 'vuex';
import VueResource from 'vue-resource';

Vue.use(VueResource);
Vue.use(Vuex);
const newDriverTransaction = {
  amount: 0,
  transactionType: "",
  transactionDateTime: "",
  driverId: "",
  isOpen: ""
}

// initial state
const state = {
  all: [],
  selected: {},
  driverInfoError: "",
  driverToDelete: null,
  driverBalances: [],
  driverBalanceFile: null,
  driverToOptimizeTours: null
}

function driverSort(a, b) {

  const a_driver_left = a.time_driver_left == null ? -1 : 0;
  const b_driver_left = b.time_driver_left == null ? -1 : 0;

  const a_driver_routed = a.route ? 1 : -1;
  const b_driver_routed = b.route ? 1 : -1;

  const a_LastArrival = a.time_driver_left == null ?
    a.last_returned_to_store ? new Date(parseInt(a.last_returned_to_store.substring(6, a.last_returned_to_store.length - 2))) : -1 :
    a.time_til_back_to_shop;

  const b_LastArrival = b.time_driver_left == null ?
    b.last_returned_to_store ? new Date(parseInt(b.last_returned_to_store.substring(6, b.last_returned_to_store.length - 2))) : -1 :
    b.time_til_back_to_shop;

  return a_LastArrival - b_LastArrival ||
    a_driver_left - b_driver_left ||
    a_driver_routed - b_driver_routed;
}

// getters
const getters = {
  getDrivers: state => {
    return state.all.filter(d => !d.deleted);
  },
  getAllDrivers: state => state.all, // this includes all the drivers of this store even the archived ones
  selectedDriver: state => state.selected,
  getDriverBalanceFile: state => state.driverBalanceFile,
  onDutyDrivers: state => {
    return state.all.filter(driver => driver.on_duty && !driver.deleted);
  },
  openDrivers: state => {
    return state.all.filter(driver => driver.IsStoreOpen && !driver.deleted);
  },
  inStoreDrivers: (state, getters) => {
    return getters.onDutyDrivers.filter(d => d.time_driver_left == null && !d.deleted);
  },
  onRoadDrivers: (state, getters) => {
    return getters.onDutyDrivers.filter(d => d.time_driver_left != null && !d.deleted);
  },
  offDutyDrivers: state => {
    return state.all.filter(driver => !driver.on_duty && !driver.deleted);
  },
  nextDriver: (state, getters) => {
    return getters.onDutyDrivers[0];
  },
  getStoreID(state, getters, rootState) {
    return rootState.user.store_id;
  },
  getBaseURL(state, getters, rootState) {
    return rootState.base_url;
  },
  getHubURL(state, getters, rootState) {
    return rootState.hub_url;
  },
  getDriverInfoError: (state) => {
    return state.driverInfoError;
  },
  getDriverToOptimizeTours: (state) => {
    return state.driverToOptimizeTours;
  },
  getAutoRouteSetting(state, getters, rootState) {
    return rootState.settings["auto_route_drivers"];
  },
  getApiUrl(state, getters, rootState) {
    return rootState.api_url;
  },
  getShowPromptModal: (state, getters, rootState) => {
    return rootState.app.showPromptModal
  },
  getDriverBalances: (state) => {
    const onDuty = state.all.filter(driver => driver.on_duty && !driver.deleted);

    return state.driverBalances.filter(i => {
      for (var d of onDuty) {
        if (d.driver_id === i.driverId || i.outstandingBalance !== 0) return true
      }
    });
  },
}

// actions
const actions = {
  getDrivers({
    commit,
    getters
  }) {
    var store_id = getters.getStoreID;
    var base_url = getters.getBaseURL;
    var par = {
      'store_id': store_id,
      'base_url': base_url
    }
    commit('getDrivers', par);
  },
  updateDrivers({
    commit
  }, d) {
    commit('reset');
    commit('updateDrivers', d);
  },
  selectDriver({
    commit,
    dispatch
  }, d) {
    commit('selectDriver', d);
    dispatch("orders/assignTentativeRoute", null, {
      root: true
    });
  },
  deselectDriver({
    commit,
    dispatch
  }) {
    commit('deselectDriver');
    dispatch("orders/clearTentativeRoute", null, {
      root: true
    });
  },
  setDriverBalanceFile({
    commit
  }, driver) {
    commit('setDriverBalanceFile', driver)
  },
  clearDriverBalanceFile({
    commit
  }) {
    commit('setDriverBalanceFile', null)
  },
  setDriverInfoError({
    commit
  }, message) {
    commit('setDriverInfoError', message)
  },
  setDriverToOptimizeTours({
    commit
  }, message) {
    commit('setDriverToOptimizeTours', message)
  },
  deleteDriver({
    getters,
    dispatch
  }, driver) {
    if (driver.on_duty == true) {
      dispatch("drivers/setDriverInfoError", "Driver is on duty. Take driver off duty to archive.", {
        root: true
      });
    } else {

      if (!getters.getShowPromptModal) {
        dispatch("app/togglePromptModal", 'DELETE_DRIVER', {
          root: true
        });
        return
      } else {
        dispatch("app/togglePromptModal", 'DELETE_DRIVER', {
          root: true
        });
      }

      var payload = {
        storeId: getters.getStoreID,
        driverId: driver.driver_id,
        phoneNumber: driver.phone_number,
        deleted: true
      };

      Vue.http.post(getters.getApiUrl + "Api/V1/Drivers/AddUpdateDriver/", payload)
        .then(
          (res) => {
            if (res.status != 200) {
              alert(res.bodyText);
            }
          },
          (res) => {
            console.log("Failed to delete driver...", res.body);
          }
        );
    }
  },
  addDriverAfterRegisterComplete({
    getters,
    dispatch
  }, driver) {
    const payload = {
      storeId: getters.getStoreID,
      driverName: driver.driver_name,
      driverLastName: driver.DriverLastName,
      phoneNumber: driver.phone_number,
      pin: driver.pin,
      onDuty: driver.on_duty,
    };

    Vue.http.post(getters.getApiUrl + "Api/V1/Drivers/AddUpdateDriver/", payload)
      .then(
        (res) => {
          dispatch("app/unlockFromPanel", null, {
            root: true
          });
          dispatch("app/setPanel", 'driverInfoPanel', {
            root: true
          });
          dispatch("app/lockOnPanel", 'driverInfoPanel', {
            root: true
          });
        },
        (res) => {
          console.log("Failed to delete driver...", res.body);
        }
      );
  },
  openDriver({
    commit,
    getters
  }, par) {
    var store_id = getters.getStoreID;
    var api_url = getters.getApiUrl;
    var par = {
      'base_url': api_url,
      'driverId': par.driverId,
      'amount': par.amount == undefined || par.amount == '' ? 0 : par.amount,
      'isOpen': true,
      'transactionType': par.transactionType,
      'storeId': store_id,
      'isUseActualTip': par.isUseActualTip
    }
    commit('openDriver', par);
  },
  closeDriver({
    commit,
    getters,
    dispatch
  }) {
    var store_id = getters.getStoreID;
    var api_url = getters.getApiUrl;

    var balance = state.driverBalances.find(d => d.driverId === state.driverBalanceFile.driverId).outstandingBalance;

    var par = {
      'base_url': api_url,
      'driverId': state.driverBalanceFile.driverId,
      'storeId': store_id,
      'amount': balance,
      'dispatch': dispatch
    }
    commit('closeDriver', par)
  },
  // get Current Day Orders
  getCurrentDayDriverTransaction({
    commit,
    getters
  }, par) {
    var base_url = getters.getHubURL
    var par = {
      'store_id': par.storeId,
      'driver_id': par.driverId == undefined || par.driverId == '' ? 0 : par.driverId,
      'base_url': base_url
    };
    commit('getCurrentDayDriverTransaction', par);
  },
  getDriverBalances({
    commit,
    getters
  }) {
    var par = {
      store_id: getters.getStoreID,
      api_url: getters.getApiUrl,
    }
    commit('getDriverBalances', par);
  },
  optimizeTours({
    commit,
    getters,
    dispatch
  }, par) {
    var store_id = getters.getStoreID;
    var base_url = getters.getBaseURL;
    console.log("Adsada", par)
    var par = {
      'base_url': base_url,
      'driver_id': par.driver_id,
      'store_id': store_id,
      'driver_returning_to_store': par.driver_returning_to_store,
      'dispatch': dispatch
    }
    commit('optimizeTours', par);
  },
}

// mutations
const mutations = {
  updateDrivers(state, drivers) {
    for (var i in drivers) {
      state.all.push(drivers[i]);
    }
    state.all.sort(driverSort);

    if (state.all.length > 0
      && state.selected?.driver_id) {
      state.selected = state.all.find(d => d.driver_id === state.selected.driver_id);
    } else {
      state.selected = null;
    }
  },
  reset(state) {
    state.all.splice(0, state.all.length);
  },
  selectDriver(state, driver) {
    state.selected = driver;
  },
  deselectDriver(state) {
    state.selected = null;
  },
  setDriverBalanceFile(state, driver) {
    state.driverBalanceFile = driver;
  },
  getDrivers(state, par) {
    var payload = {
      "store_id": par.store_id,
      "method": "GET"
    }
    Vue.http.post(par.base_url + 'drivers/', payload).then(res => {
      // success
      if (res.body.result != 0) {
        alert(res.body.error)
      } else {
        state.all.splice(0, state.all.length);
        for (var i in res.body.driver_info) {
          state.all.push(res.body.driver_info[i]);
        }

        state.all.sort(driverSort);
      }
    }, res => {
      console.log("Failed to get drivers...");
    });
  },
  setDriverInfoError(state, message) {
    state.driverInfoError = message;
  },
  setDriverToOptimizeTours(state, driver) {
    state.driverToOptimizeTours = driver;
  },
  getDriverBalances(state, par) {
    Vue.http.get(par.api_url + 'api/v1/drivers/GetDriverBalances/' + par.store_id).then(res => {
      // success
      state.driverBalances = res.body
    }, res => {
      console.error("Failed to get driver balances...", res.body);
    });
  },
  openDriver(state, par) {
    Vue.http.post(par.base_url + 'api/v1/drivers/OpenDriver', {
      storeId: par.storeId,
      driverId: par.driverId,
      amount: par.amount
    }).then(res => {

    }, res => {
      console.log('Failed to open driver');
    })
  },
  optimizeTours(state, par) {
    par.dispatch('app/togglePromptModal', 'OPTIMIZE_TOURS', { root: true });
    par.dispatch('showSpinner', '', { root: true })
    console.log("Optimizing Tours for: " + par.driver_id);

    var payload = {
      store_id: par.store_id,
      method: "GET",
      driver_id: par.driver_id,
      driver_returning_to_store: par.driver_returning_to_store
    };

    Vue.http.post(par.base_url + "driver/route/optimize-tours/", payload).then(
      (res) => {
        if (res.body.result != 0) {
          alert(res.body.error);
        }
        par.dispatch('hideSpinner', '', { root: true })
      },
      (res) => {
        console.log("Failed to optimize tours...", res.body);
        par.dispatch('hideSpinner', '', { root: true })
      }
    );
  },
  closeDriver(state, par) {
    Vue.http.post(par.base_url + 'api/v1/drivers/CloseDriver', {
      storeId: par.storeId,
      driverId: par.driverId,
      amount: par.amount
    }).then(async res => {
      par.dispatch('app/setPanel', 'accountPanel', { root: true })
      setTimeout(() => {
        par.dispatch('app/setPanel', 'dayPanel', { root: true })
        par.dispatch('app/togglePromptModal', 'CLOSE_DRIVER', { root: true });
      },
        250)

    }, res => {
      console.log('Failed to close driver');
    })

  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}