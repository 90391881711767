<template>
  <div class="card">
    <header class="card-header">
      <p class="card-header-title">Manage Drivers</p>
      <a
        class="card-header-icon button is-primary"
        v-if="!newDriverForm && !editDriverForm"
        v-on:click="toggleShowAddNewDriver"
        v-bind:class="{ 'highlight': isLocked && !isDriverAdded }"
      >
        <i class="fa fa-plus"></i>
        Add
      </a>

      <a
        class="card-header-icon button is-primary"
        v-if="newDriverForm"
        v-on:click="saveNewDriver()"
      >
        <i class="fa fa-floppy-o"></i>
        Save
      </a>
      <a
        class="card-header-icon button"
        v-if="newDriverForm"
        v-on:click="toggleShowAddNewDriver()"
      >
        Cancel
      </a>
      <a
        class="card-header-icon button is-warning"
        v-if="!editDriverForm && !newDriverForm"
        v-on:click="selectedDriver != '' && toggleShowEditDriver()"
        :disabled="selectedDriver == ''"
      >
        <i class="fa fa-pencil"></i>
        Edit
      </a>
      <a
        class="card-header-icon button is-primary"
        v-if="editDriverForm"
        v-on:click="saveEditDriver()"
      >
        <i class="fa fa-floppy-o"></i>
        Save
      </a>
      <a
        class="card-header-icon button"
        v-if="editDriverForm"
        v-on:click="cancelEditDriver()"
      >
        Cancel
      </a>
      <a
        class="card-header-icon button is-danger"
        v-on:click="
          selectedDriver != '' && toggleDeleteDriverModal(selectedDriver)
        "
        :disabled="selectedDriver == ''"
      >
        <i class="fa fa-trash"></i>
        Archive
      </a>
    </header>

    <div class="card-content">
      <p class="message-title" v-if="isLocked && !isDriverAdded">
        DOWNLOAD THE DRIVER APP! ADD YOUR FIRST DRIVER AND WE'LL TEXT YOU THE LINK
      </p>

      <p class="message-title" v-if="isLocked && isDriverAdded">
        CLOCK IN FROM YOUR APP TO CONTINUE
      </p>
      
      <article class="message is-danger" v-if="this.errorMessage">
        <div class="message-header">
          <p>Error</p>
          <button class="delete" v-on:click="closeErrorMessage"></button>
        </div>
        <div class="message-body">
          {{ this.errorMessage }}
          <a class="has-link" style="color: #3273dc !important;" v-if="errorMessage === addDriverWithSameNumberMessage" v-on:click="saveNewDriver(true)">Update Driver</a>
          <a class="has-link" style="color: #3273dc !important;" v-if="errorMessage === driverReactivationMessage" v-on:click="reactivateDriver">Reactivate Driver</a>
        </div>
      </article>

      <div v-if="!isLocked">
        <b>Show Inactive Drivers </b>
        <label class="switch">
          <input type="checkbox" v-model="showInactiveDrivers">
          <span class="slider round"></span>
        </label>
      </div>

      <br>

      <div class="columns table-headings is-size-7">
        <div class="column is-1 has-text-left">ID</div>
        <div class="column is-2 has-text-left">FIRSTNAME</div>
        <div class="column is-2 has-text-left">LASTNAME</div>
        <div class="column is-2 has-text-left">PHONE</div>
        <div class="column is-2 has-text-left">PIN</div>
        <div class="column is-2 has-text-left">ON DUTY</div>
      </div>

      <div v-if="newDriverForm" class="columns table-rows is-size-7">
        <div class="column is-1 has-text-left"></div>
        <div class="column is-2 has-text-left">
          <div class="field">
            <input
              class="input is-small"
              type="text"
              placeholder="Driver First Name"
              v-model="newDriver.driver_name"
            />
          </div>
        </div>
        <div class="column is-2 has-text-left">
          <div class="field">
            <input
              class="input is-small"
              type="text"
              placeholder="Driver Last Name"
              v-model="newDriver.DriverLastName"
            />
          </div>
        </div>
        <div class="column is-2 has-text-left">
          <input
            class="input is-small"
            type="text"
            maxlength="10"
            placeholder="Phone Number"
            v-model="newDriver.phone_number"
          />
          <p
            class="help is-danger"
            v-bind:style="{
              visibility: errorPhoneNumber ? 'visible' : 'hidden',
            }"
          >
            Required
          </p>
        </div>
        <div class="column is-2 has-text-left">
          <input
            class="input is-small"
            type="text"
            placeholder="Pin"
            v-model="newDriver.pin"
          />
        </div>
        <div class="column is-2 has-text-left">
          <a
            class="is-small button"
            v-on:click="newDriver.on_duty = !newDriver.on_duty"
            v-bind:class="[
              { 'is-primary': newDriver.on_duty },
              { 'is-danger': !newDriver.on_duty },
            ]"
            >{{ newDriver.on_duty ? "YES" : "NO" }}
          </a>
        </div>
      </div>

      <div
          class="order-details-area"
          style="height: calc(100vh - 200px);"
        >
      <div
        v-for="driver in showInactiveDrivers ? allDrivers : drivers"
        v-on:click="selectDriver(driver)"
        class="columns table-rows is-size-7"
        :class="[
          {
            'has-background-link': driver.driver_id == selectedDriver.driver_id,
          },
        ]"
        v-bind:key="driver.driver_id"
      >
        <div class="column is-1 has-text-left text-bold">{{ driver.driver_id }}</div>
        <div class="column is-2 has-text-left text-bold">
          <div v-if="editDriver.driver_id != driver.driver_id">
            {{ driver.driver_name.toUpperCase() }}
          </div>
          <input
            v-if="editDriver.driver_id == driver.driver_id"
            class="input is-small"
            type="text"
            v-model="editDriver.driver_name"
          />
        </div>
        <div class="column is-2 has-text-left text-bold">
          <div v-if="editDriver.driver_id != driver.driver_id">
            {{ driver.DriverLastName.toUpperCase() }}
          </div>
          <input
            v-if="editDriver.driver_id == driver.driver_id"
            class="input is-small"
            type="text"
            v-model="editDriver.DriverLastName"
          />
        </div>
        <div class="column is-2 has-text-left">
          <div v-if="editDriver.driver_id != driver.driver_id">
            {{ driver.phone_number }}
          </div>
          <input
            v-if="editDriver.driver_id == driver.driver_id"
            class="input is-small"
            type="text"
            placeholder="Phone Number"
            v-model="editDriver.phone_number"
          />
          <p
            v-if="editDriver.driver_id == driver.driver_id"
            class="help is-danger"
            v-bind:style="{
              visibility: errorPhoneNumber ? 'visible' : 'hidden',
            }"
          >
            Required length of 10
          </p>
        </div>
        <div class="column is-2 has-text-left">
          <div v-if="editDriver.driver_id != driver.driver_id">********</div>
          <input
            v-if="editDriver.driver_id == driver.driver_id"
            class="input is-small"
            type="text"
            placeholder="Pin"
            v-model="editDriver.pin"
          />
        </div>
        <div class="column is-2 has-text-left">
          <span
            class="has-text-weight-medium"
            v-bind:class="[
              {
                'has-text-primary':
                  driver.on_duty &&
                  selectedDriver.driver_id != driver.driver_id,
              },
              {
                'has-text-danger':
                  !driver.on_duty &&
                  selectedDriver.driver_id != driver.driver_id,
              },
            ]"
          >
            <div v-if="editDriver.driver_id != driver.driver_id">
              <template v-if="driver.deleted">
                  <a class="button is-primary"
                    v-if="!newDriverForm && !editDriverForm"
                    v-on:click="reactivateDriver(driver)">
                    Reactivate
                  </a>
              </template>
              <template v-else>
                  {{ driver.on_duty ? "YES" : "NO" }}
              </template>
            </div>
          </span>
          <a
            v-if="editDriver.driver_id == driver.driver_id"
            class="is-small button"
            v-on:click="editDriver.on_duty = !editDriver.on_duty"
            v-bind:class="[
              { 'is-primary': editDriver.on_duty },
              { 'is-danger': !editDriver.on_duty },
            ]"
            >{{ editDriver.on_duty ? "YES" : "NO" }}</a
          >
        </div>
      </div>
      </div>
    </div>
  </div>
</template>

<script>
var timeFunctions = require("../../js/timeFunctions.js");

module.exports = {
  components: {},
  data: function () {
    return {
      store_id: this.$store.state.user.store_id,
      base_url: this.$store.state.base_url,
      api_url: this.$store.state.api_url,
      newDriverForm: false,
      editDriverForm: false,
      newDriver: {
        driver_name: "",
        phone_number: "",
        DriverLastName: "",
        pin: "",
        on_duty: false,
      },
      selectedDriver: "",
      editDriverOriginal: "",
      editDriver: {
        driver_id: "",
      },
      validationErrorPhoneNumber: false,
      driverReactivationMessage: "A driver with the provided phone number was deactivated before. Do you wish to reactivate this driver with the new information?",
      addDriverWithSameNumberMessage: "A driver with the provided phone number already exists in this store. Would you like to update the existing driver's information with the new details?",
      showInactiveDrivers: false
    };
  },
  filters: {},
  computed: {
    storeInfo: function () {
      return this.$store.getters["orders/getStore"]
    },
    isLocked: function () {
      return this.$store.getters["app/getIsLocked"];
    },
    errorMessage: function () {
      return this.$store.getters["drivers/getDriverInfoError"];
    },
    drivers: function () {
      return this.$store.getters["drivers/getDrivers"];
    },
    allDrivers: function () {
      const allDrivers = this.$store.getters["drivers/getAllDrivers"];

      const sortedDrivers = allDrivers.sort((driverA, driverB) => {
        return (driverA.deleted === driverB.deleted) ? 0 : driverA.deleted ? 1 : -1;
      });

      return sortedDrivers;
    },
    errorPhoneNumber: function () {
      return this.validationErrorPhoneNumber;
    },
    isDriverAdded: function() {
      const allDrivers = this.$store.getters["drivers/getAllDrivers"];
      const storeSettings = this.$store.state.settings;
      return allDrivers.some(d => (d.driver_name != "Driver" || d.DriverLastName != "A") || (d.phone_number !== storeSettings.storePhoneNumber && storeSettings.firstDriverClockedIn === false));
    }
  },
  methods: {
    convertDate: (a) => timeFunctions.convertDate(a),
    setPanel: function (panel) {
      this.$store.dispatch("app/setPanel", panel);
    },
    toggleShowAddNewDriver: function () {
      this.newDriverForm = !this.newDriverForm;
      if (!this.newDriverForm) {
        this.newDriver.driver_name = "";
        this.newDriver.DriverLastName = "";
        this.newDriver.phone_number = "";
        this.newDriver.pin = "";
        this.newDriver.on_duty = false;
      }
    },
    selectDriver: function (driver) {
      if(driver.deleted){
        return;
      }
      const driver_id = driver.driver_id;

      // only enable selecting new driver if driver is not being edited
      if (this.editDriver.driver_id == "") {
        if (this.selectedDriver?.driver_id == driver_id) {
          this.$store.dispatch("drivers/setDriverInfoError", "");
          this.selectedDriver = "";
        } else {
          this.$store.dispatch("drivers/setDriverInfoError", "");
          this.selectedDriver = this.drivers.find(
            (d) => d.driver_id == driver_id
          );
        }
      }
    },
    updateUserInfo: function () {
      // update the user info only in the signup process
      // in the signup process the page will be locked and the user should be updated with the first created driver
      if(!this.isDriverAdded && !this.isLocked){
        return;
      }

      const editUserPayload = {
        firstname: this.newDriver.driver_name,
        lastname: this.newDriver.DriverLastName,
        phone: this.newDriver.phone_number,
        email: this.storeInfo.email
      }
      this.$http.put(this.api_url +"Api/V1/Users", editUserPayload)
      .then(
        (res) => {              
          console.log("User updated successfully")
        },
        (err) => {
          console.log("Failed to update user info...", err.body);
        }
      );
    },
    saveNewDriver: function (updateDriverWithSameNumber = false) {
      if(this.drivers.some(driver => driver.phone_number === this.newDriver.phone_number) && !updateDriverWithSameNumber) {
        this.$store.dispatch(
          "drivers/setDriverInfoError",
          this.addDriverWithSameNumberMessage
        );
        return;
      }
      this.validationErrorPhoneNumber = false;
      if (
        !this.newDriver.phone_number ||
        this.newDriver.phone_number.length != 10
      ) {
        this.validationErrorPhoneNumber = true;
        return false;
      }

      let payload = {
          storeId: this.store_id,
          driverName: this.newDriver.driver_name,
          driverLastName: this.newDriver.DriverLastName,
          phoneNumber: this.newDriver.phone_number,
          pin: this.newDriver.pin,
          onDuty: this.newDriver.on_duty,
        };

        if (updateDriverWithSameNumber) {
          const existingDriver = this.drivers.find(driver => driver.phone_number === this.newDriver.phone_number);
          
          if (existingDriver) {
            payload = {
              ...payload,
              driverId: existingDriver.driver_id
            };
          }
        }


        this.$http
          .post(this.api_url + "Api/V1/Drivers/AddUpdateDriver/", payload)
          .then(
            (res) => {
              this.updateUserInfo();
              this.closeErrorMessage();
              this.toggleShowAddNewDriver();
            },
            (err) => {
              console.log("Failed to create new driver...", err.body);

              if(err.body == "Driver was deactivated"){
                this.$store.dispatch(
                  "drivers/setDriverInfoError",
                  this.driverReactivationMessage
                );
              } else{
                this.$store.dispatch(
                  "drivers/setDriverInfoError",
                  err.body
                );
              }
            }
          );
    },
    toggleShowEditDriver: function () {
      if (this.selectedDriver?.route_status != "No route") {
        this.$store.dispatch(
          "drivers/setDriverInfoError",
          "Driver is assigned route. Unassign driver to edit driver."
        );
      } else {
        // Switch edit buttons
        this.editDriverForm = true;
        // Load edit driver with driver details
        for (var key in this.selectedDriver) {
          this.$set(this.editDriver, key, this.selectedDriver[key]);
        }
        this.editDriverOriginal = this.selectedDriver;
        this.selectedDriver = "";
      }
    },
    cancelEditDriver: function () {
      this.validationErrorPhoneNumber = false;
      this.editDriver.driver_id = "";
      this.editDriver.pin = "";
      this.editDriverForm = false;
    },
    reactivateDriver: function(driver) {
      if(driver) {
        const payload = {
          storeId: this.store_id,
          driverId: driver.driver_id,
          phoneNumber: driver.phone_number,
          reactivate: true
        };

        this.$http
          .post(this.api_url + "Api/V1/Drivers/AddUpdateDriver/", payload)
          .then(
            (res) => {
              this.closeErrorMessage()
            },
            (err) => {
              this.$store.dispatch(
                  "drivers/setDriverInfoError",
                  "Error in reactivating this driver: " + err.body
                );
            }
          );
      } 
      else {
        this.editDriver = this.newDriver;
        this.editDriver.route_status = "No route"
        this.saveEditDriver(true)
      }
    },
    saveEditDriver: function (reactivate = false) {
      this.validationErrorPhoneNumber = false;
      if (
        !this.editDriver.phone_number ||
        this.editDriver.phone_number.length != 10
      ) {
        this.validationErrorPhoneNumber = true;
        return false;
      }

      // Check if driver is busy
      if (this.editDriver.route_status != "No route") {
        this.$store.dispatch(
          "drivers/setDriverInfoError",
          "Driver is on duty. Return driver to edit driver."
        );
      } else {
        // Log that system is saving driver changes
        if (!this.editDriver.pin) {
          this.editDriver.pin = null;
        }

        const payload = {
          storeId: this.store_id,
          driverId: this.editDriver.driver_id,
          driverName: this.editDriver.driver_name,
          driverLastName: this.editDriver.DriverLastName,
          phoneNumber: this.editDriver.phone_number,
          pin: this.editDriver.pin,
          onDuty: this.editDriver.on_duty,
          reactivate: reactivate
        };

        this.$http
          .post(this.api_url + "Api/V1/Drivers/AddUpdateDriver/", payload)
          .then(
            (res) => {
              this.closeErrorMessage()
              this.cancelEditDriver();
              if(reactivate){
                this.toggleShowAddNewDriver();
              }
            },
            (err) => {
              if(err.body == "Driver was deactivated"){
                const message = "A driver with the provided phone number was deactivated before. Do you wish to reactivate this driver with the new information?"
                this.$store.dispatch(
                  "drivers/setDriverInfoError",
                  message
                );
              } else{
                this.$store.dispatch(
                  "drivers/setDriverInfoError",
                  err.body
                );
              }
            }
          );
      }
    },
    toggleDeleteDriverModal: function (driver) {
      this.$store.dispatch("drivers/selectDriver", driver)
      this.$store.dispatch("drivers/deleteDriver", driver);
      this.selectedDriver = "";
    },
    closeErrorMessage: function () {
      this.$store.dispatch("drivers/setDriverInfoError", "");
    },
  },
};
</script>

<style scoped>
.is-on-duty {
  color: #00d1b2;
}
.not-on-duty {
  color: #ff3860;
}
.main-btn {
  width: 2.25em !important;
}
.columns,
.table {
  margin: 0 !important;
}

.column{
  display: flex;
  align-items: center;
  height: 4em;
}
.order-details-area {
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.message-title{
  font-size: 1.5em;
  font-weight: bold;
  margin-bottom: 1em;
  padding: 1em;
  background-color: #f14668;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.highlight {
  animation: pulse 1s infinite;
}

@keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

</style>