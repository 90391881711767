
// initial state
const state = {
  all: [],
  showRemoveRouteModal: false
}

// getters
const getters = {

}

// actions
const actions = {
  updateRoutes({commit},routes){
    commit('reset');
    commit('updateRoutes', routes);
  },
  toggleRemoveRouteModal({
    commit
  }) {
    commit('toggleRemoveRouteModal')
  },
}

// mutations
const mutations = {
  updateRoutes(state, routes){
    for (var i in routes){
      state.all.push(routes[i]);
    }    
  },
  reset(state){
    state.all.splice(0,state.all.length);
  },
  toggleRemoveRouteModal(state){
    state.showRemoveRouteModal = !state.showRemoveRouteModal;
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}