<template>
    <div class="image-uploader">
        <label for="imageInput">
            <div v-if="!selectedImage" class="upload-placeholder">
                <span>Click to Upload</span>
            </div>
            <img v-else :src="selectedImage" alt="Selected Image" class="selected-image"/>
        </label>
        <input type="file" id="imageInput" ref="fileInput" @change="handleImageChange" accept=".jpeg, .jpg, .png"
            style="display: none" />
    </div>
</template>
  
<script>
export default {
    props: {
        oldImage: String,
        onNewImageSelected: Function,
    },
    data() {
        return {
            selectedImage: this.oldImage,
        };
    },
    watch: {
        newImage(newVal) {
            this.selectedImage = newVal;
        },
    },
    methods: {
        handleImageChange(event) {
            const file = event.target.files[0]
            this.selectedImage = URL.createObjectURL(file);
            this.onNewImageSelected(file);
        },
    },
};
</script>
    
<style scoped>
.image-uploader {
    position: relative;
    width: 100px;
    height: 100px;
    overflow: hidden;
    border: 2px dashed #ccc;
    border-radius: 8px;
    cursor: pointer;
}

.upload-placeholder {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    color: #666;
}

.selected-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
}

button {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    padding: 8px 16px;
    background-color: #3498db;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}
</style>
  