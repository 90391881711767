<template>
<input class="input" :class="{'is-danger': error}" placeholder="ADDRESS" ref="autocomplete" v-bind:value="value" v-on:input="$emit('input', $event.target.value)" v-on:focus.capture="selectInputContent" type="text" />
</template>

<script>
module.exports = {
    data: function () {
        return {
            isMounted: false,
        };
    },
    computed: {
        computedMapStatus: function () {
            return (
                window.map_loaded.ready &&
                this.$store.state.settings.latitude &&
                this.$store.state.settings.longitude &&
                this.isMounted
            );
        },
        storeLatitude: function () {
            return this.$store.state.settings.latitude;
        },
        storeLongitude: function () {
            return this.$store.state.settings.longitude;
        },
        orderRadius: function () {
            return this.$store.state.settings.max_order_radius | 6.2;
        },
    },
    watch: {
        computedMapStatus: function () {
            this.initializeAutoComplete();
        },
    },
    props: ["value", "error"],
    mounted() {
        this.isMounted = true;
    },
    methods: {
        selectInputContent: function (e) {
            e.target.select();
            this.leftPositionCursor();
        },
        initializeAutoComplete: function () {
            {
                const geolocation = {
                    lat: this.storeLatitude,
                    lng: this.storeLongitude,
                };

                const radius = this.orderRadius * 1610;

                const options = {
                    componentRestrictions: {
                        country: ["us", "ca"]
                    },
                    fields: ["address_component,formatted_address,name,types"],
                    origin: geolocation,
                    strictBounds: true,

                };

                const autocomplete = new window.google.maps.places.Autocomplete(
                    /** @type {!HTMLInputElement} */
                    (this.$refs.autocomplete),
                    options
                );

                var circle = new window.google.maps.Circle({
                    center: geolocation,
                    radius: radius,
                });

                autocomplete.setBounds(circle.getBounds());
                autocomplete.addListener("place_changed", () => {
                    const place = autocomplete.getPlace();

                    const selectedAddress =
                        place.types.indexOf("street_address") > -1 ?
                        place.formatted_address.replace(", USA", "") :
                        place.name + ", " + place.formatted_address
                        .replace(place.name + ", ", "")
                        .replace(", USA", "");

                    this.$emit("input", selectedAddress);
                });
            }
        },
        leftPositionCursor: function (e) {
            e.target.selectionStart = e.target.selectionEnd = 0;
        },
    },
};
</script>

<style>
.pac-item-query {
    font-size: 21px;
}

.pac-item {
    font-size: 16px;
}
</style>
