<template>
  <div>

    <div v-if="!componentClicked" class="columns">
      <div class="column is-half">
        <div class="menu">
          <ul class="menu-list">
            <li class="btn">
              <a @click="handleMenuClick('cards')">Cards
                <span class="default-payment-method" v-if="defaultPaymentMethodLast4">({{ defaultPaymentMethodLast4
                }})</span>
              </a>
            </li>
            <li class="btn">
              <a @click="handleMenuClick('credits')">Credits
                <span class="credit-amount">{{ this.moneyFormat(this.storeInfo.credits || 0) }}</span>
              </a>
            </li>
            <li class="btn">
              <a @click="handleMenuClick('credits')">Add Credits
              </a>
            </li>
            <!-- <li class="btn">
              <a @click="handleMenuClick('monthlyCharges')">Monthly Charge</a>
            </li> -->
          </ul>
        </div>
      </div>
      <div class="column is-half">
        <div class="notifications">
          <b>Notifications</b>
          <div class="checkboxes">
            <div class="checkbox">
              <label>
                <input type="checkbox" v-model="notificationMethods.sms">
                SMS
              </label>
            </div>
            <div class="checkbox">
              <label>
                <input type="checkbox" v-model="notificationMethods.email">
                Email
              </label>
            </div>
            <div class="checkbox">
              <label>
                <input type="checkbox" v-model="notificationMethods.inApp">
                In-App
              </label>
            </div>
          </div>
          <br>
          <b>Threshold</b>
          <div class="checkboxes">
            <div>
              <input class="input" type="number" v-model="threshold" style="width: 17%">
            </div>
            <br>
          </div>
          <button class="button is-primary" @click="saveStoreCreditNotificationPreferences">Update</button>
        </div>
      </div>
    </div>

    <div v-if="componentClicked">
      <a class="button" @click="handleBackClick">
        Back
      </a>

      <component :is="componentClicked"></component>
    </div>

  </div>
</template>
  
<script>
var cards = require("./cards.vue").default;
var credits = require("./credits.vue").default;
var monthlyCharges = require("./monthly_charges.vue").default;
var custom = require('../../../js/custom')

module.exports = {
  components: {
    cards,
    credits,
    monthlyCharges,
    custom
  },
  data() {
    return {
      api_url: this.$store.state.api_url,
      store_id: this.$store.state.user.store_id,
      defaultPaymentMethodLast4: null,
      notificationMethods: {
        sms: false,
        email: false,
        inApp: false,
      },
      threshold: 50,
    };
  },
  created() {
    this.getDefaultPaymentMethod();
    this.getStoreCreditNotificationPrefrences();
  },
  computed: {
    storeInfo: function () {
      return this.$store.getters["orders/getStore"]
    },
    componentClicked: function() {
      return this.$store.getters["app/getSettingsPanelBillingTabComponent"];
    }
  },
  methods: {
    moneyFormat(number) {
      return custom.moneyFormat(number)
    },
    getStoreCreditNotificationPrefrences() {
      this.$http
        .get(this.api_url + `Api/V1/Stores/GetStoreCreditNotificationPreferences/${this.store_id}`)
        .then(
          (res) => {
            this.threshold = res.body.threshold;
            this.notificationMethods.email = res.body.emailEnabled;
            this.notificationMethods.sms = res.body.smsEnabled;
            this.notificationMethods.inApp = res.body.inAppEnabled;
          },
          (err) => {
            console.log("Error", err);
          }
        );
    },
    getDefaultPaymentMethod() {
      if (this.storeInfo.stripeCustomerId) {
        this.$http
          .get(this.api_url + `Api/V1/Payment/Stripe/GetDefaultPaymentMethod/${this.storeInfo.stripeCustomerId}`)
          .then(
            (res) => {
              this.defaultPaymentMethodLast4 = res.body.last4Numbers
            },
            (err) => {
              console.log("Error", err);
            }
          );
      }
    },
    saveStoreCreditNotificationPreferences() {
      const body = {
        threshold: this.threshold,
        emailEnabled: this.notificationMethods.email,
        smsEnabled: this.notificationMethods.sms,
        inAppEnabled: this.notificationMethods.inApp,
        storeId: this.store_id
      };

      this.$http
        .post(this.api_url + `Api/V1/Stores/SaveStoreCreditNotificationPreferences`, body)
        .then(
          () => {

          },
          (err) => {
            console.log("Error", err);
          }
        );
    },
    handleMenuClick(component) {
      this.$store.dispatch("app/setSettingsPanelBillingTabComponent", component);
    },
    handleBackClick() {
      this.getDefaultPaymentMethod()
      this.$store.dispatch("app/setSettingsPanelBillingTabComponent", null);
    }
  },
};
</script>
  
<style scoped>

.menu-list {
  display: flex;
  flex-direction: column;
  gap: 1em;
}
.menu-list a {
  cursor: pointer;
}

.column {
  padding: 20px;
}

.notifications {
  background-color: #f5f5f5;
  padding: 10px;
}

.checkboxes {
  margin-top: 10px;
}

.checkbox {
  display: block;
  margin-bottom: 5px;
}

.default-payment-method,
.credit-amount {
  margin-left: 5px;
  font-weight: 700;
}

.btn {
  border-radius: 2px;
  border: 1px #dbdbdb solid;
  width: 150px;
}

.btn:hover {
  border-color: #b5b5b5;
}

</style>
  