<template>
    <div class="section">
        <div class="container">
            <div class="card">
                <div class="card-content">
                    <div class="hero">
                        <div class="hero-body">
                            <img class="img" src="./../../public/img/Ontime-logo.png" />
                            <h3 class="title has-text-centered">Try Ontime for free</h3>
                            <h6 class="title sub-title has-text-centered">30-days free trial | no credit card needed</h6>
                        </div>
                    </div>
                    <div class="field">
                        <label class="label">Business Email <span class="required-span"> *</span></label>
                        <p class="control has-icons-left has-icons-right">
                            <input class="input" type="email" placeholder="email@company.com" v-model="email" required />
                            <span class="icon is-small is-left">
                                <i class="fa fa-envelope"></i>
                            </span>
    
                            <p class="help is-danger" v-bind:style="{
                    visibility: emptyEmail ? 'visible' : 'hidden',}">
                                Required
                            </p>
                        </p>
                    </div>
                    <div class="field">
                        <!-- <div class="tooltip">
                            <label class="label">Password <span class="required-span"> *</span> </label>
                            <span class="tooltiptext">Minimum length 8 characters, 1 special character, 1 numeric atleast</span>
                        </div> -->
                        <label class="label">Password <span class="required-span"> *</span> </label>
                        <p class="control has-icons-left">
                            <input class="input" type="password" v-model="password" placeholder="Password Must be 8+ characters" />
                            <span class="icon is-small is-left">
                                <i class="fa fa-lock"></i>
                            </span>
                        </p>
                        <p class="help is-danger" v-bind:style="{ visibility: emptyPassword ? 'visible' : 'hidden' }">
                            Required
                        </p>
                    </div>
                    <div class="field">
                        <label class="label">Confirm Password <span class="required-span"> *</span></label>
                        <p class="control has-icons-left">
                            <input class="input" type="password" v-model="confirmPassword" placeholder="Must match the password" />
                            <span class="icon is-small is-left">
                                <i class="fa fa-lock"></i>
                            </span>
                        </p>
                        <p class="help is-danger" v-bind:style="{ visibility: emptyPassword ? 'visible' : 'hidden' }">
                            Required
                        </p>
                    </div>
                    <p class="help is-danger" v-bind:style="{
                  visibility: showPasswordError ? 'visible' : 'hidden',
                }">
                        Account creation failed, contact ontimedrivermanager@gmail.com for
                        assistance.
                    </p>
    
                    <p class="help is-danger" v-if="isServerSideError && !errors.length">{{serverSideErrorMessage}}</p>
    
                    <p v-if="errors.length">
                        <b>Please correct the following error(s):</b>
                        <ul>
                            <li class="help is-danger" v-for="error in errors">{{ error }}</li>
                        </ul>
                    </p>
                    <br>
                    <div class="field">
                        <button class="button is-medium is-responsive custombtn is-fullwidth" v-on:click="signup">
                            Create Account
                        </button>
                    </div>
    
                    <p class="gdpr-text">
                        By clicking &quot;Create account,&quot; you agree to the
                        <a class="link-text" data-target="#privacy-policy-modal" data-toggle="modal">Privacy Policy</a>
                        and
                        <a class="link-text" data-target="#terms-of-service-modal" data-toggle="modal">Terms of Service</a>
    
                        <span>
                            .
                        </span>
                    </p>
    
                    <div class="have-account-text signin">
                        Already have an account?
                        <a data-disable-with="<i class=&quot;spinner spinner-grey&quot;></i>" v-on:click="cancelSignup">Sign in</a>
                    </div>
                </div>
            </div>
    
            <modal>
                <div slot="header" class=" report-header has-text-centered">Email confirmation</div>
                <div slot="body" class="has-text-centered">
                    We just sent an email to the address: {{ email }}
                    Please check your email
                    and click on the link provided to verify your address.
                    <br>
                    <a class="card-footer-item" v-on:click="cancelSignup">Login</a>
                </div>
            </modal>
        </div>
    </div>
    </template>
    
    <script>
    require("../js/config.js");
    var modal = require("./modal.vue").default;
    
    module.exports = {
        components: {
            modal,
        },
        data: function () {
            return {
                email: "",
                password: "",
                confirmPassword: "",
                passwordError: false,
                emptyEmailValidation: false,
                emptyPasswordValidation: false,
                emptyConfirmPasswordValidation: false,
                errors: [],
            };
        },
        computed: {
            showPasswordError() {
                return this.$store.state.user.passwordError;
            },
            isServerSideError() {
                return this.$store.state.user.isServerSideError;
            },
            serverSideErrorMessage() {
                return this.$store.state.user.serverSideErrorMessage;
            },
            emptyEmail() {
                return this.emptyEmailValidation;
            },
            emptyAddress() {
                return this.emptyAddressValidation;
            },
            emptyPassword() {
                return this.emptyPasswordValidation;
            },
            emptyConfirmPassword() {
                return this.emptyConfirmPasswordValidation;
            },
        },
        methods: {
            signup: function () {
                this.errors = [];
                this.emptyEmailValidation = false;
                this.emptyPasswordValidation = false;
                this.emptyConfirmPasswordValidation = false;
                this.validEmailValidation = false;
                this.validPasswordValidation = false;
                this.validConfirmPasswordValidation = false;
                this.passwordMachedValidation = false;
    
                //email validation
                if (!this.email) {
                    this.emptyEmailValidation = true;
                } else if (!this.isEmailValid(this.email)) {
                    this.errors.push("Must be a valid email.");
                    this.validEmailValidation = true;
                }
    
                //paassword validation
                if (!this.password) {
                    this.emptyPasswordValidation = true;
                } else if (!this.isPasswordValid(this.password)) {
                    this.errors.push(
                        "Password must be between 6 and 20 characters and contain one uppercase letter, one lowercase letter, one digit and one special character."
                    );
                    this.validPasswordValidation = true;
                }
    
                //confirm password validation
                if (!this.confirmPassword) {
                    this.emptyConfirmPasswordValidation = true;
                }
    
                //password maching validation
                if (this.password == this.confirmPassword) {
                    this.passwordMachedValidation = true;
                } else if (!this.isPasswordMached(this.password, this.confirmPassword)) {
                    this.errors.push(
                        "The password and confirmation password do not match."
                    );
                    this.passwordMachedValidation = true;
                }
                if (!this.accepted) {
                    this.emptyAcceptedValidation = true;
                } else {
                    this.emptyAcceptedValidation = false;
                }
                if (
                    !(
                        this.emptyEmailValidation ||
                        this.emptyPasswordValidation ||
                        this.emptyConfirmPasswordValidation ||
                        this.validEmailValidation ||
                        this.validPasswordValidation ||
                        this.validConfirmPasswordValidation
                    )
                ) {
                    // create user model
                    var signupInfo = {
                        email: this.email,
                        password: this.password,
                        confirmPassword: this.confirmPassword,
                        roleName: "Restaurant",
                    };
                    this.$store.dispatch("user/signup", signupInfo);
                }
            },
    
            cancelSignup: function () {
                this.emptyEmailValidation = false;
                this.emptyPasswordValidation = false;
                this.emptyConfirmPasswordValidation = false;
                this.emptyAddressValidation = false;
                this.validEmailValidation = false;
                this.validPasswordValidation = false;
                this.validConfirmPasswordValidation = false;
                this.$store.dispatch("user/cancelSignup");
            },
    
            //is email valid
            isEmailValid: function (email) {
                var re =
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return re.test(email);
            },
    
            //is password valid
            isPasswordValid: function (password) {
                var re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{8,15}$/;
                return re.test(password);
            },
    
            // //is phone valid
            // isPhoneValid: function (phone) {
            //     var re = /^\+?(\d[\d-. ]+)?(\([\d-. ]+\))?[\d-. ]+\d$/;
            //     return re.test(phone);
            // },
    
            // //is username valid
            // isUserNameValid: function (userName) {
            //     var re = /^\S*$/;
            //     return re.test(userName);
            // },
    
            //is Password Mached
            isPasswordMached: function (password, confirmPassword) {
                if (password == confirmPassword) {
                    return true;
                } else {
                    return false;
                }
            },
        },
    };
    </script>
    
    <style scoped>
    body {
        -webkit-font-smoothing: antialiased !important;
        font-family: "Poppins", Arial, sans-serif !important;
    }
    
    .card {
        /* background-color: #fff;
          box-shadow: 0 1.5em 7em 7.875em rgb(10 10 10 / 10%), 0 0 0 1px rgb(10 10 10 / 2%) !important; */
        padding: 1rem 1rem 1.25rem;
        border: 0.0625rem solid #e3e3e3;
        box-shadow: 0 1.3125rem 5.9375rem #0000001a;
        border-radius: 0.8125rem;
        width: 50rem;
        max-width: 100%;
    }
    
    .card-content {
        padding: 0.5rem !important;
    }
    
    .title {
        text-align: center;
        font-weight: 500;
        font-size: 42px !important;
    }
    
    .sub-title {
        display: block;
        font-weight: 600;
        font-size: 0.875rem !important;
        line-height: 1.125rem;
    }
    
    .custombtn {
        background-color: #37c936 !important;
        color: #274456 !important;
    }
    
    .gdpr-text {
        text-align: center;
        color: #8e8e8e;
        font-size: 1rem;
        line-height: 1.25rem;
        font-weight: 600;
        margin: 1.5rem 0;
    }
    
    .sign-up .have-account-text {
        font-size: 1.125rem;
        font-weight: 600;
        line-height: 1.5rem;
        text-align: center;
        color: #252525;
    }
    
    .signin {
        text-align: right;
    }
    
    .section {
        background-color: #fff;
        height: 100vh;
        padding: 11vh;
        padding-top: 3vh;
    }
    
    .container {
        width: 40vw;
    }
    
    .hero-body {
        padding: 24px;
    }
    
    .hero-body {
        padding: 24px;
    }
    
    .field {
        margin-bottom: -0.25rem !important;
    }
    
    .report-header {
        width: 100%;
    }
    
    .privacy-font {
        font-size: 13px;
    }
    
    .content-position {
        margin-top: 5rem;
    }
    
    .title-color {
        color: white;
    }
    
    .img {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 60%;
    }
    
    .tooltip {
        position: relative;
        display: inline-block;
    }
    
    .tooltip .tooltiptext {
        visibility: hidden;
        width: 150px;
        background-color: black;
        color: #fff;
        text-align: center;
        border-radius: 6px;
        padding: 5px 0;
    
        /* Position the tooltip */
        position: absolute;
        z-index: 9999;
    }
    
    .tooltip:hover .tooltiptext {
        visibility: visible;
    }
    </style>
    