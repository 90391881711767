<template>
    <div class="notification-modal" :class="{ 'is-active': isActive }">
      <div class="modal-overlay" @click="closeModal"></div>
      <div class="modal-content">
        <button class="close-button" @click="closeModal">
          <i class="fa fa-times"></i>
        </button>
        <h3>{{ message }}</h3>
      </div>
    </div>
  </template>
  
  <script>
export default {
  props: {
    message: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      isActive: true
    };
  },
  methods: {
    closeModal() {
      this.isActive = false;
      this.$emit('close');
    }
  }
};
</script>

  <style scoped>
  .notification-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
  }
  
  .modal-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    position: relative;
    text-align: center;
  }
  
  .modal-content .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;
    border: none;
    color: #999;
    cursor: pointer;
    font-size: 18px;
  }
  
  .modal-content .close-button:hover {
    color: #333;
  }
  
  .modal-content h3 {
    margin-bottom: 10px;
    font-size: 20px;
    font-weight: bold;
    color: #333;
  }
  </style>
  