<template>
  <div class="nav-item">
    <a
      class="button is-small header-btn has-text-weight-medium"
      v-on:click="toggleModal"
    >
      <i class="fa fa-road"></i>
      Routes
    </a>

    <modal v-bind:isActive="modalStatus" v-on:close="toggleModal">
      <div slot="header">Routes</div>
      <div slot="body">
        <table class="table is-fullwidth">
          <thead>
            <tr>
              <th>Order Number</th>
              <th>Customer Name</th>
              <th>Address</th>
              <th>Driver</th>
              <th>TGT</th>
              <th>TUD</th>
              <th>TTD</th>
            </tr>
          </thead>
          <tbody>
            <template>
              <tr v-for="(order, index) in routes" v-bind:key="index">
                <td>{{ order.order_number }}</td>
                <td>{{ order.customer_name }}</td>
                <td>{{ order.order_address }}</td>
                <td>{{ getDriverName(order.driver_id) }}</td>
                <td>{{ order.target_time }}</td>
                <td>{{ order.time_until_delivery }}</td>
                <td>{{ order.estimated_total_time_elapsed }}</td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
      <div slot="footer"></div>
    </modal>
  </div>
</template>

<script>
var modal = require("../../modal.vue").default;

module.exports = {
  components: {
    modal,
  },
  data: function () {
    return {
      store_id: this.$store.state.user.store_id,
      base_url: this.$store.state.base_url,
      hub_url: this.$store.state.hub_url,
      api_url: this.$store.state.api_url,
      routes: this.$store.state.routes.all,
      drivers: this.$store.state.drivers.all,
      modalStatus: false,
    };
  },
  created: function () {
    this.getRoutes();
  },
  methods: {
    getDriverName(driverId) {
      const matchedDriver = this.drivers.find(d => d.driver_id === driverId);
      return matchedDriver
        ? `${matchedDriver.driver_name}${matchedDriver.DriverLastName ? ` ${matchedDriver.DriverLastName}` : ""}`
        : "";
    },
    getRoutes: function (callback) {
      var payload = { store_id: this.store_id, method: "GET" };

      this.$http.post(this.base_url + "orders/map/", payload).then(
        (res) => {
          this.$store
            .dispatch("routes/updateRoutes", res.body.order_map)
            .then(function () {
              if (typeof callback === "function") {
                callback();
              }
            });
        },
        (res) => {
          console.log("Failed to get routes...", res);
        }
      );
    },
    toggleModal: function () {
      this.modalStatus = !this.modalStatus;

      // Get new routes if modal is opened
      if (this.modalStatus == true) {
        this.getRoutes();
      }
    },
  },
};
</script>

<style scoped>
td {
  font-size: 12px;
}
th {
  font-size: 12px;
}
</style>