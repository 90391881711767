<template>
    <div class="container">
        <div class="columns">
            <div class="column">
                <div class="add-charge-container">
                    <div class="card-content">
                        <template v-if="!defaultPaymentMethod && !isFetchingDefaultPaymentMethod">
                            <div class="no-payment-methods">
                                No default payment method associated with your account. Please go to the cards screen and
                                add
                                a payment method and make it the default one.
                            </div>
                        </template>

                        <template v-else-if="isFetchingDefaultPaymentMethod">
                            <div class="loader"></div>
                        </template>

                        <template v-else>
                            <div v-if="scheduleSuccessful" class="success-payment">
                                Scheduled Payment Created Successfully!
                            </div>
                            <div v-if="scheduleInvalid" class="invalid-payment">
                                Invalid Schedule! Payment amount must be greater than 100$ and the Schedule Day must be
                                between
                                1 and 31
                            </div>
                            <h4 class="title is-4">Add Scheduled Payment</h4>
                            <div class="field is-horizontal">
                                <div class="field-label is-normal">
                                    <label class="label">Schedule Day</label>
                                </div>
                                <div class="field-body">
                                    <div class="field is-narrow">
                                        <div class="control">
                                            <input type="number" class="input custom-input-width" v-model="scheduleDay"
                                                min="1" max="31" placeholder="Schedule Day" />
                                        </div>
                                        <p class="help">
                                            Enter a number between 1 and 31 to specify the day of the month for the
                                            schedule.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="field is-horizontal">
                                <div class="field-label is-normal">
                                    <label class="label">Payment Amount ($)</label>
                                </div>
                                <div class="field-body">
                                    <div class="field is-narrow">
                                        <div class="control">
                                            <input type="number" class="input custom-input-width" v-model="paymentAmount"
                                                min="25" placeholder="Payment Amount" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="field is-horizontal">
                                <div class="field-label is-normal">
                                    <label class="label">Iterations</label>
                                </div>
                                <div class="field-body">
                                    <div class="field is-narrow">
                                        <div class="control">
                                            <input type="number" class="input custom-input-width" v-model="iterations"
                                                min="1" placeholder="Iterations" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="field is-horizontal">
                            <div class="field-label is-normal">
                                <label class="label">Payment Method</label>
                            </div>
                            <div class="field-body">
                                <div class="field is-narrow">
                                    <div class="control">
                                        <div v-if="paymentMethods.length === 0" class="loader-container">
                                            <div class="loader"></div>
                                        </div>
                                        <div v-else class="select">
                                            <select v-model="selectedPaymentMethod.paymentMethodId">
                                                <option v-for="(method, index) in paymentMethods"
                                                    :value="method.paymentMethodId" :key='index'>
                                                    {{ capitalizeFirstLetter(method.cardBrand) }} {{
                                                        cardNumber(method.last4Numbers) }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> -->
                            <div class="field is-horizontal">
                                <div class="field-label"></div>
                                <div class="field-body">
                                    <div class="field">
                                        <div class="control">
                                            <button @click="createScheduledPayment" class="button is-primary"
                                                :disabled="creatingSchedule">
                                                <span v-if="creatingSchedule">Creating...</span>
                                                <span v-else>Create Schedule</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
                <br />
                <hr />
                <!-- <div class="transactions-container">
                    <h4 class="title is-4">Scheduled Payments</h4>
                </div> -->
            </div>
        </div>
    </div>
</template>

<script>
var timeFunctions = require("../../../js/timeFunctions.js");

module.exports = {
    data: function () {
        return {
            api_url: this.$store.state.api_url,
            store_id: this.$store.state.user.store_id,
            paymentAmount: 100,
            stripe: null,
            cardNumberElement: null,
            expiryElement: null,
            cvcElement: null,
            selectedPaymentMethod: {},
            paymentMethods: [],
            scheduleSuccessful: false,
            transactions: [],
            selectedDate: '',
            creatingSchedule: false,
            scheduleInvalid: false,
            scheduleDay: 1,
            iterations: 12,
            defaultPaymentMethod: null,
            isFetchingDefaultPaymentMethod: false
        };
    },
    computed: {
        storeInfo: function () {
            return this.$store.getters["orders/getStore"]
        }
    },
    created() {
        this.getDefaultPaymentMethod();
    },
    mounted() {
        // this.fetchPaymentMethods();
        this.selectedDate = this.getCurrentMonth();
    },
    methods: {
        getDefaultPaymentMethod() {
            this.isFetchingDefaultPaymentMethod = true;
            this.$http
                .get(this.api_url + `Api/V1/Payment/Stripe/GetDefaultPaymentMethod/${this.storeInfo.stripeCustomerId}`)
                .then(
                    (res) => {
                        this.defaultPaymentMethod = res.body.last4Numbers
                        this.isFetchingDefaultPaymentMethod = false;
                    },
                    (err) => {
                        this.defaultPaymentMethod = null
                        this.isFetchingDefaultPaymentMethod = false
                        console.log(err);
                    }
                );
        },
        formatDateTime: (value, withTime) => timeFunctions.formatDateTime(value, withTime),
        fetchPaymentMethods() {
            this.$http
                .get(this.api_url + `Api/V1/Payment/Stripe/GetPaymentMethods/${this.storeInfo.stripeCustomerId}`)
                .then((response) => {
                    this.paymentMethods = response.body;

                    // Find the default payment method and set it as the default selected payment method
                    const defaultPaymentMethod = this.paymentMethods.find((method) => method.isDefaultPaymentMethod);
                    if (defaultPaymentMethod) {
                        this.selectedPaymentMethod = defaultPaymentMethod;
                    }
                })
                .catch((error) => {
                    console.error('Error fetching payment methods:', error);
                });
        },
        createScheduledPayment() {
            if (this.paymentAmount < 100 || this.scheduleDay > 31 || this.scheduleDay < 1) {
                this.scheduleInvalid = true;
                setTimeout(() => {
                    this.scheduleInvalid = false;
                }, 5000);
                return;
            }
            this.creatingSchedule = true;

            const payload = {
                amount: this.paymentAmount,
                dayOfMonth: this.scheduleDay,
                // paymentMethodId: this.selectedPaymentMethod.paymentMethodId,
                customerId: this.storeInfo.stripeCustomerId,
                storeId: this.store_id,
                storeName: this.storeInfo.name,
                type: "Credit",
                iterations: this.iterations
            };

            this.$http
                .post(this.api_url + 'Api/V1/Payment/Stripe/CreateScheduledPayments', payload)
                .then((response) => {
                    if (response.ok) {
                        this.creatingSchedule = false;
                        this.scheduleSuccessful = true;
                        this.paymentAmount = 100; // Reset payment amount
                        this.scheduleDay = 1;
                        this.iterations = 12;
                        setTimeout(() => {
                            this.scheduleSuccessful = false;
                        }, 5000);
                    }
                })
                .catch((error) => {
                    this.creatingSchedule = false;
                    console.log('Failed to create schedule payments:', error);
                });
        },
        capitalizeFirstLetter(value) {
            return value.charAt(0).toUpperCase() + value.slice(1);
        },
        cardNumber(last4) {
            return last4;
        },
        getCurrentMonth() {
            const now = new Date();
            const year = now.getFullYear();
            const month = (now.getMonth() + 1).toString().padStart(2, '0');
            return `${year}-${month}`;
        },
    },
};
</script>
  
<style scoped>
.columns {
    margin-top: 1.5rem;
}

.success-payment {
    margin-top: 1rem;
    background-color: #37c936;
    color: #fff;
    padding: 1rem;
    border-radius: 6px 6px 6px 6px;
}

.invalid-payment {
    margin-top: 1rem;
    background-color: #f14668;
    color: #fff;
    padding: 1rem;
    border-radius: 6px 6px 6px 6px;
}

.add-charge-container {
    border: 0.5px solid lightgray;
    border-radius: 6px 6px 6px 6px;
}

.transactions-container {
    margin-top: 1.5rem;
}

.date-picker-container {
    margin-bottom: 1.5rem;
    float: right;
}

input[type="month"] {
    padding: 0.3rem;
}

.help {
    margin-top: 5px;
    font-size: 0.8rem;
    color: #888;
}

.custom-input-width {
    width: 500px;
    /* Adjust the width value as per your requirements */
}

/* Loader styles */
.loader-container {
    display: flex;
}

.loader {
    margin: 0 auto;
    border: 4px solid #f3f3f3;
    border-top: 4px solid #3273DC;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
</style>
  