import Vue from 'vue';
import VueResource from 'vue-resource';
import store from './store/index';
import moment from 'moment';
import App from './App.vue';
import VueSimpleAlert from "vue-simple-alert";

require('./js/config.js');

Vue.config.productionTip = false

Vue.filter('formatDate', function (value) {
  if (value) {
    return moment(String(value)).format('MM/DD/YYYY h:mm A')
  }
});

Vue.filter('formatDateWithDay', function (value) {
  if (value) {
    return moment(String(value)).format('MM/DD/YYYY h:mm a, dddd')
  }
});

Vue.filter('formatShortDate', function (value) {
  if (value) {
    return moment(String(value)).format('M/D/YYYY')
  }
});

Vue.filter('formatAmount', function (value) {

  if (value && !isNaN(value)) {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2
    })
    return formatter.format(value)
  }
  
  else{
    return "$0.00";
  }
});

Vue.use(VueSimpleAlert);
Vue.use(VueResource);

Vue.http.interceptors.push((request, next) => {
  const token = localStorage.getItem('accessToken');
  if (token) {
    request.headers.set('Authorization', `Bearer ${token}`);
  }
  next();
});

new Vue({
  el: 'app',
  render: h => h(App),
  store,
});