<template>
    <div class="container">
        <h2 class="red-text" v-if="creatingPaymentMethodError">{{ creatingPaymentMethodError }}</h2>
        <h2 class="title is-4" v-if="paymentMethods.length != 0">Payment Options</h2>
        <div class="loader-container" v-if="loadingPaymentMethods">
            <div class="loader"></div>
        </div>
        <div class="accordion" v-else>
            <div v-for="method in paymentMethods" :key="method.paymentMethodId" class="accordion-item">
                <div class="accordion-header" @click="toggleAccordion(method.paymentMethodId)">

                    <p class="accordion-title">{{ capitalizeFirstLetter(method.cardBrand) }} {{
                        cardNumber(method.last4Numbers) }}
                        <span v-if="method.isDefaultPaymentMethod" class="default-payment-label">Default</span>
                    </p>
                    <button :class="{ 'is-active': isActive(method.paymentMethodId) }" class="accordion-trigger"
                        aria-controls="accordion-content"
                        :aria-expanded="isActive(method.paymentMethodId) ? 'true' : 'false'"></button>
                </div>
                <div :id="method.paymentMethodId" class="accordion-content"
                    :class="{ 'is-active': isActive(method.paymentMethodId) }" aria-hidden="true">
                    <div class="payment-details">
                        <div class="payment-details-left">
                            <p><strong>Number:</strong> {{ cardNumber(method.last4Numbers) }}</p>
                            <p><strong>Expires:</strong> {{ method.expires }}</p>
                            <p><strong>Type:</strong> {{ capitalizeFirstLetter(method.cardBrand) }}</p>
                        </div>
                        <div class="payment-details-right">
                            <span @click="setAsDefault(method.paymentMethodId)" v-if="!method.isDefaultPaymentMethod">
                                <u class="set-default-link">Set as Default</u>
                            </span>
                            <span v-if="!method.isDefaultPaymentMethod"
                                @click="removePaymentMethod(method.paymentMethodId)">
                                <i class="fa fa-trash fa-lg remove-icon"></i>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <button class="button is-primary add-payment-method-btn" @click="addPaymentMethod" v-if="!loadingPaymentMethods">Add
            Payment
            Method</button>

        <div class="modal" :class="{ 'is-active': showModal }" @click="closeModalOutside">
            <div class="modal-background"></div>
            <div class="modal-card">
                <header class="modal-card-head">
                    <p class="modal-card-title">Add Payment Method</p>
                    <button class="delete" aria-label="close" @click="closeModal"></button>
                </header>
                <section class="modal-card-body">
                    <div class="card-content">
                        <div class="field">
                            <label class="label" for="card-number-element">Card Number</label>
                            <div id="card-number-element" ref="cardNumberElement" class="control"></div>
                        </div>
                        <div class="columns">
                            <div class="column">
                                <div class="field">
                                    <label class="label" for="expiry-element">Expiration Date</label>
                                    <div id="expiry-element" ref="expiryElement" class="control"></div>
                                </div>
                            </div>
                            <div class="column">
                                <div class="field">
                                    <label class="label" for="cvc-element">Security Code</label>
                                    <div id="cvc-element" ref="cvcElement" class="control"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <footer class="modal-card-foot">
                    <button class="button is-primary" @click="savePaymentMethod" :disabled="savingPaymentMethod">
                        <span v-if="savingPaymentMethod">Saving...</span>
                        <span v-else>Save</span>
                    </button>
                    <button class="button" @click="closeModal">Cancel</button>
                </footer>
            </div>
        </div>
    </div>
</template>
  
<script>
const { loadStripe } = require('@stripe/stripe-js');

module.exports = {
    data: function () {
        return {
            api_url: this.$store.state.api_url,
            store_id: this.$store.state.user.store_id,
            stripe_publishable_key: this.$store.state.stripe_publishable_key,
            paymentMethods: [],
            activeAccordion: null,
            showModal: false,
            loadingPaymentMethods: false,
            savingPaymentMethod: false,
            stripe: null,
            cardNumberElement: null,
            expiryElement: null,
            cvcElement: null,
            activeAccordions: [],
            creatingPaymentMethodError: null
        };
    },
    computed: {
        storeInfo: function () {
            return this.$store.getters["orders/getStore"]
        }
    },
    mounted() {
        this.fetchPaymentMethods();
        this.initializeStripe();
    },
    methods: {
        async initializeStripe() {
            // Load Stripe.js
            this.stripe = await loadStripe(this.stripe_publishable_key);

            // Create instances of the card Elements
            const elements = this.stripe.elements();
            this.cardNumberElement = elements.create('cardNumber');
            this.cardNumberElement.mount(this.$refs.cardNumberElement);

            this.expiryElement = elements.create('cardExpiry');
            this.expiryElement.mount(this.$refs.expiryElement);

            this.cvcElement = elements.create('cardCvc');
            this.cvcElement.mount(this.$refs.cvcElement);
        },
        fetchPaymentMethods() {
            this.loadingPaymentMethods = true;
            this.$http
                .get(this.api_url + `Api/V1/Payment/Stripe/GetPaymentMethods/${this.storeInfo.stripeCustomerId}`)
                .then(
                    (res) => {
                        this.paymentMethods = res.data.sort((a, b) => {
                            if (a.isDefaultPaymentMethod) return -1;
                            if (b.isDefaultPaymentMethod) return 1;
                            return 0;
                        });
                        this.loadingPaymentMethods = false;
                        this.savingPaymentMethod = false;
                        this.closeModal();
                    },
                    (err) => {
                        console.log("Error fetching payment methods:", err);
                        this.loadingPaymentMethods = false;
                        this.savingPaymentMethod = false;
                        this.closeModal();
                    }
                );
        },
        removePaymentMethod(paymentMethodId) {
            if (confirm("Are you sure you want to delete this payment method?")) {
                this.$http
                    .post(this.api_url + `Api/V1/Payment/Stripe/DeletePaymentMethod/${paymentMethodId}`)
                    .then(
                        (res) => {
                            this.fetchPaymentMethods();
                        },
                        (err) => {
                            console.log(err);
                        }
                    );
            }
        },
        async savePaymentMethod() {
            this.savingPaymentMethod = true;
            // Collect payment method details
            const { error, paymentMethod } = await this.stripe.createPaymentMethod({
                type: 'card',
                card: this.cardNumberElement,
            });

            if (error) {
                this.savingPaymentMethod = false;
                this.closeModal();
                console.log(error);
            } else {
                const payload = {
                    paymentMethodId: paymentMethod.id,
                    storeId: this.store_id
                };

                this.$http
                    .post(this.api_url + "Api/V1/Payment/Stripe/CreatePaymentMethod/", payload)
                    .then(
                        (res) => {
                            this.creatingPaymentMethodError = null;
                            this.$store.dispatch("orders/getStore");
                            this.loadingPaymentMethods = true;
                            setTimeout(() => {
                                this.fetchPaymentMethods();
                            }, 5000)
                        },
                        (err) => {
                            this.creatingPaymentMethodError = err.bodyText
                            this.savingPaymentMethod = false;
                            this.closeModal();
                            console.log(err);
                        }
                    );
            }
        },
        setAsDefault(paymentMethodId) {
            this.loadingPaymentMethods = true;
            this.$http
                .post(this.api_url + `Api/V1/Payment/Stripe/SetDefaultPaymentMethod/${this.storeInfo.stripeCustomerId}/${paymentMethodId}`)
                .then((res) => {
                    this.fetchPaymentMethods();
                    this.$store.dispatch("orders/getStore");
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        toggleAccordion(id) {
            const index = this.activeAccordions.indexOf(id);
            if (index !== -1) {
                this.activeAccordions.splice(index, 1);
            } else {
                this.activeAccordions.push(id);
            }
        },
        isActive(id) {
            return this.activeAccordions.includes(id);
        },
        openModal() {
            this.showModal = true;
        },
        closeModal() {
            this.showModal = false;
        },
        closeModalOutside(event) {
            let clickedElement = event.target;
            while (clickedElement) {
                if (clickedElement.classList.contains("modal-background")) {
                    this.closeModal();
                    break;
                }
                clickedElement = clickedElement.parentElement;
            }
        },
        addPaymentMethod() {
            this.openModal();
        },
        capitalizeFirstLetter(value) {
            return value.charAt(0).toUpperCase() + value.slice(1);
        },
        cardNumber(last4) {
            return last4;
        },
    },
};
</script>
  
<style scoped>
.container {
    margin-top: 2rem;
}

.title {
    margin-bottom: 1rem;
}

.accordion-item {
    margin-bottom: 0.5rem;
}

.accordion-header {
    display: flex;
    align-items: center;
    cursor: pointer;
    border: 1px solid #ddd;
    border-radius: 4px;
    transition: background-color 0.2s ease;
    padding: 1em;
    height: 50px;
}

.accordion-header:hover {
    background-color: #f5f5f5;
    /* Hover background color */
}

.accordion-trigger {
    margin-left: auto;
    background-color: transparent;
    border: none;
    outline: none;
    padding: 0;
    width: 24px;
    height: 24px;
    position: relative;
}

.accordion-trigger::before,
.accordion-trigger::after {
    content: "";
    position: absolute;
    background-color: #333;
    transition: transform 0.2s ease;
}

.accordion-trigger::before {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 16px;
    height: 2px;
}

.accordion-trigger::after {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(90deg);
    width: 2px;
    height: 16px;
}

.accordion-trigger.is-active::before {
    transform: translate(-50%, -50%) rotate(45deg);
}

.accordion-trigger.is-active::after {
    transform: translate(-50%, -50%) rotate(135deg);
}

.accordion-content {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease;
}

.accordion-content.is-active {
    max-height: 250px;
}

.add-payment-method-btn {
    margin-top: 1.5rem;
}

.payment-details {
    /* margin-top: 0.5rem; */
    display: flex;
    align-items: center;
    padding: 1.5rem;
}

.payment-details-left {
    flex-grow: 1;
}

.payment-details-right {
    display: flex;
    align-items: center;
    margin-left: 1rem;
}

.remove-icon {
    cursor: pointer;
    color: red;
    margin-left: 2rem;
}

.columns {
    margin-top: 1.5rem;
}

.set-default-link {
    cursor: pointer;
    color: #3273DC;
}

.default-payment-label {
    margin-left: 1rem;
    color: white;
    font-size: smaller;
    background-color: #3273DC;
    padding: .2rem .5rem;
    border-radius: 4px;
}

/* Loader styles */
.loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
}

.loader {
    display: inline-block;
    border: 4px solid #f3f3f3;
    border-top: 4px solid #3273DC;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.red-text {
  color: red;
  font-weight: bold;
}
</style>
  