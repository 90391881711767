<template>
  <input
    class="input"
    :class="size"
    ref="autocomplete"
    v-bind:value="value"
    v-on:input="$emit('input', $event.target.value)"
    v-on:focus.capture="leftPositionCursor"
    type="text"
  />
</template>

<script>
module.exports = {
  data: function () {
    return {
      isMounted: false,
      size:""
    };
  },
  computed: {
    computedMapStatus: function () {
      return window.map_loaded.ready && this.isMounted;
    },
  },
  watch: {
    computedMapStatus: function () {
      this.initializeAutoComplete();
    },
  },
  props: ["value"],
  mounted() {
    this.isMounted = true;
  },
  methods: {
    initializeAutoComplete: function () {
      {
        const geolocation = {
          lat: "44.500000",
          lng: "‑89.500000",
        };

        const options = {
          componentRestrictions: { country: ["us", "ca"] },
          fields: ["address_component,formatted_address,name,types,geometry"],
          origin: geolocation,
          strictBounds: true,
        };

        const autocomplete = new window.google.maps.places.Autocomplete(
          /** @type {!HTMLInputElement} */ (this.$refs.autocomplete),
          options
        );

        autocomplete.addListener("place_changed", () => {
          const place = autocomplete.getPlace();
          const selectedAddress =
            place.types.indexOf("street_address") > -1
              ? place.formatted_address.replace(", USA", "")
              : place.name +
                ", " +
                place.formatted_address
                  .replace(place.name + ", ", "")
                  .replace(", USA", "");

          this.$emit("input", selectedAddress);
        });
      }
    },
    leftPositionCursor: function (e) {
      e.target.selectionStart = e.target.selectionEnd = 0;
    },
  },
};
</script>

<style scoped>
.pac-item-query {
  font-size: 16px;
}
.pac-item {
  font-size: 16px;
}

</style>