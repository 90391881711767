<template>
  <div class="card">
    <header class="card-header">
      <p class="card-header-title">Open Day</p>
      <a
        class="card-header-icon"
        aria-label="more options"
        v-on:click="toggleDialpad()"
      >
        <span class="icon">
          <i class="fa fa-close fa-2x"></i>
        </span>
      </a>
    </header>
    <div class="card-content">
      <section class="section">
        <div class="container csc">
          <div class="columns is-mobile phone-section">
            <div class="column center">
              <label class="label">Cash available</label>
              <div class="control">
                <input
                  ref="addCashTranscation"
                  id="ontime-dialpad-input"
                  min="0"
                  name="ontime-dialpad-input"
                  autocomplete="ontime-dialpad-input"
                  class="input is-large phone-input-style"
                  v-model="newTranscation.amount"
                  type="number"
                  v-on:keypress="checkSpecialKeys"
                  v-on:keyup.esc="toggleDialpad"
                />
              </div>
              <p
                class="help is-danger"
                v-bind:style="{ visibility: error ? 'visible' : 'hidden' }"
              ></p>
            </div>
          </div>
          <div class="columns is-mobile">
            <div class="column center">
              <span
                class="button dial-button is-info"
                v-on:click="addCash('1')"
              >
                <span class="dial-number">1</span>
                <span class="dial-alphabet">ABC</span>
              </span>
            </div>
            <div class="column center">
              <span
                class="button dial-button is-info"
                v-on:click="addCash('2')"
              >
                <span class="dial-number">2</span>
                <span class="dial-alphabet">ABC</span>
              </span>
            </div>
            <div class="column center">
              <span
                class="button dial-button is-info"
                v-on:click="addCash('3')"
              >
                <span class="dial-number">3</span>
                <span class="dial-alphabet">ABC</span>
              </span>
            </div>
            <div class="column center">
              <span class="button dial-button is-danger" v-on:click="clear()">
                <span class="dial-word">CLEAR</span>
              </span>
            </div>
          </div>
          <div class="columns is-mobile">
            <div class="column center">
              <span
                class="button dial-button is-info"
                v-on:click="addCash('4')"
              >
                <span class="dial-number">4</span>
                <span class="dial-alphabet">ABC</span>
              </span>
            </div>
            <div class="column center">
              <span
                class="button dial-button is-info"
                v-on:click="addCash('5')"
              >
                <span class="dial-number">5</span>
                <span class="dial-alphabet">ABC</span>
              </span>
            </div>
            <div class="column center">
              <span
                class="button dial-button is-info"
                v-on:click="addCash('6')"
              >
                <span class="dial-number">6</span>
                <span class="dial-alphabet">ABC</span>
              </span>
            </div>
            <div class="column center">
              <span
                class="button dial-button is-danger"
                v-on:click="backspace()"
              >
                <span class="dial-icon">
                  <i class="fa fa-arrow-left"></i>
                </span>
              </span>
            </div>
          </div>
          <div class="columns is-mobile">
            <div class="column center">
              <span
                class="button dial-button is-info"
                v-on:click="addCash('7')"
              >
                <span class="dial-number">7</span>
                <span class="dial-alphabet">ABC</span>
              </span>
            </div>
            <div class="column center">
              <span
                class="button dial-button is-info"
                v-on:click="addCash('8')"
              >
                <span class="dial-number">8</span>
                <span class="dial-alphabet">ABC</span>
              </span>
            </div>
            <div class="column center">
              <span
                class="button dial-button is-info"
                v-on:click="addCash('9')"
              >
                <span class="dial-number">9</span>
                <span class="dial-alphabet">ABC</span>
              </span>
            </div>
            <div class="column center">
              <span
                class="button dial-button is-danger"
                v-on:click="toggleDialpad()"
              >
                <span class="dial-icon">
                  <i class="fa fa-close"></i>
                </span>
              </span>
            </div>
          </div>
          <div class="columns is-mobile">
            <div class="column center">
              <span
                class="button dial-button is-info"
                v-on:click="addCash('0')"
              >
                <span class="dial-number">0</span>
                <span class="dial-alphabet">+</span>
              </span>
            </div>
            <div class="column center">
              <span
                class="button dial-button is-primary"
                v-on:click="openStore()"
              >
                <span class="dial-icon">
                  <i class="fa fa-check"></i>
                </span>
              </span>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
module.exports = {
  components: {},
  created: function () {
    if (this.currentPanel === "dialpad") {
      let self = this;
      setTimeout(() => {
        self.$refs.addCashTranscation.focus();
      });
    }
  },
  props: {},
  data: function () {
    return {
      validationError: false,
    };
  },
  computed: {
    newTranscation() {
      return this.$store.state.orders.newTranscation;
    },
    error() {
      return this.validationError;
    },
    currentPanel() {
      return this.$store.state.app.currentPanel;
    },
  },
  methods: {
    setPanel: function (panel) {
      this.$store.dispatch("app/setPanel", panel);
    },
    toggleDialpad: function () {
      this.setPanel("order");
      this.validationError = false;
    },
    openStore: function () {
      if (this.newTranscation.amount !== undefined || this.newTranscation.amount !== null && this.newTranscation.amount !== '') {
        var par = {
          transactionType: "OpenDay",
          amount: this.newTranscation.amount,
        };
        this.validationError = false;
        this.$store.dispatch("orders/openStore", par);
        this.$store.dispatch("orders/getStore");
        this.setPanel("dayPanel");
      } else {
        this.validationError = true;
      }
    },
    addCash: function (number) {
      this.$refs.addCashTranscation.focus();
      if (this.newTranscation.amount != null && this.newTranscation.amount != "0") {
        this.newTranscation.amount = this.newTranscation.amount + number;
      }
      else {
        this.newTranscation.amount = number;
      }
    },
    clear: function () {
      this.$refs.addCashTranscation.focus();
      this.newTranscation.amount = "";
    },
    backspace: function () {
      this.$refs.addCashTranscation.focus();
      if (this.newTranscation.amount && this.newTranscation.amount.length > 0) {
        this.newTranscation.amount = this.newTranscation.amount.substring(
          0,
          this.newTranscation.amount.length - 1
        );
      }
    },
    checkSpecialKeys: function (e) {
      if (e.key == "Enter") {
        this.openStore();
        return false;
      }
      return true;
    },
  },
};
</script>
<style scoped>
.phone-section {
  width: 470px;
}
.phone-input-style {
  font-weight: 900;
  text-align: center;
}
.csc {
  width: 450px;
  margin: 0 auto auto auto;
}
.center {
  text-align: center;
  margin: 0 auto auto 0;
}
.dial-word {
  top: 15px;
  position: relative;
  font-size: 1.25rem;
  font-weight: 700;
  width: 60px;
  height: 60px;
}
.dial-number {
  top: 3px;
  position: relative;
  font-size: 1.5rem;
  font-weight: 700;
  width: 60px;
  height: 60px;
}
.dial-special {
  position: relative;
  top: 5px;
  font-size: 2rem;
  width: 60px;
  height: 60px;
}
.dial-alphabet {
  font-size: 0.8rem;
  position: absolute;
  top: 40px;
}
.dial-icon {
  position: relative;
  top: 5px;
  font-size: 2rem;
  width: 60px;
  height: 60px;
}
.dial-button {
  border-radius: 10px;
  width: 100%;
  height: auto;
}
</style>