<!-- eslint-disable vue/no-use-v-if-with-v-for,vue/no-confusing-v-for-v-if -->
<template>
  <div class="card">
    <header class="card-header">
      <p class="card-header-title">New Order</p>
      <a
        class="card-header-icon"
        aria-label="more options"
        v-on:click="toggleDialpad()"
      >
        <span class="icon">
          <i class="fa fa-close fa-2x"></i>
        </span>
      </a>
    </header>

    <div class="card-content">
      <section class="section">
        <div class="container csc">
          <div class="columns is-mobile">
            <div class="column center is-9">
              <label class="label">Phone Number</label>
              <input
                  ref="newOrderPhone"
                  id="ontime-dialpad-input"
                  name="ontime-dialpad-input"
                  autocomplete="ontime-dialpad-input"
                  maxlength="10"
                  class="input is-large phone-input-style"
                  v-model="newOrder.phone"
                  type="text"
                  v-on:keypress="checkSpecialKeys"
                  v-on:keyup.esc="toggleDialpad"
                  v-on:keyup.delete="backspace"
                  readonly
                  inputmode="none"
                />
                <p
                  class="help is-danger"
                  v-bind:style="{ visibility: error ? 'visible' : 'hidden' }"
                >
                  Must be 10 digits
                </p>
            </div>
            <div class="column center">
              <label class="label has-text-white">-</label>
              <input type="file" ref="fileInput" @change="importOrders" accept=".xlsx, .xls, .csv" style="display: none;" />
              <a class="button is-large" @click="triggerFileInput">
                <i class="fa fa-arrow-down"></i>
              </a>
            </div>
          </div>
          <div class="columns is-mobile">
            <div class="column center">
              <span
                class="button dial-button is-info"
                v-on:click="addNumber('1')"
              >
                <span class="dial-number">1</span>
                <span class="dial-alphabet">ABC</span>
              </span>
            </div>
            <div class="column center">
              <span
                class="button dial-button is-info"
                v-on:click="addNumber('2')"
              >
                <span class="dial-number">2</span>
                <span class="dial-alphabet">ABC</span>
              </span>
            </div>
            <div class="column center">
              <span
                class="button dial-button is-info"
                v-on:click="addNumber('3')"
              >
                <span class="dial-number">3</span>
                <span class="dial-alphabet">ABC</span>
              </span>
            </div>
            <div class="column center">
              <span class="button dial-button is-danger" v-on:click="clear()">
                <span class="dial-word">CLEAR</span>
              </span>
            </div>
          </div>
          <div class="columns is-mobile">
            <div class="column center">
              <span
                class="button dial-button is-info"
                v-on:click="addNumber('4')"
              >
                <span class="dial-number">4</span>
                <span class="dial-alphabet">ABC</span>
              </span>
            </div>
            <div class="column center">
              <span
                class="button dial-button is-info"
                v-on:click="addNumber('5')"
              >
                <span class="dial-number">5</span>
                <span class="dial-alphabet">ABC</span>
              </span>
            </div>
            <div class="column center">
              <span
                class="button dial-button is-info"
                v-on:click="addNumber('6')"
              >
                <span class="dial-number">6</span>
                <span class="dial-alphabet">ABC</span>
              </span>
            </div>
            <div class="column center">
              <span
                class="button dial-button is-danger"
                v-on:click="backspace()"
              >
                <span class="dial-icon">
                  <i class="fa fa-arrow-left"></i>
                </span>
              </span>
            </div>
          </div>
          <div class="columns is-mobile">
            <div class="column center">
              <span
                class="button dial-button is-info"
                v-on:click="addNumber('7')"
              >
                <span class="dial-number">7</span>
                <span class="dial-alphabet">ABC</span>
              </span>
            </div>
            <div class="column center">
              <span
                class="button dial-button is-info"
                v-on:click="addNumber('8')"
              >
                <span class="dial-number">8</span>
                <span class="dial-alphabet">ABC</span>
              </span>
            </div>
            <div class="column center">
              <span
                class="button dial-button is-info"
                v-on:click="addNumber('9')"
              >
                <span class="dial-number">9</span>
                <span class="dial-alphabet">ABC</span>
              </span>
            </div>
            <div class="column center">
              <span
                class="button dial-button is-danger"
                v-on:click="toggleDialpad()"
              >
                <span class="dial-icon">
                  <i class="fa fa-close"></i>
                </span>
              </span>
            </div>
          </div>
          <div class="columns is-mobile">
            <div class="column center">
              <span
                class="button dial-button is-info"
                v-on:click="addNumber('0')"
              >
                <span class="dial-number">0</span>
                <span class="dial-alphabet">+</span>
              </span>
            </div>
            <div class="column center">
              <span
                class="button dial-button is-primary"
                v-on:click="startNewOrder()"
              >
                <span class="dial-icon">
                  <i class="fa fa-check"></i>
                </span>
              </span>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
module.exports = {
  components: {},
  created: function () {
    if (this.currentPanel === "dialpad") {
      let self = this;
      setTimeout(() => {
        self.$refs.newOrderPhone.focus();
      });
    }
  },
  props: {},
  data: function () {
    return {
      validationError: false,
      file: null,
      storeId: this.$store.state.user.store_id,
    };
  },
  computed: {
    newOrder() {
      return this.$store.state.orders.newOrder;
    },
    error() {
      return this.validationError;
    },
    currentPanel() {
      return this.$store.state.app.currentPanel;
    },
  },
  methods: {
    triggerFileInput() {
      this.$refs.fileInput.click();
    },
    importOrders: function(event) {
      const file = event.target.files[0];
      if (!file) {
        alert('No file selected.');
        return;
      }

      const formData = new FormData();
      formData.append('file', file);

      this.$http
        .post(this.$store.state.order_url + "/ImportOrdersFromExcel/" + this.storeId, formData)
        .then(
          (res) => {
            console.log("Orders imported succesfully...")
            this.setPanel("order");
          },
          (err) => {
            console.log("Failed to import orders", err);
          }
        );
    },
    setPanel: function (panel) {
      this.$store.dispatch("app/setPanel", panel);
    },
    toggleDialpad: function () {
      this.validationError = false;
      this.setPanel("order");
      this.$store.dispatch("orders/clearNewOrder");
    },
    startNewOrder: function () {
      if (this.newOrder.phone.length != 10) {
        this.validationError = true;
      } else {
        this.validationError = false;
        this.$store.dispatch("orders/newOrderPhone", this.newOrder.phone);
        this.$store.dispatch("orders/getCustomerInfo");
        this.setPanel("newOrder");
      }
    },
    addNumber: function (number) {
      this.$refs.newOrderPhone.focus();
      if (this.newOrder.phone.length < 10) {
        this.newOrder.phone = this.newOrder.phone + number;
      }
    },
    clear: function () {
      this.$refs.newOrderPhone.focus();
      this.newOrder.phone = "";
    },
    backspace: function () {
      this.$refs.newOrderPhone.focus();
      if (this.newOrder.phone && this.newOrder.phone.length > 0) {
        this.newOrder.phone = this.newOrder.phone.substring(
          0,
          this.newOrder.phone.length - 1
        );
      }
    },
    checkSpecialKeys: function (e) {
      if (e.key == "Enter") {
        this.startNewOrder();
        return false;
      }

      if (parseInt(e.key) || e.key === '0') {
        this.addNumber(e.key);
      }
      return true;
    },
  },
};
</script>

<style scoped>
.phone-section {
  width: 470px;
}
.phone-input-style {
  font-weight: 900;
  text-align: center;
}
.csc {
  width: 450px;
  margin: 0 auto auto auto;
}
.center {
  text-align: center;
  margin: 0 auto auto 0;
}
.dial-word {
  top: 15px;
  position: relative;
  font-size: 1.25rem;
  font-weight: 700;
  width: 60px;
  height: 60px;
}
.dial-number {
  top: 3px;
  position: relative;
  font-size: 1.5rem;
  font-weight: 700;
  width: 60px;
  height: 60px;
}
.dial-special {
  position: relative;
  top: 5px;
  font-size: 2rem;
  width: 60px;
  height: 60px;
}
.dial-alphabet {
  font-size: 0.8rem;
  position: absolute;
  top: 40px;
}
.dial-icon {
  position: relative;
  top: 5px;
  font-size: 2rem;
  width: 60px;
  height: 60px;
}
.dial-button {
  border-radius: 10px;
  width: 100%;
  height: auto;
}
/* End Dialpad */
</style>