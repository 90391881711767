<template>
    <div>
        <div class="container">
            <p class="getting-started-text">Getting Started</p>
            <p class="welcome-text">👏Welcome to Ontime!</p>

            <div class = "columns is-centered">
                <div class="column is-half">
                    <div class="columns">
                        <div class="column is-3">
                            <div class="card">
                                <div class="icon-wrapper">
                                    <i class="fa fa-cube"></i>
                                </div>
                                <h3>Connect to square</h3>
                            </div>
                        </div>
                        <div class="column is-3">
                            <div class="card">
                                <div class="icon-wrapper">
                                    <i class="fa fa-heart"></i>
                                </div>
                                <h3>Set your preferences</h3>
                            </div>
                        </div>
                        <div class="column is-3">
                            <div class="card">
                                <div class="icon-wrapper">
                                    <i class="fa fa-id-card"></i>
                                </div>
                                <h3>Add new drivers</h3>
                            </div>
                        </div>
                        <div class="column is-3">
                            <div class="card">
                                <div class="icon-wrapper">
                                    <i class="fa fa-calendar"></i>
                                </div>
                                <h3>Opening Day Account</h3>
                            </div>
                        </div>
                    </div>
                    <div class="columns is-vcentered is-centered">
                        <div class="column is-3">
                            <div class="card">
                                <div class="icon-wrapper">
                                    <i class="fa fa-clock-o"></i>
                                </div>
                                <h3>Driver Clock In / Clock Out</h3>
                            </div>
                        </div>
                        <div class="column is-3">
                            <div class="card">
                                <div class="icon-wrapper">
                                    <i class="fa fa-list"></i>
                                </div>
                                <h3>Add, Edit, Delete Orders</h3>
                            </div>
                        </div>
                        <div class="column is-3">
                            <div class="card">
                                <div class="icon-wrapper">
                                    <i class="fa fa-edit"></i>
                                </div>
                                <h3>Order Status Updates</h3>
                            </div>
                        </div>
                        <div class="column is-3">
                            <div class="card">
                                <div class="icon-wrapper">
                                    <i class="fa fa-check"></i>
                                </div>
                                <h3>Marking Orders Ready</h3>
                            </div>
                        </div>
                    </div>
                    <div class="columns is-vcentered is-centered">
                        <div class="column is-3">
                            <div class="card">
                                <div class="icon-wrapper">
                                    <i class="fa fa-truck"></i>
                                </div>
                                <h3>Dispatch View</h3>
                            </div>
                        </div>
                        <div class="column is-3">
                            <div class="card">
                                <div class="icon-wrapper">
                                    <i class="fa fa-map"></i>
                                </div>
                                <h3>Map View</h3>
                            </div>
                        </div>
                        <div class="column is-3">
                            <div class="card">
                                <div class="icon-wrapper">
                                    <i class="fa fa-plus"></i>
                                </div>
                                <h3>Assign/Unassign Orders</h3>
                            </div>
                        </div>
                        <div class="column is-3">
                            <div class="card">
                                <div class="icon-wrapper">
                                    <i class="fa fa-list-alt"></i>
                                </div>
                                <h3>Opening Day Account</h3>
                            </div>
                        </div>
                    </div>
                    <div class="columns is-vcentered is-centered">
                        <div class="column is-3">
                            <div class="card">
                                <div class="icon-wrapper">
                                    <i class="fa fa-car"></i>
                                </div>
                                <h3>Request 3rd Party Drivers</h3>
                            </div>
                        </div>
                        <div class="column is-3">
                            <div class="card">
                                <div class="icon-wrapper">
                                    <i class="fa fa-money"></i>
                                </div>
                                <h3>Adjusting Tips</h3>
                            </div>
                        </div>
                        <div class="column is-3">
                            <div class="card">
                                <div class="icon-wrapper">
                                    <i class="fa fa-list-alt"></i>
                                </div>
                                <h3>Closing Day Account</h3>
                            </div>
                        </div>
                        <div class="column is-3">
                            <div class="card">
                                <div class="icon-wrapper">
                                    <i class="fa fa-file"></i>
                                </div>
                                <h3>Payroll Report</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <br/><br/>
    </div>
</template>
    
<script>
require("../js/config.js");
var modal = require("./modal.vue").default;
module.exports = {
    components: {
        modal
    },
    data: function () {
        return {
        };
    },
    computed: {
    },
    methods: {
    },
};
</script>
    
<style scoped>
.container {
    justify-content: center;
    padding-top: 1rem;
}

.getting-started-text {
    font-size: 35px;
    font-weight: bold;
    text-align: center;
}

.welcome-text {
    text-align: center;
    font-style: oblique;
    font-size: 18px;
    margin: 1rem 1rem 2rem 1rem;
}

.card {
    height: 10em;
    padding: 1em 1.5em;
    background: linear-gradient(#ffffff 50%, #37c936 50%);
    background-size: 100% 200%;
    background-position: 0 2.5%;
    border-radius: 5px;
    box-shadow: 0 0 35px rgba(0, 0, 0, 0.12);
    cursor: pointer;
    transition: 0.5s;
    justify-content: center;
}

.icon-wrapper {
    background-color: #37c936;
    position: relative;
    margin: auto;
    font-size: 30px;
    height: 2em;
    width: 2em;
    color: #ffffff;
    border-radius: 50%;
    display: grid;
    place-items: center;
    transition: 0.5s;
}

.card:hover {
    background-position: 0 100%;
}

.card:hover .icon-wrapper {
    background-color: #ffffff;
    color: #37c936;
}

.card h3 {
    text-align: center;
    margin-top: 1rem;
    font-size: 15px;
    font-weight: bold;
}

.card:hover h3 {
    color: #ffffff;
    text-align: center;
}

.card:hover p {
    color: #f0f0f0;
}
</style>
    