module.exports = {
    getShortAddress: function (address) {
        return address.substr(0, 19).split(',')[0];
    },
    getShortName: function (name, limitFirstName) {
        let splitName = name.split(" ");
        if (splitName.length > 1) {
            if (splitName[0].length > 10) {
                name = splitName[0].substring(0, 5);
            } else if (limitFirstName) {
                name = splitName[0].substring(0, limitFirstName);
            } else {
                name = splitName[0];
            }
            name += " " + splitName[1].substring(0, 1);
        } else {
            if (name.length > 10) {
                name = name.substring(0, 5);
            } else if (limitFirstName) {
                name = splitName[0].substring(0, limitFirstName);
            } else {
                name = splitName[0];
            }
        }
        return name;
    },
    getDriverShortName: function (firstname, lastname, limitFirstName = 5) {
        const shortFirstName = firstname?.substring(0, limitFirstName) ?? "";
        const shortLastName = lastname ? ` ${lastname[0]}` : '';
        return `${shortFirstName}${shortLastName}`;
    },
    getShortOrderNumber: function(orderNumber){
        let order = orderNumber + ""
        return order.slice(order.length - 5, order.length).toUpperCase()
    },
    getNameInitials: function (name) {
        let rgx = new RegExp(/(\p{L}{1})\p{L}+/, 'gu');

        let initials = [...name.matchAll(rgx)] || [];

        initials = (
            (initials.shift()?.[1] || '') + ' ' + (initials.pop()?.[1] || '')
        ).toUpperCase();

        return initials;
    },
    getOrderSourceLogo: function (source) {

        if (!source || source.toLowerCase().trim().includes("ontime")) {
            return "/img/orders/Ontimeminilogo.png";
        } else if (source.toLowerCase().trim().includes("uber")) {
            return "/img/orders/Uberats.png";
        } else if (source.toLowerCase().trim().includes("doordash")) {
            return "/img/orders/doordash-logo2.png";
        } else if (source.toLowerCase().trim().includes("grubhub")) {
            return "/img/orders/grubhub2png.png";
        } else if (source.toLowerCase().trim().includes("square")) {
            return "/img/orders/square2.png";
        } else if (source.toLowerCase().trim().includes("flipdish")) {
            return "/img/orders/flip2.png";
        } return "/img/orders/Ontimeminilogo.png";
    }
}