// getHourTime
module.exports = {
  moneyFormat: function (number) {
    return '$' + number.toFixed(2)
  },
  getHourTime: function (h) {
    return h >= 12 ? 'PM' : 'AM'
  },
  getZeroPad: function (n) {
    return (parseInt(n, 10) >= 10 ? '' : '0') + n
  },
  readCookie: function (name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  },
  deleteCookie: function (name, path, domain) {
    if (this.readCookie(name)) {
      document.cookie = name + "=" +
        ((path) ? ";path=" + path : "") +
        ((domain) ? ";domain=" + domain : "") +
        ";expires=Thu, 01 Jan 1970 00:00:01 GMT";
    }
  },
  createCookie: function (name, cookie, path, domain, expires) {
    document.cookie = name + "=" + cookie +
      ((path) ? ";path=" + path : "") +
      ((domain) ? ";domain=" + domain : "") +
      ((expires) ? ";expires=" + expires : "") +
      ";";
  },
  sort_by: function (field, reverse, primer) {

    var key = primer ?
      function (x) { return primer(x[field]) } :
      function (x) { return x[field] };

    reverse = !reverse ? 1 : -1;

    return function (a, b) {
      return a = key(a), b = key(b), reverse * ((a > b) - (b > a));
    }
  },

  checkIfTablet: function(){
    return window.innerWidth < 1300;
  }

}