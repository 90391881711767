<template>
  <div>
    <article class="panel">
      <div class="panel-heading has-text-weight-medium">
        <!-- DRIVER HEADER -->
        <div class="columns is-vcentered driver-heading">
          <div class="column is-uppercase">
            <div
              v-if="!['Uber', 'DoorDash'].includes(virtualDriver)"
              class="column is-uppercase driver-name"
            >
            {{ getShortName(virtualDriver, 5).toUpperCase() }}
            </div>
            <img
              v-else
              :src="virtualDriverLogo"
              :alt="virtualDriver + ' Logo'"
            />
          </div>

          <div class="center-heading columns column has-text-centered">
            <div class="column has-text-centered driver-header-info-text">
              <div>ORDERS</div>
              <div>{{ this.orderCount }}</div>
            </div>
          </div>

          <div
            class="column button-group"
            v-if="
              (expandLevel == 3 || expandLevel == 2) &&
              ['Uber', 'DoorDash'].includes(virtualDriver)
            "
          ></div>

          <div
            class="column button-group"
            v-if="
              (expandLevel == 3 || expandLevel == 2) &&
              !['Uber', 'DoorDash'].includes(virtualDriver)
            "
          >
            <img
              class="is-small is-pulled-right is-header-button driver-panel-icon"
              :src="virtualDriverLogo"
              :alt="virtualDriver + ' Logo'"
            />
          </div>
        </div>
      </div>

      <!-- PENDING/ASSIGNED ORDERS -->
      <div
        v-if="
          driverOrdersStatus === 'pending' || driverOrdersStatus === 'assigned'
        "
      >
        <!-- ORDERS HEADER -->
        <div
          class="columns order-heading table-rows is-size-7 has-text-centered"
        >
          <div
            class="column has-text-left"
            :class="
              expandLevel == 3 ? 'is-2' : expandLevel == 1 ? 'is-6' : 'is-3'
            "
          >
            NAME
          </div>
          <div class="column is-1 has-text-centered no-padding">SRC</div>
          <div
            class="column has-text-left"
            :class="expandLevel == 3 ? 'is-5' : 'is-4'"
          >
            ADDRESS
          </div>
          <div class="column has-text-centered no-padding">TOR</div>
          <div class="column has-text-centered no-padding">EDT</div>
        </div>

        <!-- ORDERS DETAILS -->
        <div
          class="columns orders assigned-orders table-rows is-size-6 has-text-centered"
          v-for="outsourcedOrder in outsourcedOrders"
          v-bind:key="outsourcedOrder.order_id"
          v-on:click.stop="$emit('toggleOrderSelection', outsourcedOrder)"
        >
          <div
            class="column is-uppercase is-size-7 has-text-left text-ellipsis assigned-order-info-text text-bold"
            :class="{
              'has-background-link':
                selectedOrders.filter((s) => s === outsourcedOrder.order_id)
                  .length === 1,
              'is-2': expandLevel === 3,
              'is-6': expandLevel === 1,
              'is-3': expandLevel !== 1 && expandLevel !== 3,
            }"
          >
            {{ getShortName(outsourcedOrder.customer_name, 5).toUpperCase() }}
          </div>
          <div class="column is-1 has-text-centered no-padding">
            <img
              class="img source-logo"
              :src="getOrderSourceLogo(outsourcedOrder.OrderSource)"
            />
          </div>
          <div
            v-if="expandLevel == 3"
            class="column is-uppercase has-text-left text-ellipsis assigned-order-info-text"
            :class="expandLevel == 3 ? 'is-5' : 'is-4'"
          >
            {{ shortAddress(outsourcedOrder.address) }}
          </div>

          <div class="column assigned-order-info-text has-text-centered">
            {{
              outsourcedOrder.ExternalEstimatedTimeToDeliver
                ? -stringTimeDiff(
                    outsourcedOrder.pickup_eta,
                    outsourcedOrder.ExternalEstimatedTimeToDeliver
                  )
                : "--"
            }}
          </div>

          <div class="column assigned-order-info-text has-text-centered">
            {{
              outsourcedOrder.ExternalEstimatedTimeToDeliver
                ? -stringTimeDiff(
                    outsourcedOrder.entry_time,
                    outsourcedOrder.ExternalEstimatedTimeToDeliver
                  )
                : "--"
            }}
          </div>
        </div>
      </div>

      <!-- ON THE WAY ORDERS -->
      <div v-if="driverOrdersStatus === 'on the way'">
        <!-- ORDERS HEADER -->
        <div
          class="columns order-heading table-rows is-size-7 has-text-centered"
        >
          <div
            class="column has-text-left"
            :class="
              expandLevel == 3 ? 'is-2' : expandLevel == 1 ? 'is-6' : 'is-3'
            "
          >
            NAME
          </div>
          <div class="column is-1 has-text-centered no-padding">SRC</div>
          <div
            class="column has-text-left"
            :class="expandLevel == 3 ? 'is-5' : 'is-4'"
          >
            ADDRESS
          </div>
          <div class="column has-text-centered no-padding">TUD</div>
          <div class="column has-text-centered no-padding">EDT</div>
          <div class="column has-text-centered no-padding">ADT</div>
        </div>

        <!-- ORDERS DETAILS -->
        <div
          class="columns orders assigned-orders table-rows is-size-6 has-text-centered"
          v-for="outsourcedOrder in outsourcedOrders"
          v-bind:key="outsourcedOrder.order_id"
          v-on:click.stop="$emit('toggleOrderSelection', outsourcedOrder)"
        >
          <div
            class="column is-uppercase is-size-7 has-text-left text-ellipsis assigned-order-info-text text-bold"
            :class="{
              'has-background-link':
                selectedOrders.filter((s) => s === outsourcedOrder.order_id)
                  .length === 1,
              'is-2': expandLevel === 3,
              'is-6': expandLevel === 1,
              'is-3': expandLevel !== 1 && expandLevel !== 3,
            }"
          >
            {{ getShortName(outsourcedOrder.customer_name, 5).toUpperCase() }}
          </div>
          <div class="column is-1 has-text-centered no-padding">
            <img
              class="img source-logo"
              :src="getOrderSourceLogo(outsourcedOrder.OrderSource)"
            />
          </div>
          <div
            v-if="expandLevel == 3"
            class="column is-uppercase has-text-left text-ellipsis assigned-order-info-text"
            :class="expandLevel == 3 ? 'is-5' : 'is-4'"
          >
            {{ shortAddress(outsourcedOrder.address) }}
          </div>

          <div class="column assigned-order-info-text has-text-centered">
            {{
              outsourcedOrder.ExternalEstimatedTimeToDeliver
                ? -elapsedTime(outsourcedOrder.ExternalEstimatedTimeToDeliver)
                : "--"
            }}
          </div>

          <div class="column assigned-order-info-text has-text-centered">
            {{
              outsourcedOrder.ExternalEstimatedTimeToDeliver
                ? -stringTimeDiff(
                    outsourcedOrder.entry_time,
                    outsourcedOrder.ExternalEstimatedTimeToDeliver
                  )
                : "--"
            }}
          </div>
          <div class="column assigned-order-info-text has-text-centered">
            {{
              outsourcedOrder.delivery_time
                ? -stringTimeDiff(
                    outsourcedOrder.entry_time,
                    outsourcedOrder.delivery_time
                  )
                : "--"
            }}
          </div>
        </div>
      </div>
    </article>
  </div>
</template>

<script>
var timeFunctions = require("../../js/timeFunctions.js");
var orderFunctions = require("../../js/orderFunctions.js");

module.exports = {
  components: {},
  data: function () {
    return {};
  },
  created: function () {},
  props: ["outsourcedOrders", "virtualDriver"],
  computed: {
    driverOrdersStatus() {
      const status = this.outsourcedOrders[0].external_delivery_status;

      if ([null, "", "pending"].includes(status)) {
        return "pending";
      }

      if (status === "pickup") {
        return "assigned";
      }

      if (status === "on the way") {
        return "on the way";
      }
    },
    virtualDriverLogo() {
      if (this.virtualDriver === "Uber") {
        return "/img/virtual_drivers/ubereats_logo.png";
      } else if (this.virtualDriver === "DoorDash") {
        return "/img/virtual_drivers/doordash_logo.png";
      } else if (
        this.outsourcedOrders[0].external_delivery_source === "DoorDash"
      ) {
        return "/img/orders/doordash-logo2.png";
      } else if (this.outsourcedOrders[0].external_delivery_source === "Uber") {
        return "/img/orders/Uberats.png";
      } else {
        return null;
      }
    },
    expandLevel: function () {
      return this.$store.getters["app/getExpandLevel"];
    },
    selectedOrders: function () {
      return this.$store.getters["orders/selectedOrders"];
    },
    orderCount: function () {
      return this.outsourcedOrders.length;
    },
  },
  watch: {},
  methods: {
    elapsedTime: (a) => timeFunctions.elapsedTime(a),
    currentTime: () => timeFunctions.currentTime(),
    convertToTime: (a) => timeFunctions.convertToTime(a),
    stringTimeDiff: (a, b) => timeFunctions.stringTimeDiff(a, b),
    shortAddress: (address) => orderFunctions.getShortAddress(address),
    getShortName: (name, limitFirstName) =>
      orderFunctions.getShortName(name, limitFirstName),
    getOrderSourceLogo: (source) => orderFunctions.getOrderSourceLogo(source),
    clearSelections: function () {
      this.$store.dispatch("orders/clearSelectedOrders");
    },
  },
  filters: {},
};
</script>

<style scoped>
.panel-heading {
  margin-bottom: 12px;
  padding: 0 !important;
  padding-bottom: 0 !important;
}

.columns {
  margin-left: 0px;
  margin-right: 0px;
}

.driver-heading {
  min-height: 40px;
  margin-bottom: 0 !important;
  font-size: 1rem !important;
  padding: 0.5em;
  margin: 0 !important;
}

.driver-heading .column {
  padding: 0px;
  margin-bottom: 0px !important;
}

.order-heading .column {
  padding: 0px;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
  font-weight: bold;
  margin-top: 0.05em !important;
  margin-bottom: 0.05em !important;
}

.orders .column {
  padding: 0px;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
  padding-top: 0.4em !important;
}

.is-header-button {
  margin: 5px;
  background-color: transparent !important;
  background: transparent !important;
}

.driver-panel-icon {
  background-color: transparent !important;
  padding: 6px 6px !important;
  width: 34px;
  height: 34px;
}

.button-group {
  margin: 0 !important;
}

.will-be-late {
  color: #f14668;
}

.delivered-item {
  color: #05a13c;
  font-weight: bold;
}

.returned-item {
  color: #f14668;
  font-weight: bold;
}

.text-ellipsis {
  width: 100px;
  overflow: hidden;
  display: inline-block;
  text-overflow: clip;
  white-space: nowrap;
}

.panel-heading {
  background-color: white;
  border: 2px solid #3273dc;
  border-radius: 6px 6px 6px 6px;
}

.button.is-small {
  border: none;
  background-color: transparent;
}

.driver-header-info-text {
  font-size: 0.8em;
  max-width: 75px;
  padding-bottom: 0.5em !important;
  padding-left: 0.5em !important;
  padding-right: 0.5em !important;
}

.order-info-text {
  font-size: 0.8em;
}

.assigned-order-info-text {
  font-size: 0.8em;
}

.driver-collapse-arrow {
  height: 1.5em;
  padding: 0 !important;
  margin: 0 !important;
  color: #3273dc !important;
}

.button.is-info.is-hovered,
.button.is-info:hover {
  background-color: transparent !important;
}

.no-padding {
  padding: 0 !important;
}

.is-selected-background {
  background: #77a5ee;
}

.assign-route-header {
  background: lightyellow;
}

.next-route {
  margin-top: 3em;
}

.on-the-road {
  background: rgb(157, 232, 157);
}

.is-light {
  background-color: #fff !important;
  padding: 6px 6px !important;
  width: 34px;
  height: 34px;
}

.center-heading {
  justify-content: center;
  margin: 0.1em;
  font-size: 1.1rem !important;
}

.img {
  margin-top: -10px !important;
}

.invert-border {
  border-color: white !important;
}
</style>
