<template>
  <div class="section">
  </div>
</template>
<script>

module.exports = {
  beforeMount(){
  this.verifyEmail()
  },
  methods: {
    verifyEmail: function () {
          this.$store.dispatch("user/verifyEmail");
    },
  },
};
</script>

