import { render, staticRenderFns } from "./cards.vue?vue&type=template&id=34c66d14&scoped=true"
import script from "./cards.vue?vue&type=script&lang=js"
export * from "./cards.vue?vue&type=script&lang=js"
import style0 from "./cards.vue?vue&type=style&index=0&id=34c66d14&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "34c66d14",
  null
  
)

export default component.exports