<template>
  <nav class="center-content footer-custom">
    <div v-if="isTabletMode">
      <a
        class="button is-small header-btn header-text-btn-left-tablet has-text-weight-medium"
        :class="currentPanel == 'map' ? 'is-link' : ''"
        :disabled="currentPanel == 'map'"
        v-on:click="setPanel('map')"
        >DISPATCH
      </a>
      <a
        class="button is-small header-btn header-text-btn-left-tablet has-text-weight-medium"
        :class="currentPanel == 'order' ? 'is-link' : ''"
        :disabled="currentPanel == 'order'"
        v-on:click="setPanel('order')"
        >ORDERS
      </a>

      <a
        v-bind:class="{ 'is-link': currentPanel == 'dialpad' }"
        class="button is-small is-primary-button header-btn header-text-btn-left-tablet has-text-weight-medium"
        v-on:click="setPanel('dialpad')"
        >NEW
      </a>
      <a
        class="button is-small is-link-button header-btn header-text-btn-left-tablet has-text-weight-medium"
        :disabled="selectedOrders.length == 0 && !selectedDriver"
        v-on:click="clearSelections()"
        >CLEAR
      </a>
      <a
        class="button is-small is-warning-button header-btn header-text-btn-left-tablet has-text-weight-medium"
        :disabled="selectedOrders.length != 1"
        v-on:click="
          selectedOrders.length == 1 && toggleEditOrderModal(selectedOrders[0])
        "
      >
        EDIT
      </a>
      <a
        class="button is-small is-danger-button header-btn header-text-btn-left-tablet has-text-weight-medium"
        :disabled="selectedOrders.length != 1"
        v-on:click="
          selectedOrders.length == 1 &&
            toggleDeleteOrderModal(selectedOrders[0])
        "
        >DELETE
      </a>

      <a
        v-bind:class="{ 'is-link': currentPanel == 'dayPanel' }"
        class="button is-small header-btn header-btn-left-tablet has-text-weight-medium"
        v-on:click="setPanel('dayPanel')"
      >
        <i class="fa fa-calendar"></i>
      </a>
      <a
        v-bind:class="{ 'is-link': currentPanel == 'accountPanel' }"
        class="button is-small header-btn header-btn-left-tablet has-text-weight-medium"
        v-on:click="setPanel('accountPanel')"
      >
        <i class="fa fa-money"></i>
      </a>
      <a
        v-bind:class="{ 'is-link': currentPanel == 'driverInfoPanel' }"
        class="button is-small header-btn header-btn-left-tablet has-text-weight-medium"
        v-on:click="setPanel('driverInfoPanel')"
      >
        <i class="fa fa-car"></i>
      </a>

      <a
        v-bind:class="{ 'is-link': currentPanel == 'settingsPanel' }"
        class="button is-small header-btn header-btn-left-tablet has-text-weight-medium"
        v-on:click="setPanel('settingsPanel')"
      >
        <i class="fa fa fa-cog"></i>
      </a>

      <a
        v-bind:class="{ 'is-link': currentPanel == 'fullscreen' }"
        class="button is-small header-btn header-btn-left-tablet has-text-weight-medium"
        v-on:click="($event) => toggleFullscreen()"
      >
        <i class="fa fa fa-duotone fa-expand"></i>
      </a>
    </div>
  </nav>
</template>
<script>
const custom = require("../../js/custom.js");

module.exports = {
  data: function () {
    return {
      isTabletMode: false,
      isInFullscreenMode: false,
      currentDate: new Date(Date.now()),
      isUpdatingSettings: false,
    };
  },
  computed: {
    connection() {
      return this.$store.state.connection;
    },
    selectedOrders: function () {
      return this.$store.getters["orders/selectedOrders"];
    },
    selectedDriver: function () {
      return this.$store.getters["drivers/selectedDriver"];
    },
    currentPanel: function () {
      return this.$store.getters["app/getCurrentPanel"];
    },
    settings: function () {
      return this.$store.getters["settings"];
    },
    storeInfo: function () {
      return this.$store.getters["orders/getStore"];
    },
    expandLevel: function () {
      return this.$store.getters["app/getExpandLevel"];
    },
  },
  mounted() {
    this.checkTabletMode();
  },
  methods: {
    checkTabletMode() {
      this.isTabletMode = custom.checkIfTablet();
    },
    setPanel: function (panel) {
      if (panel == "dayPanel") {
        this.$store.dispatch("drivers/deselectDriver");
      }
      if (panel == "settingsPanel") {
        this.$store.dispatch("app/setSettingsPanelTab", 1);
      }
      this.$store.dispatch("app/setPanel", panel);
    },
    clearSelections: function () {
      this.$store.dispatch("orders/clearSelectedOrders");
      this.$store.dispatch("drivers/deselectDriver");
    },
    toggleEditOrderModal: function (order) {
      this.$store.dispatch("orders/setEditOrder", order);
      this.$store.dispatch("app/setPanel", "editOrder");
      this.$store.dispatch("app/editOrderScreenReadOnly", false);
    },
    toggleDeleteOrderModal: function (order) {
      this.$store.dispatch("orders/deleteOrder", order);
    },
    toggleFullscreen: function () {
      var elem = document.documentElement;
      if (document.fullscreenElement) {
        document.exitFullscreen();
      } else {
        if (elem.requestFullscreen) {
          elem.requestFullscreen();
        }
      }
    },
  },
};
</script>

<style scoped>
.header-btn-left {
  height: 35px;
  margin-bottom: 8px;
}

.header-btn-left-tablet {
  height: 35px;
}

.header-text-btn-left-tablet {
  height: 35px;
  width: 65px;
}

.footer-custom {
  background-color: white;
  margin-bottom: 0px !important;
  padding-right: 1em !important;
}

.is-grouped {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.is-link-button {
  border-color: #3273dc !important;
}

.is-primary-button {
  border-color: #6ec545 !important;
}

.is-warning-button {
  border-color: #ffdd57 !important;
}

.is-danger-button {
  border-color: #f14668 !important;
}

.is-link-button:hover {
  background-color: #3273dc !important;
}

.is-primary-button:hover {
  background-color: #6ec545 !important;
}

.is-warning-button:hover {
  background-color: #ffdd57 !important;
}

.is-danger-button:hover {
  background-color: #f14668 !important;
}

.button {
  text-transform: uppercase;
}

.is-smaller {
  font-size: 0.65rem;
}

.center-content {
  display: flex;
  justify-content: center;
  padding: 0.5em;
}
</style>
