<template>
<div class="card" :class="{ 'card-tablet-mode': isTabletMode }">
    <header class="card-header">
        <p class="card-header-title">Settings</p>

        <a class="card-header-icon button is-primary" v-on:click="saveSettings()">
            <i class="fa fa-floppy-o"></i>
            Save
        </a>
        <a class="card-header-icon button" 
          :disabled="isLocked"
          v-on:click="!isLocked && cancelSettings()">
            Cancel
        </a>
        <a class="card-header-icon button is-danger" v-on:click="logout">
            <i class="fa fa-sign-out"></i>
            Log Out
        </a>
    </header>

    <div class="card-content settings-pane">
        <div slot="body">
            <p class="settings-message-title" v-if="isLocked">
              PLEASE ADD YOUR STORE INFORMATION AND CLICK SAVE TO PROCEED
            </p>
            <div class="tabs is-centered is-toggle is-small is-fullwidth">
                <ul>
                <li v-bind:class="{ 'is-active': tab == 1 }">
                    <a v-on:click="changeTab(1)">GENERAL</a>
                </li>
                <li v-bind:class="{ 'is-active': tab == 5 }" v-if="showAccounts">
                    <a v-on:click="changeTab(5)">DRIVERS</a>
                </li>
                <li v-bind:class="{ 'is-active': tab == 2 }">
                    <a v-on:click="changeTab(2)">ROUTE OPTIMIZATION</a>
                </li>
                <li v-bind:class="{ 'is-active': tab == 3 }">
                    <a v-on:click="changeTab(3)">INTEGRATIONS</a>
                </li>
                <li v-bind:class="{ 'is-active': tab == 4 }">
                    <a v-on:click="changeTab(4)">BILLING</a>
                </li>
                </ul>
            </div>

            <div v-if="tab === 1">
              <div v-if="showRequiredFieldsErrorMessage">
                <h1 class="help is-danger errorMessage">PLEASE FILL IN THE REQUIRED INPUTS</h1>
                <br>
              </div>
                <section class="section">
                  <div class="columns">
                    <div class="column is-9 vertical-line">
                      <div class="columns">
                          <div class="column is-2">
                              <div class="field">
                                  <label class="label is-small">Store ID</label>
                                  <p class="control">
                                      <input class="input is-small" type="text" v-model="tempStoreID" readonly />
                                  </p>
                              </div>
                          </div>

                          <div class="column is-4">
                              <div class="field">
                                  <div class="field">
                                      <label class="label is-small">Store Name <span v-if="isLocked" class="required-span"> *</span></label>
                                      <p class="control">
                                          <input class="input is-small" type="text" v-model="tempSettings.name" />
                                          <p class="help is-danger" v-if="!tempSettings.name && !isSettingsLoading">PLEASE ENTER THE STORE NAME</p>
                                      </p>
                                  </div>
                              </div>
                          </div>
                          <div class="column is-4">
                              <div class="field">
                                  <div class="field">
                                      <label class="label is-small">Email</label>
                                      <p class="control text-upper-case">
                                          <span>{{ this.userEmail }}</span>
                                      </p>
                                  </div>
                              </div>
                          </div>
                      </div>

                      <div class="columns">
                          <div class="column is-6">
                              <div class="field">
                                  <label class="label is-small">Store Address <span v-if="isLocked" class="required-span"> *</span></label>
                                  <p class="control">
                                      <address-textbox v-model="tempSettings.address" :class="size"></address-textbox>
                                      <p class="help is-danger" v-if="!tempSettings.address && !isSettingsLoading">PLEASE ENTER THE STORE ADDRESS</p>
                                  </p>
                              </div>
                          </div>
                          <div class="column is-4">
                              <div class="field">
                                  <div class="field">
                                      <label class="label is-small">
                                          Phone Number <span v-if="isLocked" class="required-span"> *</span>
                                      </label>
                                      <p class="control has-icons-left">
                                          <input class="input is-small"
                                                type="text"
                                                @keypress="onlyNumber"
                                                pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                                                placeholder="Phone #"
                                                maxlength="10"
                                                v-model="tempPhone" />
                                          <span class="icon is-small is-left">
                                              <i class="fa fa-phone"></i>
                                          </span>
                                      </p>
                                      <p class="help is-danger" v-if="!tempPhone && !isSettingsLoading">PLEASE ENTER THE PHONE NUMBER</p>
                                      <p class="help is-danger" v-if="tempPhone && !validPhone && !isSettingsLoading">PHONE NUMBER IS NOT VALID</p>
                                  </div>
                              </div>
                          </div>
                      </div>
                    </div>

                      <div class="column is-3">
                          <div class="field">
                            <label class="label is-small"><center>STORE LOGO</center></label>
                            <center>
                              <image_uploader
                              :oldImage="oldStoreLogo"
                              :onNewImageSelected="handleNewLogoSelected"/>
                            </center>
                            <!-- <input type="file" ref="fileInput" @change="handleImageUpdated" /> -->
                          </div>
                      </div>
                  </div>
                  <div class="columns">
                      <div class="column is-4">
                        <div class="field">
                          <label class="ring-checkbox">
                            <input type="checkbox" v-model="tempSettings.IsConsoleRingActive" />
                            Ring Activated
                          </label>
                        </div>
                      </div>
                    </div>
                    
                    <div class="columns">
                        <div class="column">
                            <div class="field">
                                <a 
                                    class="button is-danger" 
                                    @click="toggleShowUpdatePassword()"
                                >
                                    UPDATE USER PASSWORD
                                </a>
                            </div>
                        </div>
                    </div>

                    <section class="section" v-if="this.showUpdatePassword">
                        <h1 class="section-title">UPDATE USER PASSWORD</h1>
                        <br/>

                        <div class="columns">
                            <div class="column is-12    ">
                                <div class="field" v-if="!this.resetPassword.sentOtp">
                                    <button class="button is-primary is-small" v-on:click="sendCode">
                                            SEND VERIFICATION CODE
                                    </button>
                                </div>
                                <div class="field" v-if="this.resetPassword.sentOtp">
                                    <div class="columns">
                                        <div class="column is-3">
                                            <div class="field">
                                                <label class="label is-small">VERIFICATION CODE*</label>
                                                <p class="control">
                                                    <input class="input is-small" type="password" v-model="resetPassword.code" />
                                                </p>
                                                <p class="help is-danger"
                                                    v-if="resetPassword.emptyVerificationCodeValidation">
                                                    Required
                                                </p>
                                            </div>
                                        </div>
                        
                                        <div class="column is-3">
                                            <div class="field">
                                                <label class="label is-small">NEW PASSWORD*</label>
                                                <p class="control">
                                                    <input class="input is-small" type="password" v-model="resetPassword.newPassword" />
                                                </p>
                                                <p class="help is-danger"
                                                    v-if="resetPassword.emptyNewPasswordValidation">
                                                    Required
                                                </p>
                                            </div>
                                        </div>
                        
                                        <div class="column is-3">
                                            <div class="field">
                                                <div class="field">
                                                    <label class="label is-small">CONFIRM PASSWORD*</label>
                                                    <p class="control">
                                                        <input class="input is-small" type="password" v-model="resetPassword.confirmPassword" />
                                                    </p>
                                                    <p class="help is-danger"
                                                    v-if="resetPassword.emptyConfirmPasswordValidation">
                                                    Required
                                                </p>
                                                </div>
                                            </div>
                                        </div>
                        
                                        <div class="column is-3">
                                            <div class="field">
                                                <div class="field">
                                                    <br />
                                                    <a class=" button is-small is-warning" v-on:click="resetPasswordOTP">
                                                        UPDATE
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <p class="help is-danger" v-if="isServerSideError">
                                        {{ serverSideErrorMessage }}
                                    </p>
                                    <p v-if="resetPassword.errors.length">
                                        <ul>
                                            <li class="help is-danger" v-for="error in resetPassword.errors">{{ error }}</li>
                                        </ul>
                                    </p>
                                    <p v-if="successResetPassword" class="success-text">PASSWORD SUCCESSFULLY CHANGED</p>
                                </div>
                            </div>
                        </div>
                    </section>
                </section>
            </div>

            <div v-if="tab === 5 && showAccounts">
                <section class="section">   
                  <div class="top-section">
                      <label class="label">Driver Accounts</label>
                  </div>
                    <div class="field">
                        <label class="label is-small">
                            <input class="checkbox" type="checkbox" :disabled="storeInfo.isOpen" v-model="tempSettings.driverAccounts" />
                            Track Driver Accounts
                        </label>
                    </div>
                    <div class="field">
                        <label class="label is-small">
                            <input class="checkbox is-small" type="checkbox" :disabled="!tempSettings.driverAccounts || storeInfo.isOpen" v-model="tempSettings.IsNonCashOrdersTipsPaidAtClosing" />
                            Credit Tips Paid At Closing
                        </label>
                    </div>
                    <div class="field">
                        <label class="label is-small">
                            <input class="checkbox is-small" type="checkbox" :disabled="!tempSettings.driverAccounts || storeInfo.isOpen" v-model="tempSettings.IsDriverFeePaidAtClosing" />
                            Driver Fee Paid At Closing
                        </label>
                    </div>
                <div>
                    <label class="label is-small">Driver Fee / Order</label>
                    <div class="field has-addons">
                        <p class="control">
                            <input class="input is-small number-field" v-model="tempSettings.DriverDeliveryFee" step='0.01' type="number" :disabled="storeInfo.isOpen"/>
                        </p>
                        <p class="control">
                            <label class="button is-small is-static">$</label>
                        </p>
                    </div>
                </div>
                </section>
                <hr>
                <div class="columns">
                  <div class="column is-9">
                      <label class="label is-small">Default Driver Message</label>
                      <textarea class="textarea is-medium"  type="text" v-model="tempSettings.DriverOrderDeliveryMessage" rows="3" style="resize: vertical; width: 100%;"></textarea>
                  </div>
                </div>
            </div>

            <div v-if="tab === 2">
                <section class="section">
                    <div class="columns internal-columns-p">
                        <label class="column label is-one-fifth"> Zone 1</label>
                        <div class="column">
                            <div class="fl-l mr-3">
                                <label class="z-label">Radius </label>
                            </div>
                            <div class="field has-addons fl-l">
                                <p class="control">
                                    <input class="input is-small number-field" type="number" min="1" max="99" :disabled="true" v-model="miles0" />
                                </p>
                                <p class="control">
                                    <label class="button is-small is-static">to</label>
                                </p>
                                <p class="control">
                                    <input class="input is-small number-field" type="number" min="1" max="99" step="0.1" v-model="miles1" />
                                </p>
                                <p class="control">
                                    <label class="button is-small is-static">miles</label>
                                </p>
                            </div>
                        </div>
                        <div class="column">
                            <div class="fl-l mr-3">
                                <label class="z-label">Deliver Within</label>
                            </div>
                            <div class="field has-addons fl-l">
                                <p class="control">
                                    <input class="input is-small number-field" type="number" min="1" max="999" step="1" v-model="minutes1" />
                                </p>
                                <p class="control">
                                    <label class="button is-small is-static">minutes</label>
                                </p>
                            </div>
                        </div>
                    </div>

                    <div class="columns internal-columns-p">
                      <label class="column label is-one-fifth"> Zone 2</label>
                        <div class="column">
                            <div class="fl-l mr-3">
                                <label class="z-label">Radius</label>
                            </div>
                            <div class="field has-addons fl-l">
                                <p class="control">
                                    <input class="input is-small number-field" type="number" min="1" max="999" :disabled="true" v-model="miles1" />
                                </p>
                                <p class="control">
                                    <label class="button is-small is-static">to</label>
                                </p>
                                <p class="control">
                                    <input class="input is-small number-field" type="number" min="1" max="999" step="0.1" v-model="miles2" />
                                </p>
                                <p class="control">
                                    <label class="button is-small is-static">miles</label>
                                </p>
                            </div>
                        </div>
                        <div class="column">
                            <div class="fl-l mr-3">
                                <label class="z-label">Deliver Within</label>
                            </div>
                            <div class="field has-addons fl-l">
                                <p class="control">
                                    <input class="input is-small number-field" type="number" min="1" max="999" step="1" v-model="minutes2" />
                                </p>
                                <p class="control">
                                    <label class="button is-small is-static">minutes</label>
                                </p>
                            </div>
                        </div>
                    </div>

                    <div class="columns internal-columns-p">
                        <label class="column label is-one-fifth"> Zone 3</label>
                        <div class="column">
                            <div class="fl-l mr-3">
                                <label class="z-label">Radius</label>
                            </div>
                            <div class="field has-addons fl-l">
                                <p class="control">
                                    <input class="input is-small number-field" type="number" min="1" max="999" :disabled="true" v-model="miles2" />
                                </p>
                                <p class="control">
                                    <label class="button is-small is-static">+</label>
                                </p>
                            </div>
                        </div>
                        <div class="column">
                            <div class="fl-l mr-3">
                                <label class="z-label">Deliver Within</label>
                            </div>
                            <div class="field has-addons fl-l">
                                <p class="control">
                                    <input class="input is-small number-field" type="number" min="1" max="999" step="1" v-model="minutes3" />
                                </p>
                                <p class="control">
                                    <label class="button is-small is-static">minutes</label>
                                </p>
                            </div>
                        </div>
                    </div>
                    <br>
                    <div class="columns internal-columns-p">
                        <label class="label is-small column is-one-third">Dynamic ETA increase when target can't be met</label>
                        <div class="column field has-addons">
                            <p class="control">
                                <input class="input is-small number-field" type="number" v-model="tempSettings.NewOrderSlidingTargetMinutes" />
                            </p>
                            <p class="control">
                                <label class="button is-small is-static"> minutes </label>
                            </p>
                        </div>
                    </div>
                    <br>
                    
                    <div>
                        <div class="columns internal-columns-p">
                            <label class="label is-small column is-one-fifth">Average Order Prep Time</label>
                            <div class="field has-addons column">
                                <p class="control">
                                    <input class="input is-small number-field" type="number" v-model="tempSettings.average_order_prep_time" />
                                </p>
                                <p class="control">
                                    <label class="button is-small is-static"> minutes </label>
                                </p>
                            </div>
                        </div>
                        <div class="columns internal-columns-p">
                            <label class="label is-small column is-one-fifth">Time Spent at Each Stop</label>
                            <div class="field has-addons column">
                                <p class="control">
                                    <input class="input is-small number-field" type="number" v-model="tempSettings.order_addon_constant_minutes" />
                                </p>
                                <p class="control">
                                    <label class="button is-small is-static"> minutes </label>
                                </p>
                            </div>
                        </div>
                        <div class="columns internal-columns-p">
                            <label class="label is-small column is-one-fifth">Average Required Time at Shop</label>
                            <div class="field has-addons column">
                                <p class="control">
                                    <input class="input is-small number-field" type="number" v-model="tempSettings.average_required_time_at_shop" />
                                </p>
                                <p class="control">
                                    <label class="button is-small is-static"> minutes </label>
                                </p>
                            </div>
                        </div>
                    </div>
                    <br>
                    <div class="columns internal-columns-p">
                      <label class="label is-small column is-one-fifth">Dropoff Notes</label>
                      <div class="field column">
                          <p class="control">
                              <input class="input is-small" v-model="tempSettings.dropoffNotes" type="text" />
                          </p>
                      </div>
                    </div>
                </section>
            </div>

            <div v-if="tab === 3">
              <section class="section">
                <div class="column is-12">
                  <div class="columns">
                    <div class="column">
                      <div>
                          <span class="squareup"></span>
                      </div>

                      <div class="field">
                          <strong><label class="is-small">STATUS:
                                  {{
                                    squareInProgressEnabled
                                    ? "PENDING"
                                    : tempSettings.square_authorization_token_exists
                                      ? "ENABLED"
                                      : "DISABLED"
                                  }}</label></strong>
                      </div>

                      <div v-if="squareInProgressEnabled">
                          Square Integration Status Pending. Reopen settings after
                          authenticating to complete setup.
                      </div>
                    </div>

                    <div v-if="!tempSettings.square_authorization_token_exists &&
                      !squareInProgressEnabled
                      " class="field column">
                        <a class="button is-primary is-small" :href="this.settingsUrls.squareAppUrl" @click="squareInProgressEnabled = true">Connect</a>
                    </div>

                      <div class="column">
                        <div v-if="!squareInProgressDisabled && tempSettings.square_authorization_token_exists" class="field">
                            <a class="button is-small is-danger" :href="this.settingsUrls.squareRevokeUrl" @click="
                              (squareInProgressDisabled = true) &&
                              (tempSettings.square_authorization_token_exists = false)
                              ">Disconnect</a>
                        </div>
                        <div v-if="!squareInProgressDisabled && tempSettings.square_authorization_token_exists" class="field" >
                            <label class="is-small" style="text-align: center; font-size: 13px; color: grey; font-style: italic">Expires:
                                {{ tempSettings.square_expires_at | formatDate }}</label>
                        </div>
                      </div>

                      <div v-if="!squareInProgressDisabled && tempSettings.square_authorization_token_exists" class="field column">
                          <!-- <label class="label is-small">Square Business Location</label> -->
                          <div class="control">
                              <div class="select is-small" style="width: 100%">
                                  <select v-model="tempSettings.square_location" style="width: 100%">
                                      <option value="">- YOU MUST SELECT A LOCATION -</option>
                                      <option v-for="location in square_locations" :key="location.locationId" :value="location.locationId">
                                          {{ location.name }}
                                      </option>
                                  </select>
                              </div>
                          </div>
                      </div>
                  </div> 
                </div>

                <hr>

                <div class="column is-12">
                  <div class="columns">
                    <div class="column">
                      <div>
                          <span class="deliverect"></span>
                      </div>

                      <div class="field">
                          <strong><label class="is-small">STATUS:
                                  {{
                                    tempSettings.deliverect_account_exists
                                    ? "ENABLED"
                                    : "DISABLED"
                                  }}</label></strong>
                      </div>
                    </div>

                    <div v-if="!tempSettings.deliverect_account_exists" class="field column">
                        <div class="field" v-if="showDeliverectField">
                            <div class="field">
                                <label class="label is-small">Deliverect Account Id</label>
                                <p class="control">
                                    <input class="input is-small" type="text" v-model="tempSettings.deliverect_account_id" />
                                </p>
                            </div>
                        </div>
                        <a class="button is-primary is-small" :disabled="!tempSettings.deliverect_account_id && showDeliverectField" @click="connectDeliverect()">
                            Connect
                        </a>
                    </div>

                    <div v-if="tempSettings.deliverect_account_exists" class="field column">
                        <div class="field">
                            <a class="button is-danger is-small" @click="disconnectDeliverect()">
                                Disconnect
                            </a>
                        </div>
                    </div>

                    <div v-if="tempSettings.deliverect_account_exists" class="field column">
                        <!-- <label class="label is-small">Deliverect Business Location</label> -->
                        <div class="control">
                            <div class="select is-small" style="width: 100%">
                                <select v-model="tempSettings.deliverect_location" style="width: 100%">
                                    <option value="">- YOU MUST SELECT A LOCATION -</option>
                                    <option v-for="location in deliverect_locations" :key="location.locationId" :value="location.locationId">
                                        {{ location.name }}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div v-if="!tempSettings.deliverect_account_exists" class="field column">
                    </div>
                  </div>
                  
                </div>

                <hr>

                <!-- <div class="is-horizontal">
                  <b>Import Pickup Orders</b>
                  <label class="switch">
                    <input type="checkbox" v-model="tempSettings.ImportPickupOrders">
                    <span class="slider round"></span>
                  </label>
                </div> -->
              </section>
            </div>

            <billing v-if="tab === 4"></billing>
        </div>
    </div>
</div>
</template>

<script>
var addressTextbox = require("../address_textbox_generic.vue").default;
var billing = require("./billing/billing_tab.vue").default;
var image_uploader = require("./image_uploader.vue").default;
var custom = require("../../js/custom.js")

module.exports = {
  components: {
    addressTextbox,
    billing,
    image_uploader
  },
  data: function () {
    return {
      store_id: this.$store.state.user.store_id,
      base_url: this.$store.state.base_url,
      api_url: this.$store.state.api_url,
      settings: this.$store.state.settings,
      tempSettings: {},
      tempPhone: "",
      validPhone: true,
      locations: [],
      tempStoreID: "",
      squareAppUrl: "",
      squareInProgressEnabled: false,
      squareInProgressDisabled: false,
      minutes1: 0,
      minutes2: 0,
      minutes3: 0,
      miles1: 0,
      miles2: 0,
      miles0: 0,
      size: "is-small",
      userPassword: {
        oldPassword: "",
        newPassword: "",
        confirmPassword: "",
      },
      // to reset password using OTP
      resetPassword: {
        newPassword: "",
        confirmPassword: "",
        code: "",
        sentOtp: false,
        emptyNewPasswordValidation: false,
        emptyConfirmPasswordValidation: false,
        emptyVerificationCodeValidation: false,
        errors: [],
      },
      emptyNewPasswordValidation: false,
      emptyOldPasswordValidation: false,
      emptyConfirmPasswordValidation: false,
      errors: [],
      showUpdatePassword: false,
      showDeliverectField: false,
      newStoreLogo: null,
      oldStoreLogo: null,
      logoChanged: false,
      showRequiredFieldsErrorMessage: false,
      isTabletMode: false,
    };
  },
  computed: {
    showAccounts:function () {
      return this.$store.getters["orders/getStore"]['showAccounts'];
    },
    isLocked: function () {
      return this.$store.getters["app/getIsLocked"];
    },
    isServerSideError() {
      return this.$store.state.user.isServerSideError;
    },
    serverSideErrorMessage() {
      return this.$store.state.user.serverSideErrorMessage;
    },
    successResetPassword() {
      return this.$store.state.user.successResetPassword;
    },
    userEmail() {
      return this.$store.getters["user/getEmail"];
    },
    userPhone() {
      return this.$store.getters["user/getPhone"];
    },
    storeInfo: function () {
      return this.$store.getters["orders/getStore"];
    },
    settingsUrls: function () {
      return this.$store.getters["settingsUrls"];
    },
    tab: function() {
      return this.$store.getters["app/getSettingsPanelTab"];
    }
   },
  watch: {
    tempPhone: function () {
      this.validatePhone();
    },
  },
  created: function () {
    this.getSettings();
    this.$store.dispatch("user/resetUpdatePassword");
  },
  mounted: function () {
    this.checkTabletMode();
  },
  methods: {
    checkTabletMode() {
      this.isTabletMode = custom.checkIfTablet();
    },
    setPanel: function (panel) {
      this.$store.dispatch("app/setPanel", panel);
    },
    handleNewLogoSelected(newStoreLogo) {
      this.logoChanged = true;
      this.newStoreLogo = newStoreLogo;
    },
    saveImage(){
      if(this.newStoreLogo) {
        const formData = new FormData();
      formData.append('file', this.newStoreLogo);

      this.$http
        .post(this.api_url + `Api/V1/Stores/UpdateStoreLogo/${this.store_id}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(
          (res) => {
            this.$store.dispatch("orders/getStore");
          },
          (err) => {
            console.log("Error", err);
            
          }
        );
      }
    },
    getMimeType(base64String) {
        const imageSignatures = {
            '/9j/': 'image/jpeg',
            'iVBORw': 'image/png',
        };

        for (const signature in imageSignatures) {
            if (base64String.startsWith(signature)) {
                return imageSignatures[signature];
            }
        }

        return 'application/octet-stream';
    },
    base64StringToObjectURL(base64String) {
        const mimeType = this.getMimeType(base64String);
        const byteCharacters = atob(base64String);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);

        const blob = new Blob([byteArray], { type: mimeType });

        const objectURL = URL.createObjectURL(blob);

        return objectURL;
    },
    initStoreLogo(){
      if(this.storeInfo.storeLogo){
        this.oldStoreLogo = this.base64StringToObjectURL(this.storeInfo.storeLogo);
      }
    },
    updatePassword: function () {
      this.errors = [];
      this.emptyOldPasswordValidation = false;
      this.emptyNewPasswordValidation = false;
      this.emptyConfirmPasswordValidation = false;
      this.passwordError = false;
      this.$store.dispatch("user/resetUpdatePassword");

      //password validation
      if (!this.userPassword.oldPassword) {
        this.emptyOldPasswordValidation = true;
      }
      if (!this.userPassword.newPassword) {
        this.emptyNewPasswordValidation = true;
      }
      if (!this.userPassword.confirmPassword) {
        this.emptyConfirmPasswordValidation = true;
      }

      if (
        !(
          this.emptyNewPasswordValidation ||
          this.emptyOldPasswordValidation ||
          this.emptyConfirmPasswordValidation
        )
      )
        if (!this.isPasswordValid(this.userPassword.newPassword)) {
          this.errors.push(
            "Password must be between 6 and 20 characters and contain one uppercase letter, one lowercase letter, one digit and one special character."
          );
          this.passwordError = true;
        } else if (
          this.userPassword.newPassword != this.userPassword.confirmPassword
        ) {
          this.errors.push("Passwords must match");
          this.passwordError = true;
        }

      if (
        !(
          this.emptyNewPasswordValidation ||
          this.emptyOldPasswordValidation ||
          this.emptyConfirmPasswordValidation ||
          this.passwordError
        )
      ) {
        //create update password model
        const model = {
          email: this.userEmail,
          newPassword: this.userPassword.newPassword,
          oldPassword: this.userPassword.oldPassword,
        };
        this.$store.dispatch("user/updatePassword", model);
      }
    },
    //is password valid
    isPasswordValid: function (password) {
      var re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{8,15}$/;
      return re.test(password);
    },
    setPanel: function (panel) {
      this.$store.dispatch("app/setPanel", panel);
    },
    splitRadiusDriveTimes: function (radiusDriveTimes) {
      const parts = radiusDriveTimes.split("|");
      const minutes = parts[0].split(";");
      const miles = parts[1].split(";");

      this.minutes1 = minutes[0];
      this.minutes2 = minutes[1];
      this.minutes3 = minutes[2];

      this.miles1 = miles[0];
      this.miles2 = miles[1];
    },
    combineRadiusDriveTimes: function () {
      return `${this.minutes1};${this.minutes2};${this.minutes3}|${this.miles1};${this.miles2}`;
    },
    getSettings: function () {
      this.isSettingsLoading = true;
      this.initStoreLogo();

      var payload = {
        store_id: this.store_id,
        method: "GET",
      };

      // Load settings into settings
      this.$http.post(this.base_url + "settings/", payload).then(
        (res) => {
          this.$store
            .dispatch("updateSettings", res.body.settings[0])
            .then(() => {
              if (res.body.result != 0) {
                alert(res.body.error);
              }
              // Load settings into tentative settings that are deleted if not saved
              for (var key in this.settings) {
                this.$set(this.tempSettings, key, this.settings[key]);
              }
              this.tempSettings["driverAccounts"] = this.storeInfo.driverAccounts;

              this.square_locations = res.body.square_locations;
              this.deliverect_locations = res.body.deliverect_locations;
              this.tempSettings.radius_drive_times = res.body.settings[0].radius_drive_times;
              this.splitRadiusDriveTimes(this.tempSettings.radius_drive_times);
              this.tempSettings["DriverOrderDeliveryMessage"] = res.body.settings[0].DriverOrderDeliveryMessage && res.body.settings[0].DriverOrderDeliveryMessage != "" ? 
                res.body.settings[0].DriverOrderDeliveryMessage : "Hi I'm delivering your food, I'm outside and can't get into the building. Please call me back asap, if I don't hear back soon I'll have to head to my next stop! I'll wait a few minutes, but I can't leave the food outside.";
            });
          this.tempPhone = res.body.settings[0].storePhoneNumber;
          this.tempStoreID = this.store_id;
          this.$store.dispatch("updateSettingsUrls", Object.fromEntries(Object.entries(res.body).filter(([key]) => key.toLowerCase().includes("url"))))
          this.isSettingsLoading = false;
        },
        (res) => {
          this.isSettingsLoading = false;
          console.log("Failed to get settings...", res);
        }
      );
    },
    saveSettings: function () {
      if (!this.tempSettings.name || !this.tempSettings.address || !this.tempPhone || !this.validPhone) {
        this.showRequiredFieldsErrorMessage = true;
        return;
      }

      this.showRequiredFieldsErrorMessage = false;
      this.saveImage();

      // Merge tempSettings with settings;
      var payload = {
        store_id: parseInt(this.store_id),
        method: "POST",
      };

      if (this.validPhone) {
        // Load settings into payloaod
        for (var s in this.tempSettings) {
          payload[s] = this.tempSettings[s];
        }
        payload['storePhoneNumber'] = this.tempPhone;

        payload["radius_drive_times"] = this.combineRadiusDriveTimes();
        this.$store.dispatch("app/expandLevelToDefault");
        this.$http.post(this.base_url + "settings/", payload).then(
          (res) => {
            if (res.body.result != 0) {
              alert(res.body.error);
            } else {
              if(this.isLocked) {
                // update organization name 
                const org = {
                  email: this.userEmail,
                  name: this.tempSettings.name
                }
                this.$store.dispatch("user/updateOrganization", org);
                const driver = {
                  driver_name: "Driver",
                  DriverLastName: "A",
                  phone_number: this.tempPhone,
                  pin: null,
                  on_duty: true
                }
                this.$store.dispatch("drivers/addDriverAfterRegisterComplete", driver);
              }else{
                this.setPanel("order");
              }
              this.getSettings();
              this.$store.dispatch("orders/getStore");
            }
          },
          (res) => {
            console.log("Failed to post settings...", res);
          }
        );
      } else {
        alert("Phone Number is Invalid.");
      }
    },
    connectDeliverect: function () {
      if (!this.showDeliverectField) {
        this.showDeliverectField = true;
        return;
      }

      this.$http
        .post(
          this.settingsUrls.deliverectConnectUrl + this.tempSettings.deliverect_account_id
        )
        .then(
          () => {
            this.getSettings();
          },
          (res) => {
            console.log("Failed to connect to Deliverect...", res);
          }
        );
    },

    disconnectDeliverect: function () {
      this.$http.post(this.settingsUrls.deliverectDisconnectUrl).then(
        () => {
          this.getSettings();
        },
        (res) => {
          console.log("Failed to disconnect from Deliverect...", res);
        }
      );
    },
    cancelSettings: function () {
      this.setPanel("order");
    },

    sendCode: function () {
      this.errors = [];
      this.emptyPasswordValidation = false;
      this.validPasswordValidation = false;

      if (this.userEmail && this.isEmailValid(this.userEmail)) {
        this.resetPassword.sentOtp = true;
        this.$store.dispatch("user/sendOtpByMailFromSettings", this.userEmail);
      }
    },

    resetPasswordOTP: function () {
      this.resetPassword.errors = [];
      this.resetPassword.emptyNewPasswordValidation =
        !this.resetPassword.newPassword;
      this.resetPassword.emptyConfirmPasswordValidation =
        !this.resetPassword.confirmPassword;
      this.resetPassword.emptyVerificationCodeValidation =
        !this.resetPassword.code;

      //paassword validation
      if (!this.isPasswordValid(this.resetPassword.newPassword)) {
        this.resetPassword.errors.push(
          "Password must be between 6 and 20 characters and contain one uppercase letter, one lowercase letter, one digit and one special character."
        );
      }
      if (this.password != this.confirmPassword) {
        this.resetPassword.errors.push("Passwords must match");
      }

      if (
        this.resetPassword.newPassword &&
        this.resetPassword.errors.length == 0 &&
        this.isEmailValid(this.userEmail)
      ) {
        //create reset password model
        const model = {
          email: this.userEmail,
          password: this.resetPassword.newPassword,
          code: this.resetPassword.code,
        };

        this.$store.dispatch("user/resetPasswordFromSettings", model);
      }
    },

    isEmailValid: function (email) {
      var re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },

    toggleShowUpdatePassword: function () {
      this.showUpdatePassword = !this.showUpdatePassword;
      this.resetPassword.sentOtp = false;
    },

    logout: function () {
      this.$store.dispatch("logout");
    },

    onlyNumber: function ($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        // 46 is dot
        $event.preventDefault();
      }
    },

    isPhoneValid: function (phone) {
      var re = /^\+?(\d[\d-. ]+)?(\([\d-. ]+\))?[\d-. ]+\d$/;
      return re.test(phone);
    },

    validatePhone: function () {
      this.validPhone =
        this.isPhoneValid(this.tempPhone) && this.tempPhone.length == 10;
    },

    changeTab: function (t) {
      // this.tab = t;
      this.setSettingsPanelTab(t)
      this.showDeliverectField = false;
    },

    setSettingsPanelTab: function (t) {
      this.$store.dispatch("app/setSettingsPanelTab", t);
    },
    
  },
};
</script>

<style scoped>
.checkbox {
  padding: 6px 6px;
  font-size: 12px;
  color: #363636;
  font-weight: bold;
}

span.squareup {
  background: url("../../../public/img/squareup.svg") no-repeat top left;
  background-size: contain;
  display: inline-block;
  height: 22px;
  width: 88px;
}

span.deliverect {
  background: url("../../../public/img/deliverect.png") no-repeat top left;
  background-size: contain;
  display: inline-block;
  height: 22px;
  width: 128px;
}

.number-field {
  max-width: 60px;
}

.z-label {
  font-weight: 700;
  font-size: 0.75rem;
  margin-left: 5px;
}

.fl-l {
  float: left;
}

.mr-3 {
  margin-right: 1rem;
  width: 6rem;
}

.section-title {
  font-weight: bold;
  font-size: 20px;
}

.success-text {
  color: #37c936;
}
.settings-pane {
  max-height: calc(100vh - 140px);
  overflow-y: auto;
  
}

.vertical-line {
    border-right: 1px solid #ccc;
}

.label, .z-label, .button, .is-static {
    text-transform: uppercase;
}

.z-label{
  margin: 0;
  font-size: .7rem;
}


.input[disabled] {
  border-color: #ccc !important; 
}

input{
  text-transform: uppercase;
}

.errorMessage{
  font-size: 18px;
  font-weight: bold
}

.settings-message-title{
  font-size: 1.5em;
  font-weight: bold;
  margin-bottom: 1em;
  padding: 1em;
  background-color: #f14668;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.top-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem; 
}

.ring-checkbox {
  display: block;
  margin-bottom: 5px;
}

</style>
