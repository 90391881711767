<template>
    <div class="card">
      <header class="card-header">
        <p class="card-header-title">{{ formattedPhone }}</p>
        <div class="card-header-title">
          <input
            ref="newOrderNumber"
            class="input is-small order-number-input"
            :class="{ 'is-danger': errorOrderNumber }"
            v-model="newOrder.orderNum"
            type="text"
            placeholder="ORDER # (OPTIONAL)"
          />
          <p
            class="help is-danger ml-1"
            v-bind:style="{
                        visibility: errorOrderNumber ? 'visible' : 'hidden',
                    }"
            >
          Order # must be unique
        </p>
        </div>
        <a class="card-header-icon button is-primary" v-on:click="saveOrder()"
          >Save</a
        >
        <a class="card-header-icon button" v-on:click="toggleNewOrder()"
          >Cancel</a
        >
      </header>
  
      <div class="card-content">
        <strong>
          <p
            class="help is-danger has-text-centered is-size-6"
            v-if="hasValidationErrors"
          >
          CHECK MISSING REQUIRED FIELDS
          </p>
        </strong>
        <strong>
          <p
            class="help is-danger"
            v-bind:style="{ visibility: newOrderError ? 'visible' : 'hidden' }"
          >
            {{ newOrderError }}
          </p>
        </strong>
        <section class="section">
          <div class="columns is-mobile">
            <div class="column is-one-fifth">
              <div class="field">
                <div class="control">
                  <input
                    class="input"
                    :class="{ 'is-danger': errorName }"
                    v-model="newOrder.name"
                    type="text"
                    placeholder="NAME"
                  />
                </div>
                <p
                  class="help is-danger"
                  v-bind:style="{ visibility: errorName ? 'visible' : 'hidden' }"
                >
                  Required
                </p>
              </div>
            </div>
            <div class="column is-half">
              <div class="field">
                <div class="control">
                  <address-textbox v-model="newOrder.address" :error="errorAddress"></address-textbox>
                </div>
                <p
                  class="help is-danger"
                  v-bind:style="{
                    visibility: errorAddress ? 'visible' : 'hidden',
                  }"
                >
                  Required
                </p>
              </div>
            </div>
            <div class="column">
              <div class="toggle-container">
                <label class="switch">
                  <input type="checkbox" v-model="newOrder.is_scheduled" />
                  <span class="slider round"></span>
                </label>
                <div
                  class="scheduled-label"
                  :style="{ 
                                  backgroundColor: newOrder.is_scheduled ? '#6ec545' : '#dcdcdc',
                                  color: newOrder.is_scheduled ? '#ffffff' : '#4a4a4a' 
                              }"
                >
                  SCHEDULED
                </div>
              </div>
            </div>
          </div>
  
          <div class="columns is-align-items-center">
            <div class="column is-one-fifth">
              <div class="field">
                <div class="select" :class="{ 'is-danger': errorPayment }" style="width: 100%">
                  <select v-model="newOrder.payment" style="width: 100%">
                    <option class="placeholder" value="" disabled selected hidden>
                      PAYMENT TYPE
                    </option>
                    <option value="Prepaid">Prepaid</option>
                    <option value="Cash">Cash</option>
                    <option value="Credit">Credit</option>
                    <option value="Other">Other</option>
                  </select>
                </div>
                <p
                  class="help is-danger"
                  v-if="errorPayment"
                  v-bind:style="{
                              visibility: errorPayment ? 'visible' : 'hidden',
                              }"
                >
                  Required
                </p>
              </div>
              <div class="field">
                <div class="columns is-flex is-align-items-center">
                  <div class="column is-half">
                    <label class="label">TIP</label>
                  </div>
                  <div class="column is-half">
                    <input
                      type="number"
                      class="input no-arrows"
                      v-model="newOrder.tip"
                      min="0.00"
                      step="0.01"
                      max="2500"
                    />
                  </div>
                </div>
              </div>
              <div class="field">
                <div class="columns is-flex is-align-items-center">
                  <div class="column is-half">
                    <label class="label">TOTAL</label>
                  </div>
                  <div class="column is-half">
                    <input
                      type="number"
                      class="input no-arrows"
                      v-model="newOrder.total"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="column is-half">
              <div class="field">
                <div class="control">
                  <textarea
                    class="textarea"
                    rows="6"
                    placeholder="DELIVERY INSTRUCTIONS / APT NUMBER"
                    v-model="newOrder.special_instructions"
                  ></textarea>
                </div>
              </div>
            </div>
            <div class="column">
              <div class="field">
                <label class="label">RECEIVED</label>
                <div class="control">
                  <input
                    class="input"
                    :class="{ 'is-danger': errorEntryDateTime }"
                    v-model="newOrder.entry_datetime"
                    type="datetime-local"
                    :disabled="newOrder.is_scheduled"
                  />
                </div>
                <p
                  class="help is-danger"
                  v-if="errorEntryDateTime"
                  v-bind:style="{visibility: errorEntryDateTime ? 'visible' : 'hidden',}"
                >
                  Required
                </p>
              </div>
              <div class="field" v-if="!newOrder.is_scheduled">
                <label class="label">DELIVER BY</label>
                <div class="control">
                  <input
                    class="input"
                    v-model="newOrder.deliver_by_datetime"
                    type="datetime-local"
                    :disabled="true"
                  />
                </div>
              </div>
              <div class="field" v-else>
                <label class="label">DELIVER AT</label>
                <div class="control">
                  <input
                    class="input"
                    :class="{ 'is-danger': errorDeliverAtDateTime }"
                    v-model="newOrder.deliver_datetime"
                    type="datetime-local"
                  />
                </div>
                <p
                  class="help is-danger"
                  v-if="errorDeliverAtDateTime"
                  v-bind:style="{visibility: errorDeliverAtDateTime ? 'visible' : 'hidden',}"
                >
                  Required
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
</template>

<script>
var timeFunctions = require("../../js/timeFunctions.js");
var addressTextbox = require("../address_textbox").default;

module.exports = {
    components: {
        addressTextbox,
    },
    created: function () {
        if (this.currentPanel === "newOrder") {
            let self = this;
            setTimeout(() => {
                self.$refs.newOrderNumber.focus();
            });
        }
    },
    data: function () {
        return {
            validationErrorOrderNumber: false,
            validationErrorEntryDateTime: false,
            validationErrorDeliverAtDateTime: false,
            validationErrorName: false,
            validationErrorAddress: false,
            validationErrorPayment: false,
            validationErrorTotal: false,
            showDate: false,
            is_medium: false,
            is_large: false,
            hasValidationErrors: false
        };
    },
    watch: {
        is_medium(newValue) {
            if (newValue) {
                this.is_large = false;
            }
        },
        is_large(newValue) {
            if (newValue) {
                this.is_medium = false;
            }
        }
    },
    mounted() {
        const tgt = this.getTgt()
        const entryDateTime = new Date(this.newOrder.entry_datetime);
        entryDateTime.setMinutes(entryDateTime.getMinutes() + tgt + 10);
        const deliver_by_date = [
            entryDateTime.getFullYear(),
            ('0' + (entryDateTime.getMonth() + 1)).slice(-2),
            ('0' + entryDateTime.getDate()).slice(-2)
        ].join('-');

        const deliver_by_time = `${entryDateTime.getHours().toString().padStart(2, '0')}:${entryDateTime.getMinutes().toString().padStart(2, '0')}`;
        this.newOrder.deliver_by_datetime = `${deliver_by_date}T${deliver_by_time}`;
    },
    computed: {
        settings: function() {
            return this.$store.getters["settings"];
        },
        newOrder() {
            var store = this.$store.state.orders.store;
            var newOrder = this.$store.state.orders.newOrder;
            return newOrder;
        },
        newOrderError() {
            return this.$store.state.orders.newOrderError;
        },
        formattedPhone() {
            var cleaned = ("" + this.newOrder.phone).replace(/\D/g, "");
            var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
            if (match) {
                return "(" + match[1] + ") " + match[2] + "-" + match[3];
            }
            return this.newOrder.phone;
        },
        errorOrderNumber() {
            return this.validationErrorOrderNumber;
        },
        errorEntryDateTime() {
            return this.validationErrorEntryDateTime;
        },
        errorDeliverAtDateTime() {
            return this.validationErrorDeliverAtDateTime;
        },
        errorName() {
            return this.validationErrorName;
        },
        errorAddress() {
            return this.validationErrorAddress;
        },
        errorPayment() {
            return this.validationErrorPayment;
        },
        errorTotal() {
            return this.validationErrorTotal;
        },
        currentPanel() {
            return this.$store.state.app.currentPanel;
        },
        allOrders() {
            return this.$store.state.orders.all;
        }
    },
    methods: {
        setPanel: function (panel) {
            this.$store.dispatch("app/setPanel", panel);
        },
        toggleNewOrder: function () {
            this.validationErrorOrderNumber = false;
            this.validationErrorEntryDateTime = false;
            this.validationErrorDeliverAtDateTime = false;
            this.validationErrorName = false;
            this.validationErrorAddress = false;
            this.validationErrorPayment = false;
            this.validationErrorTotal = false;

            this.setPanel("order");
            this.$store.dispatch("orders/clearNewOrder");
        },
        getTgt: function () {
            const radiusDriveTimes = this.settings.radius_drive_times;
            if(!radiusDriveTimes) return

            const parts = radiusDriveTimes.split("|");
            const minutes = parts[0].split(";");

            const tgt = parseInt(minutes[0]);

            return tgt;
        },
        getDateAndTime(datetime) {
            const [date, time] = datetime.split('T');
            return { date, time };
        },
        getOrderToSave: function() {
            if(!this.newOrder.is_scheduled) {
                const { date, time } = this.getDateAndTime(this.newOrder.entry_datetime);
                return {
                    ...this.newOrder,
                    entry_date: date,
                    entry_time: time
                }
            }
            const tgt = this.getTgt();
            const { date, time } = this.getDateAndTime(this.newOrder.deliver_datetime);
            let dateTime = new Date(this.newOrder.deliver_datetime);
            dateTime.setMinutes(dateTime.getMinutes() - parseInt(tgt));
            let newEntryDate = timeFunctions.formatDate(dateTime);
            let newEntryTime = dateTime.toTimeString().slice(0, 5);
            return {
                ...this.newOrder,
                entry_date: newEntryDate,
                entry_time: newEntryTime,
                deliver_time: time,
                deliver_date: date,
            }
        },
        saveOrder: function () {
            this.validationErrorOrderNumber = false;
            this.validationErrorEntryDateTime = false;
            this.validationErrorDeliverAtDateTime = false;
            this.validationErrorName = false;
            this.validationErrorAddress = false;
            this.validationErrorPayment = false;
            this.validationErrorTotal = false;
            this.hasValidationErrors = false;

            if (this.newOrder.orderNum) {
                this.allOrders.forEach(o => {
                    if (o.order_number === parseInt(this.newOrder.orderNum)) this.validationErrorOrderNumber = true;
                })
            }
            if (!this.newOrder.entry_datetime) {
                this.validationErrorEntryDateTime = true;
            }
            if(!this.newOrder.deliver_datetime && this.newOrder.is_scheduled) {
                this.validationErrorDeliverAtDateTime = true;
            }
            if (!this.newOrder.name) {
                this.validationErrorName = true;
            }
            if (!this.newOrder.address) {
                this.validationErrorAddress = true;
            }
            if (!this.newOrder.payment) {
                this.validationErrorPayment = true;
            }
            if (!this.newOrder.total) {
                //this.validationErrorTotal = true;
            }

            if (
                this.validationErrorOrderNumber ||
                this.validationErrorEntryDateTime ||
                this.validationErrorDeliverAtDateTime ||
                this.validationErrorName ||
                this.validationErrorAddress ||
                this.validationErrorPayment ||
                this.validationErrorTotal
            ) {
                this.hasValidationErrors = true;
                return false;
            }

            const orderToSave = this.getOrderToSave();

            this.$store.dispatch("orders/saveNewOrder", orderToSave);
        },
    },
};
</script>

<style scoped>
.newOrderFormattedPhone {
    font-weight: 900;
    font-size: 1.3em;
}

.newOrderFormattedPhoneLabel {
    margin-bottom: 0.25em !important;
}

.change-date-button {
    font-size: 0.6rem !important;
}

.double-field {
    margin-bottom: .5em !important;
}

.no-arrows{
    -webkit-appearance: none;
    -moz-appearance: textfield;
}
.field.flex{
    display: flex;
    gap: 1em;
    align-items: center;
}
.top-reduce-height{
    height:6em;
}

.order-number-input{
    width: 150px;
}

.placeholder {
    color: #dbdbdb;
}

.is-align-items-center {
    align-items: center;
}

.mr-3{
    margin-right: 3em;
}

.mr-2 {
    margin-right: 2em;
}

.column {
    padding: .75rem !important;
}

.toggle-container {
    display: flex;
    align-items: center;
    gap: 10px;
}

.scheduled-label {
    padding: 5px 15px;
    border-radius: 20px;
    font-size: .7rem;
    font-weight: bold;
    height: 34px;
    align-items: center;
    display: flex
}

.card {
  padding-top: .9em !important;
}

.ml-1 {
    margin-left: 1em;
}
</style>
