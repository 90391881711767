<template>
    <div class="container">
        <div class="columns">
            <div class="column">
                <div class="add-charge-container">
                    <div class="card-content">

                        <template v-if="paymentMethods.length === 0 && !isFetchingPaymentMethods">
                            <div class="no-payment-methods">
                                No saved payment methods associated with your account. Please go to the cards screen and add
                                at least one payment method.
                            </div>
                        </template>

                        <template v-else-if="isFetchingPaymentMethods">
                            <div class="loader"></div>
                        </template>

                        <template v-else>
                            <div v-if="paymentSuccessful" class="success-payment">Payment successful!</div>
                            <div v-if="paymentInvalid" class="invalid-payment">Invalid Payment! Payment amount must be
                                greater
                                than 25$</div>
                            <h4 class="title is-4">Add Credits</h4>
                            <div class="field is-horizontal">
                                <div class="field-label is-normal">
                                    <label class="label">Payment Method</label>
                                </div>
                                <div class="field-body">
                                    <div class="field is-narrow">
                                        <div class="control">
                                            <!-- <div v-if="paymentMethods.length === 0" class="loader-container">
                                            <div class="loader"></div>
                                        </div> -->
                                            <div class="select">
                                                <select v-model="selectedPaymentMethod.paymentMethodId">
                                                    <option v-for="(method, index) in paymentMethods"
                                                        :value="method.paymentMethodId" :key='index'>
                                                        {{ capitalizeFirstLetter(method.cardBrand) }} {{
                                                            cardNumber(method.last4Numbers) }}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="field is-horizontal">
                                <div class="field-label is-normal">
                                    <label class="label">Payment Amount ($)</label>
                                </div>
                                <div class="field-body">
                                    <div class="field is-narrow">
                                        <div class="control">
                                            <input type="number" class="input" v-model="paymentAmount" min="25"
                                                placeholder="Payment Amount">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="field is-horizontal">
                                <div class="field-label"></div>
                                <div class="field-body">
                                    <div class="field">
                                        <div class="control">
                                            <button @click="confirmPayment" class="button is-primary"
                                                :disabled="submittingPayment">
                                                <span v-if="submittingPayment">Submitting...</span>
                                                <span v-else>Submit Payment</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
var timeFunctions = require("../../../js/timeFunctions.js");

module.exports = {
    data: function () {
        return {
            api_url: this.$store.state.api_url,
            store_id: this.$store.state.user.store_id,
            paymentAmount: 25,
            stripe: null,
            cardNumberElement: null,
            expiryElement: null,
            cvcElement: null,
            selectedPaymentMethod: {},
            paymentMethods: [],
            paymentSuccessful: false,
            transactions: [],
            selectedDate: '',
            submittingPayment: false,
            paymentInvalid: false,
            isFetchingPaymentMethods: false
        };
    },
    mounted() {
        this.fetchPaymentMethods();
        this.selectedDate = this.getCurrentMonth();
    },
    computed: {
        storeInfo: function () {
            return this.$store.getters["orders/getStore"]
        }
    },
    methods: {
        formatDateTime: (value, withTime) => timeFunctions.formatDateTime(value, withTime),
        fetchPaymentMethods() {
            this.isFetchingPaymentMethods = true;
            this.$http
                .get(this.api_url + `Api/V1/Payment/Stripe/GetPaymentMethods/${this.storeInfo.stripeCustomerId}`)
                .then((response) => {
                    this.isFetchingPaymentMethods = false;
                    this.paymentMethods = response.body;
                    const defaultPaymentMethod = this.paymentMethods.find((method) => method.isDefaultPaymentMethod);
                    if (defaultPaymentMethod) {
                        this.selectedPaymentMethod = defaultPaymentMethod;
                    }
                })
                .catch((error) => {
                    this.isFetchingPaymentMethods = false;
                    console.error('Error fetching payment methods:', error);
                });
        },
        confirmPayment() {
            this.$confirm("You are about to submit a payment. Confirm to proceed!").then(() => {
                this.submitPayment()
            });
        },
        submitPayment() {
            if (this.paymentAmount < 25) {
                this.paymentInvalid = true;
                setTimeout(() => {
                    this.paymentInvalid = false;
                }, 5000);
                return;
            }
            this.submittingPayment = true;
            const payload = {
                amount: this.paymentAmount,
                currency: 'usd',
                description: 'Credit',
                paymentMethodId: this.selectedPaymentMethod.paymentMethodId,
                storeId: this.store_id,
            };

            this.$http
                .post(this.api_url + 'Api/V1/Payment/Stripe/CreatePaymentIntent', payload)
                .then((response) => {
                    if (response.ok) {
                        this.submittingPayment = false;
                        this.paymentSuccessful = true;
                        this.paymentAmount = 25;
                        this.$store.dispatch("orders/getStore");
                        setTimeout(() => {
                            this.paymentSuccessful = false;
                        }, 5000);
                    }
                })
                .catch((error) => {
                    this.submittingPayment = false;
                    console.log('Failed to complete add charge:', error);
                });
        },
        capitalizeFirstLetter(value) {
            return value.charAt(0).toUpperCase() + value.slice(1);
        },
        cardNumber(last4) {
            return last4;
        },
        getCurrentMonth() {
            const now = new Date();
            const year = now.getFullYear();
            const month = (now.getMonth() + 1).toString().padStart(2, '0');
            return `${year}-${month}`;
        },
    },
};
</script>
  
<style scoped>
.columns {
    margin-top: 1.5rem;
}

.success-payment {
    margin-top: 1rem;
    background-color: #37c936;
    color: #fff;
    padding: 1rem;
    border-radius: 6px 6px 6px 6px;
}

.invalid-payment {
    margin-top: 1rem;
    background-color: #f14668;
    color: #fff;
    padding: 1rem;
    border-radius: 6px 6px 6px 6px;
}

.add-charge-container {
    border: 0.5px solid lightgray;
    border-radius: 6px 6px 6px 6px;
}

.transactions-container {
    margin-top: 1.5rem;
}

.date-picker-container {
    margin-bottom: 1.5rem;
    float: right;
}

input[type="month"] {
    padding: 0.3rem;
}

/* Loader styles */
.loader-container {
    display: flex;
}

.loader {
    margin: 0 auto;
    border: 4px solid #f3f3f3;
    border-top: 4px solid #3273DC;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
</style>
  