<template>
<div class="section">
    <div class="container">
        <div class="card">
            <div class="card-content">
                <div class="hero">
                    <div class="hero-body">
                        <img class="img" src="./../../public/img/Ontime-logo.png"/>
                        <h1 class="title has-text-centered">Login</h1>
                    </div>
                </div>
                <p class="success-text" v-if="successResetPassword">Your password has been successfully updated</p>
                <div class="field">
                    <label class="label">Email</label>
                     <p class="control has-icons-left">
                        <input class="input" type="text" placeholder="email@company.com" v-model="email" />
                          <span class="icon is-small is-left">
                            <i class="fa fa-envelope"></i>
                        </span>
                    </p>
                    <p class="help is-danger" v-bind:style="{
                visibility: emptyEmail ? 'visible' : 'hidden',
              }">
                        Required
                    </p>
                </div>
                <div class="field">
                    <label class="label">Password</label>
                    <p class="control has-icons-left">
                        <input class="input" type="password" v-model="password" placeholder="Password" />
                        <span class="icon is-small is-left">
                            <i class="fa fa-lock"></i>
                        </span>
                    </p>
                    <p class="help is-danger" v-bind:style="{ visibility: emptyPassword ? 'visible' : 'hidden' }">
                        Required
                    </p>
                </div>
                <p v-if="showPasswordError" class="help is-danger" v-bind:style="{
              visibility: showPasswordError ? 'visible' : 'hidden',
            }">
                    Email or password is incorrect.
                </p>
                <p class="help is-danger" v-if="isServerSideError">{{serverSideErrorMessage}}</p>
                <p v-if="errors.length">
                    <b>Please correct the following error(s):</b>
                    <ul>
                        <li class="help is-danger" v-for="error in errors">{{ error }}</li>
                    </ul>
                </p>
                <div class="reset-password-text">
                    <a v-on:click="startResetPassword">Forgot password?</a>
                </div>
                <div class="field">
                    <button class="button is-medium is-responsive custombtn is-fullwidth" v-on:click="login">
                        Login
                    </button>
                </div>
                <div class="have-account-text signin">
                    Don't have an account?
                    <a data-disable-with="<i class=&quot;spinner spinner-grey&quot;></i>" v-on:click="startSignup">Sign Up</a>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
require("../js/config.js");
var modal = require("./modal.vue").default;
module.exports = {
    components: {
        modal
    },
    data: function () {
        return {
            email: "",
            password: "",
            passwordError: false,
            emptyEmailValidation: false,
            emptyPasswordValidation: false,
            errors: [],
        };
    },
    computed: {
        showPasswordError() {
            return this.$store.state.user.passwordError;
        },
        isServerSideError() {
            return this.$store.state.user.isServerSideError;
        },
        serverSideErrorMessage() {
            return this.$store.state.user.serverSideErrorMessage;
        },
        emptyEmail() {
            return this.emptyEmailValidation;
        },
        emptyPassword() {
            return this.emptyPasswordValidation;
        },
        successResetPassword() {
            return this.$store.state.user.successResetPassword;
        },
    },
    methods: {
        login: function () {
            this.errors = [];
            this.emptyEmailValidation = false;
            this.emptyPasswordValidation = false;
            this.validEmailValidation = false;
            this.validPasswordValidation = false;

            //email validation
            if (!this.email) {
                this.emptyEmailValidation = true;
            } else if (!this.isEmailValid(this.email)) {
                this.errors.push('Must be a valid email.');
                this.validEmailValidation = true;
            }

            //paassword validation
            if (!this.password) {
                this.emptyPasswordValidation = true;
            } else if (!this.isPasswordValid(this.password)) {
                this.errors.push(
                    'Password must be between 6 and 20 characters and contain one uppercase letter, one lowercase letter, one digit and one special character.'
                );
                this.validPasswordValidation = true;
            }

            if (!(this.emptyEmailValidation || this.emptyPasswordValidation || this.validEmailValidation || this.validPasswordValidation)) {
                // create user model
                var user = {
                    email: this.email,
                    password: this.password
                };

                this.$store.dispatch("user/login", user);
            }

        },
        startSignup: function () {
            this.$store.dispatch("user/startSignup");
        },
        startResetPassword: function () {
            this.$store.dispatch("user/startResetPasswordStep1");
        },
        //is email valid
        isEmailValid: function (email) {
            var re =
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(email);
        },

        //is password valid
        isPasswordValid: function (password) {
            var re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{8,15}$/;
            return re.test(password);
        },
    },
};
</script>

<style scoped>
body {
    -webkit-font-smoothing: antialiased !important;
    font-family: "Poppins", Arial, sans-serif !important;
}

.card {
    /* background-color: #fff;
      box-shadow: 0 1.5em 7em 7.875em rgb(10 10 10 / 10%), 0 0 0 1px rgb(10 10 10 / 2%) !important; */
    padding: 1rem 1rem 1.25rem;
    border: 0.0625rem solid #E3E3E3;
    box-shadow: 0 1.3125rem 5.9375rem #0000001a;
    border-radius: 0.8125rem;
    width: 50rem;
    max-width: 100%;
}

.title {
    text-align: center;
    font-weight: 500;
    font-size: 2rem !important;
}

.sub-title {
    display: block;
    font-weight: 600;
    font-size: .875rem !important;
    line-height: 1.125rem;
}

.custombtn {
    background-color: #37c936 !important;
    color: #274456 !important
}

.gdpr-text {
    text-align: center;
    color: #8e8e8e;
    font-size: 1rem;
    line-height: 1.25rem;
    font-weight: 600;
    margin: 1.5rem 0;
}

.sign-up .have-account-text {
    font-size: 1.125rem;
    font-weight: 600;
    line-height: 1.5rem;
    text-align: center;
    color: #252525;
}

.signin {
    text-align: center;
    margin-top: 30px;
}

.section {
    background-color: #fff;
    height: 100vh;
    padding: 15vh;
}

.container {
    width: 30vw;
}

.hero-body {
    padding: 24px;
}

.required-span {
    color: red;
    font-weight: 700;
}

.hero-body {
    padding: 24px;
}

.field {
    margin-bottom: -0.25rem !important;
}

.report-header {
    width: 100%;
}

.privacy-font {
    font-size: 13px;
}

.content-position {
    margin-top: 5rem;
}
.img{
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 60%;
}
.title-color {
    color: white;
}

.reset-password-text{
    text-align: right;
    margin-bottom: 1rem;
}

.success-text{
    color: #37c936;
    text-align: center;
    margin-bottom: 1rem;
}
</style>
