// initial state
import Vue from 'vue';
import VueResource from 'vue-resource';
import custom from '../../js/custom';

Vue.use(VueResource);

const state = {
  store_id: null,
  username: '',
  authenticated: false,
  isEmailconfirm: false,
  signup: false,
  resetPasswordStep1: false,
  resetPasswordStep2: false,
  successResetPassword: false,
  unique_store_id: null,
  passwordError: false,
  isServerSideError: false,
  serverSideErrorMessage: '',
  successUpdatePassword: false,
  successUpdatePhone: false,
  email: '',
  phone: ''
}

// getters
const getters = {
  getBaseURL(state, getters, rootState) {
    return rootState.base_url;
  },
  getStoreKey(state) {
    return state.unique_store_id;
  },
  getApiUrl(state, getters, rootState) {
    return rootState.api_url;
  },
  getEmail(state, getters, rootState){
    return state.email;
  },
  getPhone(state){
    return state.phone;
  }
}

// actions
const actions = {
  login({
    commit,
    getters,
    dispatch
  }, user) {
    const api_url = getters.getApiUrl;
    Vue.http.post(api_url + 'Api/V1/Users/login/', user).then(res => {
      commit("updateEmail", res.body.email);
      localStorage.setItem('accessToken', res.body.token);
      //get store by user id
      Vue.http.get(api_url + 'Api/V1/Stores/GetByUserId?userId=' + res.body.userGuid).then(response => {
        if(!response.body.address){
          dispatch("app/lockOnPanel", 'settingsPanel', {
            root: true
          });
        }
        var expirationDate = new Date();
        expirationDate.setDate(expirationDate.getDate() + 5);
        custom.createCookie('sid', 'ONTIMESESSION', "", "", expirationDate.toUTCString())
        commit('login');
        commit('updateUser', response.body.userId);
        commit('changeStoreID', response.body.storeId);
        commit('updatePhone', response.body.phone);
        commit('changeUniqueStoreID', response.body.storeUniqueIdentifier);
      }, res => {
        commit('loginError');
      });
    }, res => {
      // to update
      console.log('failed authentication', res)
      commit('serverSideError', res);
    });
  },
  tokenLogin({
    commit,
    getters
  }, token) {
    const api_url = getters.getApiUrl;
    Vue.http.post(api_url + 'Api/V1/Users/TokenLogin/', token).then(res => {
      commit("updateEmail", res.body.email);
      localStorage.setItem('accessToken', res.body.token);
      //get store by user id
      Vue.http.get(api_url + 'Api/V1/Stores/GetByUserId?userId=' + res.body.userGuid).then(response => {
        var expirationDate = new Date();
        expirationDate.setDate(expirationDate.getDate() + 5);
        custom.createCookie('sid', 'ONTIMESESSION', "", "", expirationDate.toUTCString())
        commit('login');
        commit('updateUser', response.body.userId);
        commit('changeStoreID', response.body.storeId);
        commit('updatePhone', response.body.phone);
        commit('changeUniqueStoreID', response.body.storeUniqueIdentifier);
      }, res => {
        commit('loginError');
      });
    }, res => {
      // to update
      console.log('failed authentication', res)
      commit('serverSideError', res);
    });
  },
  sendOtpByMail({
    commit,
    getters
  }, mail) {
    const api_url = getters.getApiUrl;
    Vue.http.post(api_url + 'Api/V1/Users/SendOtpByMail/' + mail).then(res => {
      commit("startResetPasswordStep2");
      commit('serverSideError', null);
    }, res => {
      commit('serverSideError', res);
    });
  },

  sendOtpByMailFromSettings({
    commit,
    getters
  }, mail) {
    const api_url = getters.getApiUrl;
    Vue.http.post(api_url + 'Api/V1/Users/SendOtpByMail/' + mail).then(res => {
      commit('serverSideError', null);
    }, res => {
      commit('serverSideError', res);
    });
  },

  resetPassword({
    commit,
    getters
  }, model) {
    const api_url = getters.getApiUrl;
    Vue.http.post(api_url + 'Api/V1/Users/ResetPassword/', model).then(res => {
      commit('successResetPassword');
    }, res => {
      commit('serverSideError', res);
    });
  },

  resetPasswordFromSettings({
    commit,
    getters
  }, model) {
    const api_url = getters.getApiUrl;
    Vue.http.post(api_url + 'Api/V1/Users/ResetPassword/', model).then(res => {
      commit('successResetPasswordFromSettings');
    }, res => {
      commit('serverSideError', res);
    });
  },

  updatePassword({
    commit,
    getters
  }, model) {
    const api_url = getters.getApiUrl;
    Vue.http.post(api_url + 'Api/V1/Users/UpdatePassword/', model).then(res => {
      commit('successUpdatePassword', true);
    }, res => {
      commit('successUpdatePassword', false);
      commit('serverSideError', res);
    });
  },

  updatePhone({
    commit,
    getters
  }, model) {
    const api_url = getters.getApiUrl;
    Vue.http.post(api_url + 'Api/V1/Users/UpdatePhone/', model).then(res => {
      commit('updatePhone', model.newPhoneNumber);
      commit('successUpdatePhone', true);
    }, res => {
      commit('successUpdatePhone', false);
      commit('serverSideError', res);
    });
  },

  //verify Email
  verifyEmail({
    commit,
    getters
  }, payload) {
    const api_url = getters.getApiUrl;
    Vue.http.post(api_url + 'Api/V1/Users/ConfirmEmail?' + window.location.href.split("?")[1]).then(res => {
      window.location.href = window.location.origin;
    }, res => {
      // to update
      console.log('failed authentication', res)
      commit('loginError');
    });
  },

  logout({
    commit
  }) {
    commit('logout');
  },

  navigateHome({
    commit
  }) {
    commit('navigateHome');
  },

  startSignup({
    commit
  }) {
    commit('startSignup');
  },

  startResetPasswordStep1({
    commit
  }) {
    commit('startResetPasswordStep1');
  },

  startResetPasswordStep2({
    commit
  }) {
    commit('startResetPasswordStep2');
  },

  cancelResetPassword({
    commit
  }) {
    commit('cancelResetPassword');
  },

  resetUpdatePassword({
    commit
  }) {
    commit('resetUpdatePassword');
  },

  cancelSignup({
    commit
  }) {
    commit('cancelSignup');
  },
  signup({
    commit,
    getters,
    dispatch
  }, signupInfo) {
    commit('clearLoginError');
    const api_url = getters.getApiUrl;
    Vue.http.post(api_url + 'Api/V1/Users/RegisterStore/', signupInfo).then(res => {
      // after succesfully signing up lock the user on the settings page, to fill out all the required information
      dispatch("app/lockOnPanel", 'settingsPanel', {
        root: true
      });
      commit("updateEmail", res.body.email);

      //get store by user id
      Vue.http.get(api_url + 'Api/V1/Stores/GetByUserId?userId=' + res.body.userGuid).then(response => {
        var expirationDate = new Date();
        expirationDate.setDate(expirationDate.getDate() + 5);
        custom.createCookie('sid', 'ONTIMESESSION', "", "", expirationDate.toUTCString())
        commit('login');
        commit('updateUser', response.body.userId);
        commit('updatePhone', response.body.phone);
        commit('changeStoreID', response.body.storeId);
        commit('changeUniqueStoreID', response.body.storeUniqueIdentifier);
        localStorage.setItem('accessToken', res.body.token);
      }, res => {
        commit('loginError');
      });
    }, res => {
      // to update
      console.log('failed authentication', res)
      commit('serverSideError', res);
    });
  },

  updateUser({
    commit
  }, username) {
    commit('updateUser', username);
  },

  checkLSforUser({
    commit
  }) {
    var username = localStorage.getItem('username');
    var storeId = localStorage.getItem('storeId');
    var email = localStorage.getItem("email");
    let phone = localStorage.getItem("phone");
    var storeUniqueIdentifier = localStorage.getItem('storeUniqueIdentifier');
    if (username && storeId && storeUniqueIdentifier && email) {
      commit('updateUser', username);
      commit('changeStoreID', storeId);
      commit('updateEmail', email);
      commit('changeUniqueStoreID', storeUniqueIdentifier);
    }
    if(phone){
      commit('updatePhone', phone);
    }
  },

  // May Not Be Secure! Check!
  cookieLogin({
    commit
  }) {
    commit('login');
  },

  updateOrganization({
    getters,
    dispatch
  }, organization) {
    const payload = {
      email: organization.email,
      name: organization.name
    }
    Vue.http.post(getters.getApiUrl + "Api/V1/Users/UpdateOrganization/", payload)
      .then(
        (res) => {
          console.log("Organization Updated Succesfully")
        },
        (res) => {
          console.log("Failed to update organization...", res.body);
        }
      );
  },
}

// mutations
const mutations = {

  login(state) {
    state.authenticated = true;
    state.signup = false;
    state.passwordError = false;
  },

  logout(state) {
    state.authenticated = false;
    state.signup = false;
    custom.deleteCookie('sid');
    state.username = '';
    state.passwordError = false;
    state.isServerSideError = false;
    localStorage.removeItem('username');
    localStorage.removeItem('storeId');
    localStorage.removeItem('storeUniqueIdentifier');
  },

  navigateHome(state) {
    var currentUrl = new URL(window.location.href);
    window.location.assign(currentUrl.origin)
  },

  startSignup(state) {
    state.authenticated = false;
    state.signup = true;
    state.passwordError = false;
    state.isServerSideError = false;
  },

  successUpdatePassword(state, successUpdatePassword){
    state.successUpdatePassword = successUpdatePassword;
    state.isServerSideError = false;
  },

  successUpdatePhone(state, successUpdatePhone){
    state.successUpdatePhone = successUpdatePhone;
    state.isServerSideError = false;
  },

  successResetPassword(state){
    state.authenticated = false;
    state.resetPasswordStep1 = false;
    state.resetPasswordStep2 = false;
    state.passwordError = false;
    state.isServerSideError = false;
    state.successResetPassword = true;
  },

  successResetPasswordFromSettings(state){
    state.successResetPassword = true;
  },

  startResetPasswordStep1(state) {
    state.authenticated = false;
    state.resetPasswordStep1 = true;
    state.passwordError = false;
    state.isServerSideError = false;
  },

  startResetPasswordStep2(state){
    state.authenticated = false;
    state.resetPasswordStep1 = false;
    state.resetPasswordStep2 = true;
    state.passwordError = false;
    state.isServerSideError = false;
  },

  cancelResetPassword(state) {
    state.authenticated = false;
    state.resetPasswordStep1 = false;
    state.resetPasswordStep2 = false;
    state.passwordError = false;
    state.isServerSideError = false;
  },

  cancelSignup(state) {
    state.authenticated = false;
    state.signup = false;
    state.passwordError = false;
    state.isServerSideError = false;
  },

  updateUser(state, username) {
    state.username = username;
    localStorage.setItem('username', username);
  },

  updatePhone(state, phone) {
    state.phone = phone;
    localStorage.setItem('phone', phone);
  },

  updateEmail(state, email) {
    state.email = email;
    localStorage.setItem('email', email);
  },

  resetUpdatePassword(state){
    state.successUpdatePassword = false;
    state.isServerSideError = false;
  },

  changeStoreID(state, storeId) {
    state.store_id = storeId;
    localStorage.setItem('storeId', storeId);
  },

  changeUniqueStoreID(state, storeUniqueIdentifier) {
    state.unique_store_id = storeUniqueIdentifier;
    localStorage.setItem('storeUniqueIdentifier', storeUniqueIdentifier);
  },

  loginError(state) {
    state.passwordError = true;
  },

  clearLoginError(state) {
    state.passwordError = false;
  },

  serverSideError(state, response) {
    if (response != null && response.body != null) {
      state.isServerSideError = true;
      state.serverSideErrorMessage = response.body;
    }else{
      state.isServerSideError = false;
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}