<template>
  <nav class="is-grouped is-fullwidth level" :class="{ 'footer-custom': currentPanel != 'map' }">

    <div class="center-content is-grouped">

      <a
      v-bind:class="{'is-link': currentPanel == 'fullscreen'}"
      class="
        button
        is-smaller
        header-btn header-btn-left
        has-text-weight-medium
      "
      v-on:click="$event => toggleFullscreen()"
      >
        <i class="fa fa fa-duotone fa-expand"></i>
        Fullscreen
      </a>

      <a
        class="
          button
          is-small
          header-btn header-btn-left
          has-text-weight-medium
        "
        :class="currentPanel == 'map' ? 'is-link' : ''"
        :disabled="currentPanel == 'map'"
        v-on:click="setPanel('map')"
      >
        <i class="fa fa-map-marker"></i>
        DISPATCH
      </a>
      <a
        class="
          button
          is-small
          header-btn header-btn-left
          has-text-weight-medium
        "
        :class="currentPanel == 'order' ? 'is-link' : ''"
        :disabled="currentPanel == 'order'"
        v-on:click="setPanel('order')"
      >
        <i class="fa fa-truck"></i>
        ORDERS
      </a>
    </div>
    
  
  <div class="no-effect">

    <div>
      <a
        v-bind:class="{ 'is-link': currentPanel == 'dialpad' }"
        class="
          button
          is-small is-primary-button
          header-btn header-btn-left
          has-text-weight-medium
        "
        v-on:click="setPanel('dialpad')"
      >
        <i class="fa fa-plus"></i>New
      </a>
      <a
        class="
          button
          is-small is-link-button
          header-btn header-btn-left
          has-text-weight-medium
        "
        :disabled="selectedOrders.length == 0 && !selectedDriver"
        v-on:click="clearSelections()"
      >
        <i class="fa fa-mouse-pointer"></i>Clear
      </a>
      <a
        class="
          button
          is-small is-warning-button
          header-btn header-btn-left
          has-text-weight-medium
        "
        :disabled="selectedOrders.length != 1"
        v-on:click="
          selectedOrders.length == 1 && toggleEditOrderModal(selectedOrders[0])
        "
      >
        <i class="fa fa-pencil"></i>Edit
      </a>
      <a
        class="
          button
          is-small is-danger-button
          header-btn header-btn-left
          has-text-weight-medium
        "
        :disabled="selectedOrders.length != 1"
        v-on:click="
          selectedOrders.length == 1 &&
            toggleDeleteOrderModal(selectedOrders[0])
        "
      >
        <i class="fa fa-trash"></i>
        Delete
      </a>
    </div>

    <div class="is-grouped eta-quick">
      <!-- First ETA Section -->
      <div :class="selectedEta === 1 ? 'selected' : ''" @click="selectEta(1)">
        <div class="left-eta"> &lt;{{ miles1 }}</div>
        <div>{{ minutes1 }}</div>
      </div>

      <!-- Second ETA Section -->
      <div :class="selectedEta === 2 ? 'selected' : ''" @click="selectEta(2)">
        <div class="left-eta">{{ miles1 +"-"+ miles2 }}</div>
        <div>{{ minutes2 }}</div>
      </div>

      <!-- Third ETA Section -->
      <div :class="selectedEta === 3 ? 'selected' : ''" @click="selectEta(3)">
        <div class="left-eta">{{ miles2 }}+</div>
        <div>{{ minutes3 }}</div>
      </div>

      <!-- Control Buttons -->
      <div>
        <a
          class="button is-small is-link header-btn header-btn-left has-text-weight-medium"
          :class="{ 'is-disabled': !selectedEta }"
          @click="changeMinutes(-5)"
        >
          <i class="fa fa-minus"></i>
        </a>
        <a
          class="button is-small is-link header-btn header-btn-left has-text-weight-medium"
          :class="{ 'is-disabled': !selectedEta }"
          @click="changeMinutes(5)"
        >
          <i class="fa fa-plus"></i>
        </a>
      </div>
    </div>

    <div>
      <a
        v-bind:class="{ 'is-link': currentPanel == 'dayPanel' }"
        class="
          button
          is-small
          header-btn header-btn-left
          has-text-weight-medium
        "
        v-on:click="setPanel('dayPanel')"
      >
        <i class="fa fa-calendar"></i>
        Today
      </a>
      <a
        v-bind:class="{ 'is-link': currentPanel == 'accountPanel' }"
        class="
          button
          is-small
          header-btn header-btn-left
          has-text-weight-medium
        "
        v-on:click="setPanel('accountPanel')"
      >
        <i class="fa fa-money"></i>
        Reports
      </a>
      <a
        v-bind:class="{ 'is-link': currentPanel == 'driverInfoPanel' }"
        class="
          button
          is-small
          header-btn header-btn-left
          has-text-weight-medium
        "
        v-on:click="setPanel('driverInfoPanel')"
      >
        <i class="fa fa-car"></i>
        Drivers
      </a>
      <a
        v-bind:class="{ 'is-link': currentPanel == 'settingsPanel' }"
        class="
          button
          is-small
          header-btn header-btn-left
          has-text-weight-medium
        "
        v-on:click="setPanel('settingsPanel')"
      >
        <i class="fa fa fa-cog"></i>
        Settings
      </a>

      <div  class="center-content is-grouped">
        <span class="time">
          {{ currentDate.toLocaleTimeString("en-US", {timeStyle: 'short', }).trimEnd() }}
        </span>
      </div>

    </div>
  </div>
  </nav>
</template>
<script>
var routes = require("./routes.vue").default;
const custom = require("../../../js/custom.js");

module.exports = {
  components: {
    routes,
  },
  data: function () {
    return {
      base_url: this.$store.state.base_url,
      store_id: this.$store.state.user.store_id,
      minutes1: 0,
      minutes2: 0,
      minutes3: 0,
      miles1: 0,
      miles2: 0,
      isTabletMode: false,
      isInFullscreenMode: false,
      interval: null,
      currentDate: new Date(Date.now()),
      isUpdatingSettings: false,
      selectedEta: null
    };
  },
  created: function () {
    // Initialize the connection and get settings
    this.connectAndFetchSettings();

    // Set up a periodic task to update settings and process drive times
    this.setupUpdateInterval();
  },
  mounted() {
    this.checkTabletMode();
    window.addEventListener('resize', this.checkTabletMode());
    document.addEventListener("fullscreenchange", this.checkFullscreenMode);
    document.addEventListener("mozfullscreenchange", this.checkFullscreenMode);
    document.addEventListener("webkitfullscreenchange", this.checkFullscreenMode);
    document.addEventListener("msfullscreenchange", this.checkFullscreenMode);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.checkTabletMode());
  },
  beforeDestroy() {
    document.removeEventListener("fullscreenchange", this.checkFullscreenMode);
    document.removeEventListener("mozfullscreenchange", this.checkFullscreenMode);
    document.removeEventListener("webkitfullscreenchange", this.checkFullscreenMode);
    document.removeEventListener("msfullscreenchange", this.checkFullscreenMode);
    clearInterval(this.interval);
  },
  computed: {
    connection() {
      return this.$store.state.connection;
    },
    selectedOrders: function () {
      return this.$store.getters["orders/selectedOrders"];
    },
    selectedDriver: function () {
      return this.$store.getters["drivers/selectedDriver"];
    },
    currentPanel: function () {
      return this.$store.getters["app/getCurrentPanel"];
    },
    settings: function() {
      return this.$store.getters["settings"];
    },
    storeInfo: function () {
      return this.$store.getters["orders/getStore"]
    },
    expandLevel: function() {
      return this.$store.getters["app/getExpandLevel"]
    },
  },
  watch: {
    settings: {
      handler: 'processDriveTimesAndDate',
      deep: true
    }
  },
  methods: {
    selectEta(section) {
      this.selectedEta = section;  // Set selected section
    },
    changeMinutes(amount) {
      // Adjust minutes based on which section is selected
      if (this.selectedEta === 1) {
        this.minutes1 += amount;
      } else if (this.selectedEta === 2) {
        this.minutes2 += amount;
      } else if (this.selectedEta === 3) {
        this.minutes3 += amount;
      }
      this.saveSettings();  // Save after each change
    },
    connectAndFetchSettings() {
      this.$store.dispatch("connect");
      this.fetchSettings();
    },
    fetchSettings(){
      this.$store.dispatch("getSettings");
    },
    processDriveTimesAndDate() {
      if(this.settings.radius_drive_times){
        this.splitRadiusDriveTimes(this.settings.radius_drive_times)
        this.currentDate = new Date(Date.now())
      }
    },
    setupUpdateInterval() {
      this.interval = setInterval(() => {
        if(!this.isUpdatingSettings){
          this.fetchSettings();
        }
      }, 10000);
    },
    checkFullscreenMode() {
      this.isInFullscreenMode =
        document.fullscreenElement ||
        document.mozFullScreenElement ||
        document.webkitFullscreenElement ||
        document.msFullscreenElement;
    },
    checkTabletMode() {
      this.isTabletMode = custom.checkIfTablet();
    },
    setPanel: function (panel) {
      if (panel == "dayPanel") {
        this.$store.dispatch("drivers/deselectDriver");
      }
      if(panel == "settingsPanel"){
        this.$store.dispatch("app/setSettingsPanelTab", 1);
      }
      this.$store.dispatch("app/setPanel", panel);
    },
    clearSelections: function () {
      this.$store.dispatch("orders/clearSelectedOrders");
      this.$store.dispatch("drivers/deselectDriver");
    },
    toggleEditOrderModal: function (order) {
      this.$store.dispatch("orders/setEditOrder", order);
      this.$store.dispatch("app/setPanel", "editOrder");
      this.$store.dispatch('app/editOrderScreenReadOnly', false);
    },
    toggleDeleteOrderModal: function (order) {
      this.$store.dispatch("orders/deleteOrder", order);
    },
    toggleFullscreen: function () {
      var elem = document.documentElement;

      if (document.fullscreenElement) {
        document.exitFullscreen();
      } else {
        if (elem.requestFullscreen) {
          elem.requestFullscreen();
        }
      }
    },

    splitRadiusDriveTimes: function (radiusDriveTimes) {
      if(!radiusDriveTimes) return

      const parts = radiusDriveTimes.split("|");
      const minutes = parts[0].split(";");
      const miles = parts[1].split(";");

      this.minutes1 = parseInt(minutes[0]);
      this.minutes2 = parseInt(minutes[1]);
      this.minutes3 = parseInt(minutes[2]);

      this.miles1 = miles[0];
      this.miles2 = miles[1];
    },
    combineRadiusDriveTimes: function () {
        return `${this.minutes1};${this.minutes2};${this.minutes3}|${this.miles1};${this.miles2}`;
    },
    saveSettings: function () {
        if(!this.settings.radius_drive_times) return

        var payload = {
            store_id: parseInt(this.store_id),
            method: "POST",
        };

        // Load settings into payloaod
        for (var s in this.settings) {
            payload[s] = this.settings[s];
        }

        payload["radius_drive_times"] = this.combineRadiusDriveTimes();
        this.isUpdatingSettings = true;
        this.$http.post(this.base_url + "settings/", payload).then(
            (res) => {
                if (res.body.result != 0) {
                    alert(res.body.error);
                } else {
                  this.fetchSettings()
                }
                this.isUpdatingSettings = false;
            },
            (res) => {
                this.isUpdatingSettings = false;
                console.log("Failed to post settings...", res);
            }
        );
    },
  },
};
</script>

<style scoped>
.flex-container {
  display: flex;
  flex-wrap: wrap;
}

.non-clickable-space {
  display: inline-block;
  width: 95px;
  pointer-events: none;
  background-color: #ffffff;
}

.header-btn-left{
  height: 35px;
  margin-bottom: 8px;
}

.header-btn-left-tablet {
  height: 35px;
}

.header-text-btn-left-tablet {
  height: 35px;
  width: 65px;
}

.footer-custom {
  background-color: white;
  margin-bottom: 0px !important;
  padding-right:1em !important;
}
.is-grouped{
  display:flex;
  flex-direction: column;
  justify-content: space-between; 
}

.username {
  margin-left: 12px;
}

.logout {
  color: #7a7a7a;
}

.icon {
  height: 1rem;
  width: 1rem;
  padding-top: 3px;
}
.is-link-button{
  border-color: #3273dc !important;
}
.is-primary-button{
  border-color: #6ec545 !important;
}
.is-warning-button{
  border-color: #ffdd57 !important;
}
.is-danger-button{
  border-color: #f14668 !important;
}
.is-link-button:hover{
  background-color: #3273dc !important;
}
.is-primary-button:hover{
  background-color: #6ec545 !important;
}
.is-warning-button:hover{
  background-color: #ffdd57 !important;
}
.is-danger-button:hover{
  background-color: #f14668 !important;
}
.eta-quick > div{
  display: flex;
  padding: 5px;
  width: 94px;
  justify-content: center;
}

.eta-quick > div > div{
  width: 100%;
  text-align: center;
  border: 1px solid #dbdbdb;
  padding: 2px;
}

.left-eta{
  border-right: none !important;
  background-color: #dbdbdb;
}

.time {
  text-align: center;
}

.center-content {
    display: flex;
    justify-content: center;
}

.no-effect {
  display: contents;
}

.button {
  text-transform: uppercase;
}

.is-smaller{
  font-size: .65rem;
}

.eta-quick > div > div {
  width: 100%;
  text-align: center;
  border: 1px solid #dbdbdb;
  padding: 2px;
  cursor: pointer;
}

.eta-quick > div.selected > div {
  border-color: #3273dc; /* Blue border when selected */
}


.is-disabled {
  pointer-events: none;
  opacity: 0.5;
}
</style>